<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">

    <!-- Loading Screen -->
    <div v-show="isSaving" class="animate__animated animate__slideInLeft animate__faster" id="loadingOverlay"></div>

    <div v-show="isSaving" class="text-center animate__animated animate__bounce animate__slow" id="loadingSpinner">
        <img :src="'/assets/svg/'+ $t('general.company_name') + '.svg'" height="35" />
        <br />
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-dialog v-model="viewPdf.show" width="97%" height="100%">
      <v-card>
        <v-card-actions>
          {{viewPdf.title || ''}}
          <v-spacer></v-spacer>
          <v-icon @click="() => { viewPdf.show = false; viewPdf.data = null;}">mdi-close</v-icon>
        </v-card-actions>
        <v-card-text>
          <vue-pdf-app style="height: 83vh; display: block;" :pdf="viewPdf.data" page-scale="page-fit" theme.sync="light" :fileName="viewPdf.title + '.pdf'" :config="{ toolbar: { toolbarViewerRight: {openFile: false, viewBookmark: false} }, secondaryToolbar: false }"></vue-pdf-app>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="viewPdf.show && viewPdf.data == null" class="downloadingPdf">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-row style="margin:0px;">
      <Breadcrumbs style="display:inline-block;" />

      <v-spacer />
      <div v-if="selectedReport" style="padding:5px;">
        <div :class="{ 'align-right': !isMobile }">

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="gray"
              style="vertical-align:top;margin-right:25px;display:inline-block;margin-top:8px;"
              title="Click Up"
              v-if="userFunctions.find(uf => uf.id == FUNCTIONS.ACCESS_TO_CLICKUP) &&
                    (selectedReport.stampedFieldReportClickUpTaskUrl || 
                     selectedReport.conformityAttestationClickUpTaskUrl || 
                     selectedReport.buildingInspectionFormClickUpTaskUrl ||
                     selectedReport.tproClickUpTaskUrl ||
                     selectedReport.recommendationClickUpTaskUrl ||
                     selectedReport.permitClickUpTaskUrl)"
              v-bind="attrs"
              v-on="on">
              <img src="/assets/icon/clickup-symbol_color.svg" height="35" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="selectedReport.stampedFieldReportClickUpTaskUrl">
              <a class="clickup-anchor"  :href="selectedReport.stampedFieldReportClickUpTaskUrl" target="_blank"><v-list-item-title>{{ $t('documentRequests.dr_stampedfieldreport') }}</v-list-item-title></a>
            </v-list-item>
            <v-list-item v-if="selectedReport.conformityAttestationClickUpTaskUrl">
              <a class="clickup-anchor" :href="selectedReport.conformityAttestationClickUpTaskUrl" target="_blank"><v-list-item-title>{{ selectedReport.project.dealer.engineering_department && selectedReport.project.dealer.engineering_department.conformityReportAsDoe ? $t('documentRequests.dr_doe') : $t('documentRequests.dr_attestation') }}</v-list-item-title></a>
            </v-list-item>
            <v-list-item v-if="selectedReport.buildingInspectionFormClickUpTaskUrl">
              <a class="clickup-anchor" :href="selectedReport.buildingInspectionFormClickUpTaskUrl" target="_blank"><v-list-item-title>{{ $t('documentRequests.dr_buildinginspectionform') }}</v-list-item-title></a>
            </v-list-item>
            <v-list-item v-if="selectedReport.tproClickUpTaskUrl">
              <a class="clickup-anchor" :href="selectedReport.tproClickUpTaskUrl" target="_blank"><v-list-item-title>{{ $t('documentRequests.dr_tpro') }}</v-list-item-title></a>
            </v-list-item>
            <v-list-item v-if="selectedReport.recommendationClickUpTaskUrl">
              <a class="clickup-anchor" :href="selectedReport.recommendationClickUpTaskUrl" target="_blank"><v-list-item-title>{{ $t('documentRequests.dr_recommendation') }}</v-list-item-title></a>
            </v-list-item>
            <v-list-item v-if="selectedReport.permitClickUpTaskUrl">
              <a class="clickup-anchor" :href="selectedReport.permitClickUpTaskUrl" target="_blank"><v-list-item-title>{{ $t('documentRequests.dr_permit') }}</v-list-item-title></a>
            </v-list-item>
          </v-list>
        </v-menu>
          <div class="mt-2" style="display:inline-block;">
            <span :style="{'margin-right': '15px', 'font-weight': 'bold', 'color': SITE_REPORT_STATUS_COLOR[selectedReport.statusEnum].text}">{{ $t(SITE_REPORT_STATUS_TEXT[selectedReport.statusEnum]) }}</span>
            <v-tooltip bottom :disabled="!returnToDraftTooltip">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" style="display:inline-block;">
                  <v-btn v-bind="attrs" v-on="on" class="techno-green mr-5" 
                        v-if="selectedReport.statusEnum != SITE_REPORT_STATUS.DRAFT" 
                        :disabled="isReturnToDraftDisabled" 
                        v-on:click="confirmReturnToDraft()">
                    {{ $t('report.send_draft') }}
                  </v-btn>
                </div>
              </template>
              <span>{{ returnToDraftTooltip }}</span>
            </v-tooltip>

            <v-btn class="techno-green mr-5" fab v-if="selectedReport.statusEnum != SITE_REPORT_STATUS.CANCELED && selectedReport.statusEnum != SITE_REPORT_STATUS.DELETED" :disabled="!canDelete" v-on:click="confirmDelete()" :title="$t('reportInfo.cancel_report')">
              <v-icon>mdi-cancel</v-icon>
            </v-btn>
            <v-btn class="techno-green mr-5" fab v-if="selectedReport.statusEnum == SITE_REPORT_STATUS.CANCELED && userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_HARD_DELETE)" v-on:click="confirmHardDelete()" :title="$t('reportInfo.delete_report')">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab :disabled='isGeneratingReport' style="vertical-align:top;margin-right:15px;display:inline-block;" :title="$t('reportInfo.export')" class="techno-green" v-bind="attrs" v-on="on">
                  <v-progress-circular v-if="isGeneratingReport" color="white" indeterminate></v-progress-circular>
                  <v-icon v-else style="color:white;">mdi-download</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click.prevent="downloadReport(selectedReport.pdfReportUrl)">
                  {{ $t('reportInfo.export_pdf') }}
                </v-list-item>
                <v-list-item  @click.prevent="downloadReport(selectedReport.pdfReportUrlWithAttachment)">
                  {{ $t('reportInfo.export_pdf_with_attachments') }}
                </v-list-item>
                <v-list-item v-if="allowExcel" @click.prevent="downloadReport(selectedReport.excelReportUrl, true)">
                  {{ $t('reportInfo.export_excel') }}
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn id="send-btn" fab :disabled="isReadOnly" :title="$t('report.send_validate')" v-if="selectedReport.statusEnum == SITE_REPORT_STATUS.DRAFT || selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATION" v-on:click="changeStatus()">
              <font-awesome-icon :icon="['far', 'paper-plane']" size="2x" />
            </v-btn>    
          </div>    
        </div>
      </div>
    </v-row>

    <v-card class="border10 mx-3 my-3">
      <v-progress-linear
        v-if="!selectedReport"
        indeterminate
      ></v-progress-linear>
      <v-container fluid>
        <v-row>
          <!-- COLUMN 1 -->
          <v-col xl="3" lg="6" md="6" sm="12" cols="12" class="border-right" :class="{ 'col-custom5' : showFourthColumn }">
            <v-skeleton-loader
              v-if="!selectedReport"
              type="card-heading, list-item-three-line, divider, card-heading, list-item-three-line, card-heading, list-item-three-line"
            ></v-skeleton-loader>

            <div v-else>
              <div v-if="showDealerDropdown">
                <h3 :class="{ 'errored' : selectedReport.errorDealer }" style="margin-bottom:10px;">{{ $t("reportInfo.dealername")}}</h3>
                <v-select
                  dense
                  class="required"
                  :items="selectedReport.dealers"
                  :disabled="isReadOnly || selectingCustomer"
                  :label="$t('reportInfo.dealername')"
                  item-text="name"
                  item-value="id"
                  v-model="selectedReport.project.dealerId"
                  @change="dealerChanged()">
                </v-select>
              </div>

              <div v-if="selectedReport.requestType == REQUEST_TYPES.FIELD_REPORT &&
                         isSyncToProject" class="my-3" style="text-align:center;">
                <v-dialog
                    ref="changeProjectModal"
                    v-model="changeProjectModal"
                    width="800">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn tile color="#009f4d" style="color:white;" 
                             v-if="selectedReport.project.dealer"
                             :disabled="isReadOnly"
                             @click="getOtherProjects()"
                             v-bind="attrs"
                             v-on="on">
                        {{ $t("reportInfo.assign_another_project") }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-item>
                        <v-card-title>{{ $t("reportInfo.assign_another_project_title") }}</v-card-title>
                        <v-card-subtitle>{{ $t("reportInfo.assign_another_project_subtitle") }}</v-card-subtitle>
                      </v-card-item>

                      <v-card-text>
                        <div v-if="otherProjects == null" style="text-align: center;">
                          <v-progress-circular indeterminate class="my-3"></v-progress-circular>
                        </div> 
                        <v-autocomplete
                          v-else
                          :items="otherProjects"
                          :label="$t('projects.project')"
                          item-text="formattedName"
                          item-value="id"
                          v-model="selectedReport.project.id"
                        >
                        </v-autocomplete>
                      </v-card-text>
                      
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="changeProjectModal = false">{{ $t('confirm.cancel') }}</v-btn>
                        <v-btn color="primary" @click="$refs.changeProjectModal.save(selectedReport.project.id);projectChanged(selectedReport.project.id)">OK</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </div>

              <h3 style="margin-bottom:10px;" :class="{ 'errored' : selectedReport.errorCustomerAddress || selectedReport.errorCustomerName }" class="required">{{ $t("reportInfo.clientinfo") }}</h3>
              <v-autocomplete
                dense
                :class="{ 'errored' : selectedReport.errorCustomerId }"
                :items="selectedReport.project.dealer.customers"
                :label="$t('reportInfo.client')"
                :disabled="isReadOnly || selectingCustomer"
                item-text="formattedName"
                item-value="id"
                v-model="selectedReport.project.customerId"
                @change="customerChanged()"
                v-if="selectedReport.project.dealer"
              >
              </v-autocomplete>

              <div v-show="!selectingCustomer"
                   :disabled="!canManageCustomers">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      :class="{ 'errored' : selectedReport.errorCustomerFirstname, 'required' : selectedReport.customerFirstname || (!selectedReport.customerCompany && !selectedReport.customerLastname) }"
                      dense
                      :value="selectedReport.customerFirstname" @change="v => onCustomerFirstNameChange(v)" @keyup="v => onCustomerFirstNameChange(v.target.value, true)"
                      :label="$t('reportInfo.firstname')"
                      maxlength="45"
                      hide-details
                      :disabled="isReadOnly">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      :class="{ 'errored' : selectedReport.errorCustomerLastname, 'required' : selectedReport.customerLastname || (!selectedReport.customerCompany && !selectedReport.customerFirstname) }"
                      :value="selectedReport.customerLastname" @change="v => onCustomerLastNameChange(v)" @keyup="v => onCustomerLastNameChange(v.target.value, true)"
                      :label="$t('reportInfo.lastname')"
                      maxlength="45"
                      hide-details
                      :disabled="isReadOnly">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :label="$t('reportInfo.company')"
                      dense
                      :class="{ 'errored' : selectedReport.errorCustomerCompany, 'required' : selectedReport.customerCompany || (!selectedReport.customerFirstname && !selectedReport.customerLastname) }"
                      :value="selectedReport.customerCompany" @change="v => onCompanyChange(v)" @keyup="v => onCompanyChange(v.target.value, true)"
                      maxlength="255"
                      hide-details
                      :disabled="isReadOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="showCustomerTelephone">
                    <v-text-field dense :value="selectedReport.customerPhoneMobile" @change="v => onPhoneMobileChange(v)" :disabled="isReadOnly" maxlength="50" hide-details :label="$t('user.phone_mobile')"></v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="showCustomerEmail">
                    <v-form ref="emailForm1">
                      <v-text-field dense :value="selectedReport.customerEmailMain" :rules="emailRules" :label="$t('user.main_email')" hide-details :disabled="isReadOnly" maxlength="320" @change="v => emailMainChanged(v)"></v-text-field>
                    </v-form>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-col cols="12">
                    <AddressField
                      class='address-field'

                      @saveAddress="saveCustomerAddress"
                      @onShowMap="onShowMap"
                      :address="selectedReport.customerAddress"
                      :country="selectedReport.project.dealer.placeAutocompleteRestricted?.split(',')"
                      ref="customerAddressRef"

                      :disabled="isReadOnly"
                      :mapDisabled="isMapDisabled"
                      :showMarker="selectedReport.isCustomerAddress"

                      :errorAddress1="selectedReport.errorCustomerAddress1"
                      :errorCity="selectedReport.errorCustomerCity"
                      :errorCountry="selectedReport.errorCustomerCountry"
                      :errorState="selectedReport.errorCustomerState"
                      :errorPoBox="selectedReport.errorCustomerPoBox"

                      :key="selectedReport.project.customerId"
                      :id="'autocompleteCustomer' + selectedReport.project.customerId"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-select v-if="showCustomerCategory"
                        dense
                        :items="[ { name: '', id: null }, 
                                  { name: $t('customer.home_owner'), id: CUSTOMER_CATEGORY.HOME_OWNER },
                                  { name: $t('customer.residential_company'), id: CUSTOMER_CATEGORY.RESIDENTIAL_COMPANY },
                                  { name: $t('customer.commercial_company'), id: CUSTOMER_CATEGORY.COMMERCIAL_COMPANY } ]"
                        :label="$t('customer.category')"
                        item-text="name"
                        item-value="id"
                        :disabled="isReadOnly"
                        v-model="selectedReport.customerCategory" 
                        @change="updateCustomerCategory()"></v-select>
                  </v-col>
                </v-row>
              </div>

              <div v-if="selectingCustomer" style="text-align: center;">
                <v-progress-circular indeterminate class="my-3"></v-progress-circular>
              </div>

              <v-row v-if="isSyncToProject &&
                           !(selectedReport.project.customerId > 0) && 
                           !isReadOnly">
                <v-col cols="12" style="text-align:center;">
                  <v-btn tile color="#009f4d" style="color:white;margin-top:10px;" v-on:click="transformCustomer()"
                          :loading="transformingCustomer"
                        v-if="userFunctions.find(uf => uf.id == FUNCTIONS.TRANSFORM_TEMP_CUSTOMER)">
                    {{ $t("reportInfo.client_official") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <!-- COLUMN 2 -->
          <v-col xl="3" lg="6" md="6" sm="12" cols="12" :class="{ 'col-custom5' : showFourthColumn }">
            <v-skeleton-loader
              v-if="!selectedReport"
              type="card-heading, list-item-three-line, divider, card-heading, list-item-three-line, card-heading, list-item-three-line"
            ></v-skeleton-loader>
            <div v-else>

              <div v-if="showContacts" style="margin-bottom:15px;">
                <h3 style="margin-bottom:10px;" :class="{ 'required' : contactsMandatory, 'errored' : selectedReport.errorContacts }">{{ $t("reportInfo.site_contact") }}</h3>
                <div>
                  <v-row v-if="selectedReport.project.customer && selectedReport.project.customerId > 0">
                    <v-col cols="12">
                      <v-autocomplete
                        dense
                        :items="selectedReport.project.customer.contacts"
                        :label="$t('reportInfo.site_contact')"
                        :disabled="isReadOnly || selectingCustomer"
                        item-text="formattedName"
                        item-value="id"
                        v-model="selectedReport.contactId"
                        @change="contactChanged()"
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          :value="selectedReport.contactFirstname"
                          :label="$t('reportInfo.firstname')"
                          @change="v => siteContactFirstnameChanged(v)"
                          :class="{ 'required' : contactsMandatory }"
                          maxlength="45"
                          hide-details
                          :disabled="isReadOnly || selectingCustomer">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          :value="selectedReport.contactLastname" 
                          :label="$t('reportInfo.lastname')"
                          @change="v => siteContactLastnameChanged(v)"
                          :class="{ 'required' : contactsMandatory }"
                          maxlength="45"
                          hide-details
                          :disabled="isReadOnly || selectingCustomer">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          :label="$t('user.phone_mobile')"
                          dense
                          maxlength="45"
                          :value="selectedReport.contactPhoneMobile"
                          @change="v => siteContactPhoneChanged(v)"
                          :disabled="isReadOnly || selectingCustomer"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :label="$t('users.email')"
                          dense
                          :rules="emailRules"
                          :value="selectedReport.contactEmail"
                          :disabled="isReadOnly || selectingCustomer"
                          maxlength="320"
                          @change="v => siteContactEmailChanged(v)"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="selectedReport.project.customer && selectedReport.project.customerId > 0 && selectedReport.contactId < 0 && !selectingCustomer">
                      <v-col cols="12" style="text-align:center;">
                        <v-btn tile color="#009f4d" v-if="canManageCustomers" :loading="savingContact" :disabled="isReadOnly" style="color:white;margin-top:10px;" v-on:click="transformContact()">
                          {{ $t("reportInfo.save_to_customer") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                </div>
              </div>

              <h3 :class="{ 'errored' : selectedReport.errorSiteAddress }" class="required">{{ $t("reportInfo.site_address") }}</h3>
              <v-checkbox
                v-model="selectedReport.isCustomerAddress"
                :label="$t('reportInfo.sameprojectaddress')"
                :disabled="isReadOnly"
                @change="isCustomerAddressClicked()"
              >
              </v-checkbox>

              <AddressField
                v-show="!selectedReport.isCustomerAddress"
                ref="siteAddressRef"
                :address="selectedReport.siteAddress"
                @saveAddress="saveProjectAddress"
                :country="selectedReport.project.dealer.placeAutocompleteRestricted?.split(',')"
                @onShowMap="onShowMap"

                :disabled="isReadOnly"
                :mapDisabled="isMapDisabled"
                :showMarker="true"

                :errorAddress1="selectedReport.errorSiteAddress1"
                :errorCity="selectedReport.errorSiteCity"
                :errorCountry="selectedReport.errorSiteCountry"
                :errorState="selectedReport.errorSiteState"
                :errorPoBox="selectedReport.errorSitePoBox"
              />

              <div v-if="showMachines">
                <h3 style="margin-top:10px;margin-bottom:10px;" :class="{ 'errored' : selectedReport.errorDealerMachineMotor, 'required' : machinesMandatory }">{{ $t("reportInfo.dealermachinemotor") }}</h3>

                <div class="machines">
                  <div v-for="(siteDealerMachineMotor, index) in selectedReport.site_dealer_machine_motors" v-bind:key="'A'+ index" 
                    style="position:relative;padding: 15px;border: 1px solid lightgray;align-content: bottom;position:relative;border-radius:5px;">

                    <div style="width: calc(100% - 20px)">
                      <v-autocomplete
                        dense
                        hide-details
                        :class="{ 'required' : machinesMandatory }"
                        :items="selectedReport.project.dealer.dealer_machines"
                        :label="$t('reportInfo.dealerMachine')"
                        :disabled="isReadOnly"
                        :item-text="dealerMachine => dealerMachine.isOther ? $t('reportInfo.other') : (dealerMachine.name + ' - ' + dealerMachine.serialNumber)"
                        item-value="id"
                        v-model="siteDealerMachineMotor.dealerMachineId"
                        @change="dealerMachineChanged(siteDealerMachineMotor.dealerMachineId, siteDealerMachineMotor, index)"
                        v-if="selectedReport.project.dealer && (!isReadOnly || siteDealerMachineMotor.dealerMachineId > 0)"
                      ></v-autocomplete>

                      <v-autocomplete
                        v-if="siteDealerMachineMotor.id > 0 && siteDealerMachineMotor.dealerMachineId > 0"
                        dense
                        hide-details
                        :class="{ 'required' : machinesMandatory }"
                        :items="getDealerMachineMotors(siteDealerMachineMotor.dealerMachineId)"
                        :label="$t('reportInfo.motor')"
                        :disabled="isReadOnly"
                        :item-text="motor => motor.name"
                        item-value="id"
                        v-model="siteDealerMachineMotor.motorId"
                        @change="motorChanged(siteDealerMachineMotor.motorId, siteDealerMachineMotor)"
                      ></v-autocomplete>

                      <template v-if="siteDealerMachineMotor.id < 0 || siteDealerMachineMotor.dealerMachineId < 0">
                        <v-autocomplete
                          dense
                          hide-details
                          :class="{ 'required' : machinesMandatory }"
                          :items="machines"
                          :label="$t('reportInfo.model')"
                          :disabled="isReadOnly"
                          :item-text="machine => machine.name"
                          item-value="id"
                          v-model="siteDealerMachineMotor.machineId"
                          @change="updateTempMotorChanges(true);"
                        ></v-autocomplete>
                        <v-autocomplete
                          dense
                          hide-details
                          :class="{ 'required' : machinesMandatory }"
                          :items="motors"
                          :label="$t('reportInfo.motor')"
                          :disabled="isReadOnly"
                          :item-text="motor => motor.name"
                          item-value="id"
                          v-model="siteDealerMachineMotor.motorNumber"
                          @change="updateTempMotorChanges(true)"
                        ></v-autocomplete>
                        <v-text-field :label="$t('reportInfo.dealermachinename')" dense hide-details :value="siteDealerMachineMotor.machineName" @change="v => { siteDealerMachineMotor.machineName = v; updateTempMotorChanges(true); }" maxlength="50" :disabled="isReadOnly"></v-text-field>
                        <v-text-field :label="$t('reportInfo.dealermachinenumber')" dense hide-details :value="siteDealerMachineMotor.serialNumber" @change="v => { siteDealerMachineMotor.serialNumber = v; updateTempMotorChanges(true); }" maxlength="50" :disabled="isReadOnly"></v-text-field>
                      </template>

                      <v-icon v-if="!isReadOnly"  style="position:absolute;top:5px;right:0px;" v-on:click="deleteDealerMachineMotor(index)">mdi-close</v-icon>
                    </div>
                  </div>
                </div>

                <v-btn tile color="#009f4d" style="color:white;margin-top:5px;" :loading="addingDealerMachineMotor" v-on:click="addDealerMachineMotor()" :disabled="isReadOnly" :class="{ 'btn-errored' : selectedReport.errorDealerMachineMotor && selectedReport.site_dealer_machine_motors.length == 0 }">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  {{ $t("general.add") }}
                </v-btn>
              </div>
            </div>
          </v-col>

          <!-- COLUMN 3 -->
          <v-col xl="3" :lg="showFourthColumn ? 4 : 6" :md="showFourthColumn ? 4 : 6" sm="12" cols="12" class="border-right" :class="{ 'col-custom5' : showFourthColumn }">
            <v-skeleton-loader
              v-if="!selectedReport"
              type="card-heading, list-item-three-line, divider, card-heading, list-item-three-line, card-heading, list-item-three-line, card-heading, list-item-three-line"
            ></v-skeleton-loader>
            <div v-else>
              <h3 v-if="showProjectReferenceNumber" style="margin-bottom:10px;" :class="{ 'errored': selectedReport.errorReferenceNumber }">{{ $t("reportInfo.projectinfo") }}</h3>
              <v-text-field
                v-if="showProjectReferenceNumber"
                :label="$t('reportInfo.referenceNumber')"
                dense
                :value="selectedReport.project.referenceNumber" @change="v => updateReferenceNumber(v, true)"
                :class="{ 'errored': selectedReport.errorReferenceNumber }"
                :disabled="isReadOnly"
                maxlength="45"
              ></v-text-field>

              <div v-if="showProjectTypes">
                <h4 :class="{ 'errored' : selectedReport.errorProjectType, 'required': projectsMandatory }">{{ $t('reportInfo.project_types') }}</h4>
                <div v-for="(projectType, index) in selectedReport.projectTypes" v-bind:key="'C'+ index" 
                    style="padding: 15px;align-content: bottom;position:relative;border-radius:5px;"
                    :style="{ 'border' : showProjectTypeDetails ? '1px solid lightgray' : 'unset' }">
                  <div style="width:calc(100% - 10px)">
                    <v-row>
                      <v-col cols="12">
                        <h4 v-if="!projectType.isOther" :style="{ 'font-weight' : showProjectTypeDetails ? 'bold' : 'normal' }">{{ projectType.displayName }}</h4>
                        <v-text-field v-else
                          style="margin-top:5px;" 
                          :label="$t('reportInfo.other_project_type')"
                          dense
                          hide-details
                          :value="projectType.name" @change="v => { projectType.name = v; updateOtherProjectTypeChanges(); }"
                          :disabled="isReadOnly"
                          maxlength="100"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="7" v-if="showProjectFreeStructure || showProjectDimensions">
                  
                        <v-select 
                          v-if="showProjectFreeStructure"
                          dense
                          style="margin-top:5px;"
                          :items="[ { name: $t('reportInfo.freeStanding'), id: true }, { name: $t('reportInfo.attached'), id: false } ]"
                          :label="$t('reportInfo.holderStructureType')"
                          item-text="name"
                          item-value="id"
                          :class="{ 'required' : projectFreeStructureMandatory, 
                                    'errored' : projectType.errorProjectFreeStructure }"
                          :disabled="isReadOnly"
                          v-model="projectType.isFreeStandingStructure"
                          @change="updateProjectTypeFreeStandingStructure(projectType)" ></v-select>
                        <v-text-field 
                          v-if="showProjectDimensions"
                          dense
                          hide-details
                          maxlength="255"
                          :class="{ 'required' : projectDimensionsMandatory, 
                                    'errored' : projectType.errorProjectDimension }"
                          :value="projectType.projectDimensions" @change="v => { projectType.projectDimensions = v; updateProjectDimensions(projectType); }" 
                          :label="$t('reportInfo.dimension')"
                          :disabled="isReadOnly">
                        </v-text-field>
                      </v-col>
                      <v-col cols="5" v-if="showProjectTypeHeight">
                        
                        <h5 :class="{ 'errored' : projectType.errorHeightAboveGround, 
                                      'required' : projectTypeHeightMandatory }">{{ isMetric() ? $t('reportInfo.heightaboveground_cm') : $t('reportInfo.heightaboveground_ft') }}</h5> 
                        <MeasureFields
                          :ref="'projectTypeMeasureRef'"
                          :errored="projectType.errorHeightAboveGround"
                          :readonly="isReadOnly" 
                          :ismetric="isMetric()" 
                          :valueInFt="projectType.heightAboveGround_ft"
                          :maxValue="9999"
                          :objectToUpdate="projectType" 
                          @changed="updateProjectHeightAboveGround"
                        />

                      </v-col>
                    </v-row>

                    <v-row v-if="showConcreteSlab">
                      <v-col cols="6" style="padding: 6px 12px;">
                        <v-checkbox
                          v-model="projectType.hasConcreteSlab"
                          :label="$t('reportInfo.has_concrete_slab')"
                          hide-details
                          :disabled="isReadOnly"
                          style="margin-top:0px;"
                          @change="updateProjectTypeProperty(projectType, 'hasConcreteSlab')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" v-if="projectType.hasConcreteSlab" style="padding: 6px 12px;">
                        <h5 :class="{ 'errored' : projectType.errorConcreteSlab, 'required' : concreteSlabMandatory(projectType) }">{{ isMetric() ? $t('reportInfo.concrete_slab_m') : $t('reportInfo.concrete_slab_ft') }}</h5> 
                        <MeasureFields
                          :ref="'projectConcreteSlabMeasureRef'"
                          :errored="projectType.errorConcreteSlab"
                          :readonly="isReadOnly" 
                          :ismetric="isMetric()" 
                          :valueInFt="projectType.concreteSlabThickness_ft" 
                          :maxValue="9999"
                          :objectToUpdate="projectType"
                          @changed="updateProjectConcreteSlab"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="showBrick">
                      <v-col cols="6" style="padding: 6px 12px;">
                        <v-checkbox
                          v-model="projectType.hasBrick"
                          :label="$t('reportInfo.has_brick')"
                          hide-details
                          :disabled="isReadOnly"
                          style="margin-top:0px;"
                          @change="updateProjectTypeProperty(projectType, 'hasBrick')"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" v-if="projectType.hasBrick" style="padding: 6px 12px;">
                        <h5 :class="{ 'errored' : projectType.errorBrick, 'required' : brickMandatory(projectType) }">{{ isMetric() ? $t('reportInfo.brick_height_m') : $t('reportInfo.brick_height_ft') }}</h5> 
                        <MeasureFields
                          :ref="'projectBrickMeasureRef'"
                          :errored="projectType.errorBrick"
                          :readonly="isReadOnly" 
                          :ismetric="isMetric()" 
                          :valueInFt="projectType.brickHeight_ft" 
                          :maxValue="9999"
                          :objectToUpdate="projectType"
                          @changed="updateProjectBrickHeight"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="showSpa">
                      <v-col cols="6" style="padding: 6px 12px;">
                        <v-checkbox
                          v-model="projectType.hasSpa"
                          :label="$t('reportInfo.has_spa')"
                          hide-details
                          :disabled="isReadOnly"
                          style="margin-top:0px;"
                          @change="updateProjectTypeProperty(projectType, 'hasSpa')"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                  <v-icon style="position:absolute;right:0px;top:10px;" v-on:click="removeProjectType(index)" v-if="!isReadOnly">mdi-close</v-icon>
                </div>

                <div v-if="addingProjectType" style="text-align: center;">
                  <v-progress-circular indeterminate class="my-3"></v-progress-circular>
                </div>

                <v-autocomplete
                  dense
                  :items="selectedReport.project.dealer.projectTypes"
                  :label="$t('reportInfo.holderProjecttype')"
                  v-if="!isReadOnly"
                  :item-text="projectType => (projectType.isOther ? $t('reportInfo.other') : '') + projectType.name"
                  item-value="id"
                  v-model="addProjectType"
                  return-object
                  @change="projectTypeAdd(addProjectType)"
                  style="margin-top:10px;"
                ></v-autocomplete>
              </div>

              <div v-if="showSoilType">

                <h4 style="margin-bottom:10px;" :class="{ 'errored' : selectedReport.errorSoilType }">{{ $t("summary.soiltype") }}</h4>

                <v-autocomplete
                  id="soilType"
                  dense
                  :items="soiltypelist"
                  :item-text='item => $t(item.text)'
                  item-value='soilTypeEnum'
                  return-object
                  v-model="selectedReport.site_reports_soil_types"
                  multiple
                  chips
                  @change="soilTypeChanged()"
                ></v-autocomplete>
              </div>

              <div v-if="showSafetyFactor">
                <h4 style="margin-bottom:10px;" :class="{ 'required' : safetyFactorMandatory, 'errored' : selectedReport.errorSafetyFactor }">{{ $t("reportInfo.safety_factor") }}</h4>
                
                <v-row>
                  <v-col>
                    <v-select style="margin-top:5px;" dense
                      :items="[ 2, 2.5, 3 ]"
                      :label="$t('dealer.compression')"
                      :disabled="isReadOnly"
                      v-model="selectedReport.safetyFactorCompression"
                      @change="safetyFactorCompressionChanged()" ></v-select>
                  </v-col>

                  <v-col>
                    <v-select style="margin-top:5px;" dense
                      :items="[ 2, 2.5, 3 ]"
                      :label="$t('dealer.tension')"
                      :disabled="isReadOnly"
                      v-model="selectedReport.safetyFactorTension"
                      @change="safetyFactorTensionChanged()" ></v-select>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>

          <!-- COLUMN 4 -->
          <v-col v-if="showFourthColumn" lg="4" md="4" sm="12" cols="12" class="border-right col-custom5">
            <v-skeleton-loader
              v-if="!selectedReport"
              type="card-heading, list-item-three-line, divider, card-heading, list-item-three-line, card-heading, list-item-three-line"
            ></v-skeleton-loader>
            <div v-else>
              
              <div v-if="showInstallers" style="margin-bottom: 10px;">
                <h4 :class="{ 'errored' : selectedReport.errorSiteInstaller, 'required': installersMandatory }">{{ $t('reportInfo.siteinstaller') }}</h4>
                <div v-for="(installer, index) in selectedReport.installers" :key="'B'+ index">
                  <v-row>
                    <v-col cols="10" style="line-height:51px;padding-top:0px;padding-bottom:0px;">
                      {{ installer.firstname + ' ' + installer.lastname }}
                    </v-col>
                    <v-col cols="2">
                      <v-icon v-if="!isReadOnly && 
                                    (installer.id != currentUser.id ||
                                    !currentUser.isAutoAddedInstaller ||
                                    userFunctions.find( uf => uf.id == FUNCTIONS.SITE_REPORT_SELF_DELETE_INSTALLER))" 
                              v-on:click="removeInstaller(index)">mdi-close</v-icon>
                    </v-col>
                  </v-row>
                </div>
                <v-autocomplete
                  dense
                  :items="availableInstallers"
                  :label="$t('reportInfo.addsiteinstaller')"
                  v-if="!isReadOnly"
                  :item-text="installer => installer.firstname + ' ' + installer.lastname"
                  return-object
                  v-model="installerAdd"
                  style="margin-top:10px;"
                  @change="installerAdded(installerAdd)"
                ></v-autocomplete>
              </div>

              <div v-if="showCollaborators">
                <h4 :class="{ 'required': collaboratorsMandatory, 'errored' : selectedReport.errorCollaborators }">{{ $t('reportInfo.collaborators') }}</h4>
                <div v-for="(collaborator, index) in selectedReport.collaborators" :key="'B'+ index">
                  <v-row>
                    <v-col cols="10" style="line-height:51px;padding-top:0px;padding-bottom:0px;">
                      <span style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;display:block;" :title="collaborator.formattedName">{{ collaborator.formattedName }}</span>
                    </v-col>
                    <v-col cols="2">
                      <v-icon v-if="!isReadOnly" v-on:click="removeCollaborator(index)">mdi-close</v-icon>
                    </v-col>
                  </v-row>
                </div>
                <v-autocomplete
                  dense
                  :items="availableCollaborators"
                  :label="$t('reportInfo.addcollaborator')"
                  v-if="!isReadOnly"
                  :item-text="collaborator => collaborator.formattedName"
                  return-object
                  v-model="collaboratorAdd"
                  style="margin-top:10px;"
                  @change="collaboratorAdded(collaboratorAdd)"
                ></v-autocomplete>
              </div>

              <div v-if="showWelders">
                <h4 :class="{ 'errored' : selectedReport.errorSiteWelder, 'required' : weldersMandatory }" style="margin-top:10px;">{{ $t('reportInfo.sitewelder') }}</h4>
                <div v-for="(welder, index) in selectedReport.welders" :key="'C'+ index">
                  <v-row :style="{ 'margin-top': welder.isOther ? '10px' : '0px' }">
                    <v-col v-if="!welder.isOther || isReadOnly" style="line-height:59px;padding-top:0px;padding-bottom:0px;">
                      <span>{{ welder.firstname + ' ' + welder.lastname }}</span><span style="display:block;color:red;font-size:12px;font-style:italic;line-height:20px;margin-top:-20px;">{{ welder.isExpired ? $t("reportInfo.welderstatus_expired") : ''}}</span>
                    </v-col>
                    <v-col v-if="welder.isOther && !isReadOnly" cols="5">
                      <v-text-field
                        :label="$t('reportInfo.firstname')"
                        dense
                        hide-details
                        maxlength="255"
                        :value="welder.firstname" @change="v => { welder.firstname = v; updateTempWelder(); }" 
                        :disabled="isReadOnly"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="welder.isOther && !isReadOnly" cols="5">
                      <v-text-field
                        :label="$t('reportInfo.lastname')"
                        dense
                        hide-details
                        maxlength="255"
                        :value="welder.lastname" @change="v => { welder.lastname = v; updateTempWelder(); }" 
                        :disabled="isReadOnly"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-icon :disabled="isReadOnly" v-on:click="removeWelder(index)" v-if="!isReadOnly" style="margin-top:3px;">mdi-close</v-icon>
                    </v-col>
                  </v-row>
                </div>
                <v-autocomplete
                  dense
                  :items="availableWelders"
                  :label="$t('reportInfo.addsitewelder')"
                  v-if="!isReadOnly"
                  :item-text="welder => ( welder.isOther ? $t('reportInfo.other') : '' ) +  welder.firstname + ' ' + welder.lastname"
                  return-object
                  v-model="welderAdd"
                  @change="welderAdded(welderAdd)"
                  style="margin-top:20px;"
                ></v-autocomplete>
              </div>

              <v-row style="margin-top:25px;" v-if="showInstallationDates">
                <v-col cols="6">
                 <v-dialog
                    ref="startDateModal"
                    v-model="startDateModal"
                    :return-value.sync="selectedReport.installationStart"
                    persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="selectedReport.installationStart"
                        :label="$t('reportInfo.startdate')"
                        :class="{ 'errored' : selectedReport && selectedReport.errorInstallationDates, 'required' : installationDatesMandatory }"
                        readonly
                        :disabled="isReadOnly"
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker :first-day-of-week="getComputedFirstDayOfWeek" v-model="selectedReport.installationStart" scrollable :min="getMinSelectableDate" :max="getMaxSelectableDate">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startDateModal = false">{{ $t('confirm.cancel') }}</v-btn>
                      <v-btn text color="primary" @click="$refs.startDateModal.save(selectedReport.installationStart);updateInstallationStart();">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="endDateModal"
                    v-model="endDateModal"
                    :return-value.sync="selectedReport.installationEnd"
                    persistent
                    :disabled="isReadOnly"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="selectedReport.installationEnd"
                        :label="$t('reportInfo.enddate')"
                        :class="{ 'errored' : selectedReport && selectedReport.errorInstallationDates, 'required' : installationDatesMandatory }"
                        readonly
                        :disabled="isReadOnly"
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker :first-day-of-week="getComputedFirstDayOfWeek" v-model="selectedReport.installationEnd" scrollable :min="getMinSelectableDate" :max="getMaxSelectableDate">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endDateModal = false">{{ $t('confirm.cancel') }}</v-btn>
                      <v-btn text color="primary" @click="$refs.endDateModal.save(selectedReport.installationEnd);updateInstallationEnd()">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
             
            </div>
          </v-col>

          <!-- COLUMN 5 -->
         <v-col xl="3" :lg="showFourthColumn ? 4 : 6" :md="showFourthColumn ? 4 : 6" sm="12" cols="12" :class="{ 'col-custom5' : showFourthColumn }">
            <v-skeleton-loader
              v-if="!selectedReport"
              type="table-heading, card-heading, card-heading, card-heading, card-heading, card-heading, card-heading, card-heading, card-heading, image, card-heading, image"
            ></v-skeleton-loader>
            <div v-else id="required-info">
              <div v-if="showImportantInformation" style="margin-bottom:20px">
                <h3 style="margin-bottom:10px;" :class="{ 'errored' : selectedReport.errorPileAngleConform || selectedReport.errorPilePositionConform }">{{ $t("reportInfo.requiredinfo") }}</h3>

                <div>
                  <v-checkbox
                    v-if="showInfoWelding"
                    v-model="selectedReport.isWeldingDone"
                    :label="$t('summary.welding')"
                    :class="{ 'errored' : selectedReport.errorInfoWelding, 'required' : infoWeldingMandatory }"
                    hide-details
                    :disabled="isReadOnly"
                    @change="checkWelding()"
                  ></v-checkbox>

                  <v-checkbox
                    v-if="showInfoSelfTappingScrew"
                    v-model="selectedReport.isSelfTappingScrew"
                    :label="$t('summary.selftapping')"
                    :class="{ 'errored' : selectedReport.errorInfoSelfTappingScrew, 'required' : infoSelfTappingScrewMandatory }"
                    hide-details
                    :disabled="isReadOnly"
                    @change="checkSelfTappingScrew()"
                  ></v-checkbox>
                  
                  <v-checkbox
                    v-if="showInfoStickers"
                    v-model="selectedReport.areStickersPosed"
                    :label="$t('reportInfo.stickersposed')"
                    :class="{ 'errored' : selectedReport.errorInfoStickers, 'required' : infoStickersMandatory }"
                    hide-details
                    :disabled="isReadOnly"
                    @change="checkStickersPosed()"
                  ></v-checkbox>

                  <v-checkbox
                    v-if="showInfoGreenSleeve"
                    v-model="selectedReport.isGreenSleeve"
                    :label="$t('summary.greensleeve')"
                    :class="{ 'errored' : selectedReport.errorInfoGreenSleeve, 'required' : infoGreenSleeveMandatory }"
                    hide-details
                    :disabled="isReadOnly"
                    @change="checkGreenSleeve()"
                  ></v-checkbox>

                  <v-checkbox
                    v-if="showInfoPileAngleConform"
                    v-model="selectedReport.isPileAngleConform"
                    :label="$t('summary.pileangle')"
                    :class="{ 'errored' : selectedReport.errorPileAngleConform, 'required' : infoPileAngleConformMandatory }"
                    hide-details
                    :disabled="isReadOnly"
                    @change="checkpileangle()">
                  </v-checkbox>

                  <v-checkbox
                    v-if="showInfoPilePositionConform"
                    v-model="selectedReport.isPilePositionConform"
                    :label="$t('summary.pileposition')"
                    :class="{ 'errored' : selectedReport.errorPilePositionConform, 'required' : infoPilePositionConformMandatory }"
                    hide-details
                    :disabled="isReadOnly"
                    @change="checkpileposition()">
                  </v-checkbox>
                </div>
              </div>

              <div v-if="showRequests" style="margin-bottom:20px;">
                <h3 v-if="showDocuments" style="margin-bottom:10px;">{{ $t("reportInfo.docrequired") }}</h3>

                <div v-if="(selectedReport.project.dealer.engineering_department && selectedReport.project.dealer.engineering_department.stampedFieldReportInfo) ||
                           selectedReport.requireStampedFieldReport">
                  <v-checkbox
                    v-model="selectedReport.requireStampedFieldReport"
                    :label="$t('documentRequests.dr_stampedfieldreport')"
                    :disabled="isRequestDisabled(selectedReport.requireStampedFieldReport)"
                    @change="toggleRequest('requireStampedFieldReport')"
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    :label="$t('reportInfo.expected_date')"
                    v-model="selectedReport.requireStampedFieldReport.requestedDate"
                    v-if="selectedReport.requireStampedFieldReport && selectedReport.requireStampedFieldReport.requestedDate && selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED"
                    disabled
                  ></v-text-field>
                </div>

                <div v-if="(selectedReport.project.dealer.engineering_department && selectedReport.project.dealer.engineering_department.conformityReportInfo) ||
                           selectedReport.requireConformityAttestation">
                  <v-checkbox
                    v-model="selectedReport.requireConformityAttestation"
                    :label="selectedReport.project.dealer.engineering_department && selectedReport.project.dealer.engineering_department.conformityReportAsDoe ? $t('documentRequests.dr_doe') : $t('documentRequests.dr_attestation')"
                    :disabled="isRequestDisabled(selectedReport.requireConformityAttestation)"
                    @change="toggleRequest('requireConformityAttestation')"
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    :label="$t('reportInfo.expected_date')"
                    v-model="selectedReport.requireConformityAttestation.requestedDate"
                    v-if="selectedReport.requireConformityAttestation && selectedReport.requireConformityAttestation.requestedDate && selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED"
                    disabled
                  ></v-text-field>
                </div>
                

                <div v-if="(selectedReport.project.dealer.engineering_department && selectedReport.project.dealer.engineering_department.buildingInspectionFormInfo) ||
                           selectedReport.requireBuildingInspectionForm">
                  <v-checkbox
                    v-model="selectedReport.requireBuildingInspectionForm"
                    :label="$t('documentRequests.dr_buildinginspectionform')"
                    :disabled="isRequestDisabled(selectedReport.requireBuildingInspectionForm)"
                    @change="toggleRequest('requireBuildingInspectionForm')"
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    :label="$t('reportInfo.expected_date')"
                    v-model="selectedReport.requireBuildingInspectionForm.requestedDate"
                    v-if="selectedReport.requireBuildingInspectionForm && selectedReport.requireBuildingInspectionForm.requestedDate && selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED"
                    disabled
                  ></v-text-field>
                </div>

                <div v-if="((selectedReport.project.dealer.engineering_department && selectedReport.project.dealer.engineering_department.permitRequestInfo) && showPermit) ||
                           selectedReport.requirePermit">
                  <v-checkbox
                    v-model="selectedReport.requirePermit"
                    :label="$t('documentRequests.dr_permit')"
                    :disabled="isRequestDisabled(selectedReport.requirePermit)"
                    @change="toggleRequest('requirePermit')"
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    :label="$t('reportInfo.expected_date')"
                    v-model="selectedReport.requirePermit.requestedDate"
                    v-if="selectedReport.requirePermit && selectedReport.requirePermit.requestedDate && selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED"
                    disabled
                  ></v-text-field>
                </div>

                <div v-if="(selectedReport.project.dealer.engineering_department && selectedReport.project.dealer.engineering_department.tproInfo) ||
                           selectedReport.requireTpro">
                  <v-checkbox
                    v-model="selectedReport.requireTpro"
                    :label="$t('documentRequests.dr_tpro')"
                    :disabled="isRequestDisabled(selectedReport.requireTpro)"
                    @change="toggleRequest('requireTpro')"
                    hide-details
                  ></v-checkbox>
                  <v-text-field
                    :label="$t('reportInfo.expected_date')"
                    v-model="selectedReport.requireTpro.requestedDate"
                    v-if="selectedReport.requireTpro && selectedReport.requireTpro.requestedDate && selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED"
                    disabled
                  ></v-text-field>
                </div>
              </div>

              <h3 style="margin-bottom:10px;">{{ $t("reportInfo.reportfile") }}</h3>
              <span v-for="category in filesCategories" v-bind:key="category.id">
                <v-row v-if="category.displayCondition()">
                  <v-col>
                    <div style="position:relative;width:100%;">
                      <h4 class="techno-text" :class="{ 'errored' : selectedReport[category.errored], 'required' : category.mandatory() }">{{ $t(category.title) }}</h4>
                      <div class="upload-btn-wrapper">
                        <v-btn fab color="#009f4d" dark :disabled="isReadOnly" v-on:click="selectFileFromProjectClicked(category)" class="mr-2">
                          <v-progress-circular v-if="isUploading[category.id]" color="white" indeterminate></v-progress-circular>
                          <v-icon v-else>mdi-folder</v-icon>
                        </v-btn>

                        <v-btn fab color="#009f4d" dark :disabled="isReadOnly" v-on:click="uploadBtnClicked(category)">
                          <v-progress-circular v-if="isUploading[category.id]" color="white" indeterminate></v-progress-circular>
                          <v-icon v-else>mdi-cloud-upload</v-icon>
                        </v-btn>
                        <input style="display:none;" type="file" name="file" multiple :id="category.id" v-on:change="uploadFile(category)" :disabled="isReadOnly" :accept="category.acceptFiles"/>
                      </div>
                    </div>

                    <div
                      @dragover.prevent="dragOver"
                      @dragleave.prevent="dragLeave"
                      @drop.prevent="dropFile($event, category)"
                      class="mt-3"
                    >
                      <div class="request-container" v-if="selectedReport[category.reportArray].length == 0">
                        <div class="requests-anchor">
                            <img class="required-img">
                            <span class="wrappedfile">{{ category.mandatory() ? $t('reportInfo.required') : $t('reportInfo.optional') }}</span>
                        </div>
                      </div>

                      <div class="request-container"
                        v-for="(currentFile, index) in selectedReport[category.reportArray]" v-bind:key="'D'+ index">
                          <div target="_blank" class="requests-anchor" @click="(currentFile.url?.thumbnail ? selectPhoto(currentFile) : downloadFile(currentFile))" style="cursor:pointer;">
                            <span v-if="currentFile.url?.thumbnail != null" :id="'loading-' + currentFile.id" class="loader"></span>
                            <img v-if="currentFile.url?.thumbnail != null" :src="currentFile.url?.thumbnail" @load="imgLoadingDone(currentFile)" @error="imgLoadingDone(currentFile)">
                            <img v-else :src="category.imageCategory ? '/assets/icon/pho-preview.svg' : '/assets/icon/doc-file.svg'">
                            <span v-if="currentFile.renaming" class="editingFile">
                              <v-text-field :id="currentFile.inputId" v-model="currentFile.filename" @blur="renameFile(currentFile, 'disable')" @keyup.native.enter="renameFile(currentFile, 'disable')" maxlength="200"></v-text-field>
                            </span>
                            <span v-else class="wrappedfile" :title="currentFile.filename + '.' + currentFile.extension">{{ currentFile.filename }}</span>
                          </div>
                          <span v-if="!currentFile.renaming">
                            <v-progress-circular v-if="currentFile.isInRotation" class="rotate-photo-icon" style="height:24px;width:24px;" indeterminate></v-progress-circular>
                            <v-menu v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :icon="true" density="compact" variant="outlined" style="margin:8px 8px 0 0;">
                                  <v-icon style="color:black;">mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click.prevent="downloadFile(currentFile)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-download</v-icon>
                                  </v-list-item-icon>
                                  {{ $t('reportInfo.download_file') }}
                                </v-list-item>
                                <v-list-item @click.prevent="renameFile(currentFile, 'enable')" v-if="!isReadOnly" :disabled="isReadOnly">
                                  <v-list-item-icon>
                                    <v-icon>mdi-form-textbox</v-icon>
                                  </v-list-item-icon>     
                                  {{ $t('reportInfo.rename_file') }}
                                </v-list-item>
                                <v-list-item v-if="(selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED || selectedReport.statusEnum == SITE_REPORT_STATUS.DRAFT) && 
                                              currentFile.url?.thumbnail != null &&
                                              (userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION))" @click.prevent="rotateImage(currentFile)">
                                  <v-list-item-icon>
                                    <v-icon>mdi-rotate-3d-variant</v-icon>
                                  </v-list-item-icon>     
                                  {{ $t('reportInfo.rotate_image') }}
                                </v-list-item>
                                <v-list-item @click.prevent="confirmFileDelete(currentFile, selectedReport[category.reportArray], index)" v-if="!isReadOnly" :disabled="isReadOnly">
                                  <v-list-item-icon>
                                    <v-icon>mdi-close</v-icon>
                                  </v-list-item-icon>     
                                  {{ $t('reportInfo.delete_file') }}
                                </v-list-item>
                              </v-list>
                            </v-menu>
                            <!-- <v-icon v-on:click="rotateImage(currentFile)" 
                                    v-else-if="(selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED || selectedReport.statusEnum == SITE_REPORT_STATUS.DRAFT) && 
                                              currentFile.url?.thumbnail != null &&
                                              (userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION))" 
                                    class="rotate-photo-icon" :title="$t('reportInfo.rotate_image')">mdi-rotate-3d-variant</v-icon>
                            <v-icon v-on:click="confirmFileDelete(currentFile, selectedReport[category.reportArray], index)" v-if="!isReadOnly" :disabled="isReadOnly" class="delete-photo-icon" :title="$t('reportInfo.delete_file')">mdi-close</v-icon>

                            <v-icon v-on:click="renameFile(currentFile, 'enable')" v-if="!isReadOnly" :disabled="isReadOnly" class="rename-file-icon" :title="$t('reportInfo.rename_file')">mdi-form-textbox</v-icon>
                            <v-icon v-on:click="downloadFile(currentFile)" class="download-photo-icon" :title="$t('reportInfo.download_file')">mdi-download</v-icon> -->
                          </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </span>

              <v-row>
                <div class="mx-3" v-if="selectedReport && selectedReport.requestType == REQUEST_TYPES.RECOMMENDATION_REQUEST" style="font-weight:bold;font-style: italic;">
                  {{ $t('reportInfo.recommendation_loads') }}
                </div>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card v-if="selectedReport && showPiles" class="border10 mx-3 my-3" id="card-holding-table" style="padding:10px;overflow:auto;">
      <div id="div-holding-table">
      <table id="piles-table" v-if="selectedReport && selectedReport.project && selectedReport.project.dealer" fixed-header>
        <tr style="font-size:12px; fixed">
          <th v-if="showPileInstalledColumns"><v-checkbox class="check-all" style="margin-top: auto;" label="" :disabled="isReadOnly" hide-details v-model="allPilesChecked" v-on:change="checkAllPiles()"></v-checkbox></th>
          <th v-if="showPileInstalledColumns" class="required piles-header">{{ $t("reportInfo.pilemodel") }}</th>
          <th v-if="showPileInstalledColumns && showPileLength" class="required piles-header">{{ isMetric() ? $t("reportInfo.pileLength_m") : $t("reportInfo.pileLength_ft") }}</th>
          <th v-if="showPileInstalledColumns" class="required piles-header">{{ $t("general.helixModel") }}</th>
          <th v-if="showPileInstalledColumns && showPileFinish" class="piles-header" :class="{ 'required': pileFinishMandatory }">{{ $t("reportInfo.blackorgalvanized") }}</th>
          <th v-if="showPileInstalledColumns && showPilePlateDimension" class="piles-header" :class="{ 'required': pilePlateDimensionMandatory }">{{ $t("reportInfo.platedimension") }}</th>
          <th v-if="showPileInstalledColumns && showPlateTypeColumn" class="piles-header" :class="{ 'required': plateTypeMandatory }">{{ $t("reportInfo.plate_type") }}</th>
          <th v-else-if="showPileInstalledColumns && showPileConcreteBlock" class="piles-header" :class="{ 'required': pileConcreteBlockMandatory }">{{ $t("reportInfo.concrete_block") }}</th>
          <th v-if="showPileInstalledColumns" class="required piles-header">{{ $t("general.pressure") }} (psi)</th>
          <th v-if="showPileInstalledColumns" class="piles-header" :class="{ 'required': showPileInstalledColumns }">{{ isMetric() ? $t("reportInfo.depth_m") : $t("reportInfo.depth_ft") }}</th>
          <th v-if="showPileInstalledColumns && showInvoiceLength" class="piles-header">{{ $t("reportInfo.invoiced_length") }}<br/>({{ isMetric() ? 'm' : $t("general.ft") }})</th>
          <th v-if="showPileInstalledColumns && showExtension" class="piles-header">{{ $t("reportInfo.extension") }}</th>
          <th v-if="showPileInstalledColumns && showPileHeightAboveGround" class="piles-header" :class="{ 'required': pileHeightAboveGroundMandatory }">{{ $t("general.heightaboveground_1") }} ({{ isMetric() ? 'm' : $t("general.ft") }})</th>
          <th v-if="showPileInstalledColumns && (showPileImpacts || showHammer )" class="piles-header" :class="{ 'required': pileImpactsMandatory }">{{ $t("reportInfo.impact") }}</th>
          <th v-if="showPileInstalledColumns && validMotors.length > 1" class="required piles-header">{{ $t("reportInfo.useddealermachines") }}</th>
          <th v-if="showPileInstalledColumns && showCompression" class="piles-header">{{ $t("reportInfo.compressioncapacity") }} ({{ (isCalculationInPounds() ? $t("reportInfo.lb") : $t("reportInfo.ton")) }})</th>
          <th v-if="showPileInstalledColumns && showTension" class="piles-header">{{ $t("reportInfo.tensioncapacity") }} ({{ (isCalculationInPounds() ? $t("reportInfo.lb") : $t("reportInfo.ton")) }})</th>
          <th v-if="showPileInstalledColumns && showTorque" class="piles-header">{{ $t("reportInfo.torque") }} ({{ (isCalculationInPounds() ? $t("reportInfo.lb-pi") : $t("reportInfo.N-m")) }})</th>
          <th v-if="showPileNotInstalledColumns" class="required piles-header">{{ $t("reportInfo.reason") }}</th>
        </tr>
        <draggable v-model="orderedPiles" tag="tbody" handle=".handle" :disabled="isReadOnly">

        <!-- https://vuetifyjs.com/en/components/lazy/ 
             https://github.com/vuetifyjs/vuetify/issues/3435 -->
        <v-lazy tag="tr" style="height: 25px;" v-for="(pile, index) in orderedPiles" :key="pile.id" :class="{ 'gray': index % 2 == 1 }">
        <div style="display: contents;vertical-align:top;">
          <td :style="{'min-width':showPileName?'167px':'140px'}">
            <div style="display:inline-block;vertical-align:top;line-height:40px;margin-top:25px;">
              <v-checkbox style="margin-top:0px;padding-top:0px;display:inline-block;vertical-align:middle;" label="" :disabled="isReadOnly" hide-details v-model="pile.isChecked" v-on:change="pileChecked(pile)"></v-checkbox>
              <img class="handle" src="/assets/icon/drag-vertical.svg" height="25px" style="vertical-align:middle;margin-top:-1px;" />
            </div>
            <div style="display:inline-block;max-width:100px;">
              <br v-if="!showPileName" style="padding-top:10px;"/>
              <div :style="{'padding-top':showPileName?'0px':'10px'}" style="height:25px;padding-left:5px;color:#009f4d;font-weight:bold;" :class="{ 'errored' : pile.error }">
                <span>{{ $t("reportInfo.pile") }} #{{index+1}}</span>
                <v-icon v-if="pile.isNotInstalled" style="margin-left:5px;margin-top:-3px;" :title="$t('reportInfo.not_installed')">mdi-cancel</v-icon>
              </div>
              <v-text-field v-if="showPileName" outlined dense hide-details maxlength="45" :value="pile.name" @change="v => { pile.name = v; updatePileName(pile); }" :disabled="isReadOnly" style="background-color:white;"></v-text-field>
            </div>
          </td>
          <td v-if="showPileInstalledColumns" style="min-width:75px;">
            <div v-if="!pile.isNotInstalled">
              <v-select
                  outlined
                  dense
                  hide-details
                  :items="availablePileModels"
                  item-text="name"
                  item-value="id"
                  :class="{ 'errored' : pile.errorNoPileModel }"
                  v-model="pile.pileModelId"
                  :disabled="isReadOnly"
                  @change="pileModelChanged(pile)"
                  style="display:inline-block;margin-top:25px;background-color:white;">
            </v-select>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showPileLength">
            <div v-if="!pile.isNotInstalled">
              <v-select
                  outlined
                  dense
                  hide-details
                  :items="pileLengths"
                  item-text="value"
                  item-value="id"
                  :disabled="isReadOnly"
                  v-model="pile.pileLength_ft"
                  @change="pileLengthChanged(pile)"
                  style="margin-top:25px;">
            </v-select>
            </div>
          </td>
          <td v-if="showPileInstalledColumns" style="text-align:center;white-space:nowrap;" :style="{'min-width':pile.pile_helixes.length>1?'220px':'150px'}">
            <div v-if="!pile.isNotInstalled">
              <v-checkbox v-if="showAnchoredBedrock" class="small-text" style="padding-top:0px;" :style="{ 'margin-top': pile.isAnchoredToBedrock ? '32px' : '0px' }" v-model="pile.isAnchoredToBedrock" :disabled="isReadOnly" :label="$t('reportInfo.anchored')" hide-details @change="updatePileAnchored(pile)"></v-checkbox>
              <div v-if="!pile.isAnchoredToBedrock" style="text-align:left;" :style=" { 'margin-top': showAnchoredBedrock ? '1px' : '25px' }">
                  <v-select
                    v-for="(helix, index) in pile.pile_helixes" v-bind:key="'H'+ index"
                    outlined
                    dense
                    hide-details
                    :items="helixModelsWithEmpty"
                    item-text="name"
                    item-value="id"
                    :value="helix.helixModelId"
                    :disabled="isReadOnly"
                    @change="helixModelChanged(pile, helix, $event, index)"
                    style="display:inline-block;max-width:70px;background-color:white;"
                  ></v-select>
                  <v-select
                    class="small-text"
                    v-if="pile.pile_helixes.length < 3 && !isReadOnly"
                    outlined
                    dense
                    hide-details
                    :items="helixModels"
                    :label="$t('general.add')"
                    :class="{ 'errored' : pile.errorNoHelixes }"
                    item-text="name"
                    item-value="id"
                    :disabled="isReadOnly"
                    v-model="pile.addHelix"
                    return-object
                    @change="helixAdded(pile, $event)"
                    style="display:inline-block;max-width:70px;background-color:white;vertical-align:top;"
                  ></v-select>
              </div>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showPileFinish" style="text-align:center;min-width:130px;">
            <div v-if="!pile.isNotInstalled">
              <v-select style="display:inline-block;margin-top:25px;background-color:white;" dense outlined hide-details
                      :items="[ { name: '' , id: null }, { name: $t('reportInfo.black'), id: 0 }, { name: $t('reportInfo.galvanized'), id: 1 } ]"
                      item-text="name"
                      item-value="id"
                      :disabled="isReadOnly"
                      :class="{ 'errored' : pile.errorSurfaceFinishEnum }"
                      v-model="pile.surfaceFinishEnum"
                      @change="updateSurfaceFinishEnum(pile)" ></v-select>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showPilePlateDimension" style="text-align:center;min-width:135px;">
            <div v-if="!pile.isNotInstalled">
              <v-select
                class="small-text"
                outlined
                dense
                hide-details
                :items="selectedReport.pilePlateDimensions"
                :item-text="dimension => dimension.isOther ? $t('reportInfo.other') : dimension.dimension"
                item-value="id"
                :disabled="isReadOnly"
                v-if="!isReadOnly || pile.pilePlatesDimensionId > 0"
                v-model="pile.pilePlatesDimensionId"
                :class="{ 'errored' : pile.errorPlateDimensions }"
                style="display:inline-block;width:100%;max-width:250px;background-color:white;vertical-align:top;"
                :style="{ 'margin-top': (pile.pilePlatesDimensionId == -1 ? '0px' : '25px') }"
                @change="updatePlateDimensionId(pile)"
              ></v-select>
            <v-text-field v-if="pile.pilePlatesDimensionId == -1"
                          style="background-color:white;margin-top: 0px;" outlined dense hide-details
                          :value="pile.customPlateDimensions" 
                          @change="v => { pile.customPlateDimensions = v; updateCustomerPlateDimensions(pile); }" 
                          maxlength="45" 
                          :style="{ 'margin-top': (isReadOnly ? '25px' : '0px') }"
                          :disabled="isReadOnly"></v-text-field>

                          <div v-if="pile.pilePlatesDimensionId == -1 && !showPlateTypeColumn" style="margin-top:1px;">
                            <v-select
                              class="small-text"
                              outlined
                              dense
                              hide-details
                              :items="[ { name: $t('reportInfo.fix'), id: true }, { name: $t('reportInfo.adjustable'), id: false } ]"
                              :label="$t('general.mobility')"
                              item-text="name"
                              item-value="id"
                              :class="{ 'errored' : pile.errorPlateTypeEnum }"
                              :disabled="isReadOnly || pile.plateReadonly"
                              v-model="pile.isFixPlate"
                              style="display:inline-block;width:110px;background-color:white;vertical-align:top;"
                              @change="isFixPlateChanged(pile)"
                            ></v-select>
                          </div>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showPlateTypeColumn" style="text-align:center;min-width:210px;">
            <div v-if="!pile.isNotInstalled">
              <v-checkbox class="small-text" style="padding-top:0px;" :style="{ 'margin-top': pile.isCustomPlate ? '32px' : '0px' }" v-model="pile.isCustomPlate" :disabled="isReadOnly || pile.plateReadonly" :label="$t('reportInfo.customplate')" hide-details @change="isCustomPlateChanged(pile)"></v-checkbox>
              <div v-if="!pile.isCustomPlate" style="margin-top:1px;text-align:left;">
                <v-select
                  class="small-text"
                  outlined
                  dense
                  hide-details
                  :items="[ { name: $t('reportInfo.fix'), id: true }, { name: $t('reportInfo.adjustable'), id: false } ]"
                  :label="$t('general.mobility')"
                  item-text="name"
                  item-value="id"
                  :class="{ 'errored' : pile.errorPlateTypeEnum }"
                  :disabled="isReadOnly || pile.plateReadonly"
                  v-model="pile.isFixPlate"
                  style="display:inline-block;width:110px;background-color:white;vertical-align:top;"
                  @change="isFixPlateChanged(pile)"
                ></v-select>
                <v-select
                  class="small-text"
                  outlined
                  dense
                  hide-details
                  :items="[ { name: $t('reportInfo.square'), id: PLATE_FORMS.SQUARE }, { name: $t('reportInfo.u'), id: PLATE_FORMS.U }, { name: $t('reportInfo.l'), id: PLATE_FORMS.L }, { name: $t('reportInfo.box'), id: PLATE_FORMS.BOX }, { name: $t('reportInfo.underpinning'), id: PLATE_FORMS.UNDERPINNING } ]"
                  :label="$t('general.form')"
                  item-text="name"
                  item-value="id"
                  :class="{ 'errored' : pile.errorPlateTypeEnum }"
                  :disabled="isReadOnly || pile.plateReadonly"
                  v-model="pile.plateTypeEnum"
                  style="display:inline-block;width:90px;background-color:white;vertical-align:top;"
                  @change="plateTypeEnumChanged(pile)"
                ></v-select>
              </div>
            </div>
          </td>
          <td v-else-if="showPileInstalledColumns && showPileConcreteBlock" style="text-align:center;min-width:135px;">
            <div v-if="!pile.isNotInstalled">
              <v-select
                class="small-text"
                outlined
                dense
                hide-details
                :items="concreteBlockTypes"
                :item-text="blockType => blockType.id == -1 ? $t('reportInfo.other') : blockType.name"
                item-value="id"
                :disabled="isReadOnly"
                v-model="pile.concreteBlockTypeId"
                style="display:inline-block;width:100%;max-width:250px;background-color:white;vertical-align:top;"
                :style="{ 'margin-top': (pile.concreteBlockTypeId == -1 ? '0px' : '25px') }"
                @change="updateConcreteBlockTypeId(pile)"
                :class="{ 'errored' : pile.errorConcreteBlock }"
              ></v-select>
            <v-text-field v-if="pile.concreteBlockTypeId == -1"
                          style="background-color:white;margin-top:0px;" outlined dense hide-details
                          :value="pile.customConcreteBlockType" 
                          @change="v => { pile.customConcreteBlockType = v; updateCustomConcreteBlockType(pile); }" 
                          maxlength="45" 
                          :disabled="isReadOnly"></v-text-field>
            </div>
          </td>
          <td v-if="showPileInstalledColumns" style="text-align:center;min-width:150px;">
            <div v-if="!pile.isNotInstalled">
              <div v-if="!pile.isAnchoredToBedrock">
                <div v-if="showPileBedrockDropdown(pile)">
                  <v-select style="background-color:white;" dense outlined hide-details
                            :items="[ { name: '', id: null }, { name: $t('reportInfo.spinning'), id: BEDROCK_ENUM.SPINNING }, { name: $t('reportInfo.bedrock'), id: BEDROCK_ENUM.BEDROCK } ]"
                            :label="$t('reportInfo.falserefusal')"
                            item-text="name"
                            item-value="id"
                            :disabled="isReadOnly"
                            v-model="pile.bedrockEnum"
                            @change="updateBedrockEnum(pile)"></v-select>
                </div>
                <v-checkbox v-else class="small-text" style="margin-top:0px;padding-top:0px;" v-model="pile.isFalseRefusal" :disabled="isReadOnly" :label="$t('reportInfo.falserefusal')" hide-details @change="checkFalseRefusal(pile)"></v-checkbox>

                <v-text-field outlined dense hide-details style="margin-top:1px;background-color:white;" :class="{ 'errored' : pile.errorMissingPressure }"
                              :label="$t('reportInfo.pressure')" class="smaller"
                              type="number" @wheel="$event.target.blur()" :value="pile.pressure_psi" min="0" maxlength="9" oninput="if (this.value && this.value.length > this.maxLength) { this.value=this.value.slice(0,this.maxLength) }" @change="v => { pile.pressure_psi = v; updatePressureChange(pile); }" :disabled="isReadOnly"></v-text-field>

                <v-alert dense type="info" v-if="showPilePressureWarning(pile)" class="pile-alert">{{ $t('reportInfo.pressure_before_bedrock') }}</v-alert>
              </div>
            </div>
          </td>
          <td style="text-align:center;min-width:150px;">

            <MeasureFields 
              :ref="'pileDepthRef'"
              class="mt-3"
              :errored="pile.errorDepth"
              :readonly="isReadOnly" 
              :ismetric="isMetric()" 
              :valueInFt="pile.depth_ft"
              :maxValue="9999"
              :objectToUpdate="pile" 
              outlined
              @changed="updateDepthChange" />

              <v-alert
                dense
                type="info"
                v-if="!pile.isAnchoredToBedrock && pile.depth_ft != null && pile.depth_ft < selectedReport.project.dealer.depthAlertCompression_ft && !pile.isNotInstalled"
                class="pile-alert">
                {{ isMetric() ? $t('reportInfo.depth_alert_m', {x:convertFeetsToMeters(selectedReport.project.dealer.depthAlertCompression_ft)}) : $t('reportInfo.depth_alert_ft', {x:selectedReport.project.dealer.depthAlertCompression_ft}) }}
              </v-alert>
          </td>
          <td v-if="showPileInstalledColumns && showInvoiceLength" style="text-align:center;min-width:125px;">
            <div v-if="!pile.isNotInstalled">
              <v-text-field outlined dense hide-details style="margin-top:24px;background-color:white;"
                          type="number" @wheel="$event.target.blur()" :label="isMetric() ? 'm' : $t('general.ft')" :value="pile.editInvoicedLength"
                          maxlength="3"
                          @change="v => { updateInvoicedLength(pile, v); }" :disabled="isReadOnly"></v-text-field>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showExtension" style="text-align:center;min-width:200px;">
            <div v-if="!pile.isNotInstalled">
              <v-row no-gutters>
                <v-col cols="7">
                  <v-select
                    outlined
                    dense
                    hide-details
                    :label="$t('reportInfo.extension_length') + ' (' + (isMetric() ? 'm' : $t('general.ft')) + ')'"
                    :items="selectedReport.pileExtensionLengths"
                    item-text="value"
                    item-value="id"
                    :disabled="isReadOnly"
                    v-model="pile.editExtensionLength"
                    @change="updatePileExtensionLengthEdit(pile)" 
                    style="display:inline-block;width:100%;max-width:250px;background-color:white;vertical-align:top;"
                    :style="{ 'margin-top': (pile.editExtensionLength == -1 ? '0px' : '24px') }"
                  ></v-select>
                <v-text-field v-if="pile.editExtensionLength == -1"
                              style="background-color:white;" outlined dense hide-details
                              :label="isMetric() ? 'm' : $t('general.ft')"
                              :value="pile.editCustomExtensionLength" 
                              @change="v => { pile.editCustomExtensionLength = v; customExtensionLengthChanged(pile); }" 
                              maxlength="3" 
                              type="number" @wheel="$event.target.blur()"
                              :disabled="isReadOnly"></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field :class="{ 'text-field-units': pile.extensionUnitsCount == null }"
                              style="background-color:white;margin-top:24px;" outlined dense hide-details
                              :label="$t('reportInfo.extension_units')"
                              :value="pile.extensionUnitsCount" 
                              @change="v => { pile.extensionUnitsCount = v; updatePileExtensionUnits(pile); }" 
                              maxlength="3" 
                              type="number" @wheel="$event.target.blur()"
                              :disabled="isReadOnly"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showPileHeightAboveGround" style="text-align:center;min-width:150px;">
              <div v-if="!pile.isNotInstalled">
                <MeasureFields 
                :ref="'pileHeightAboveGroundRef'"
                class="mt-3"
                :errored="pile.errorHeightAboveGround"
                :readonly="isReadOnly" 
                :ismetric="isMetric()" 
                :valueInFt="pile.heightAboveGround_ft" 
                :maxValue="9999"
                :objectToUpdate="pile" 
                outlined
                @changed="updateHeightAboveGround" />

              </div>
          </td>
          <td v-if="showPileInstalledColumns && (showPileImpacts || showHammer)" style="min-width:250px;">
            <div v-if="!pile.isNotInstalled">
              <v-select v-if="showHammer" class="small-control" style="display:inline-block;height:25px;min-height:25px;background-color:white;width:100%;top:-6px;vertical-align: top;" dense outlined hide-details
                  :items="[ { name: '' , id: null }, { name: $t('reportInfo.mass8') , id: 0 }, { name: $t('reportInfo.mass16'), id: 1 }, { name: 'PJ1', id: 2 }, { name: 'PJR', id: 3 } ]"
                  item-text="name"
                  item-value="id"
                  :disabled="isReadOnly"
                  v-model="pile.hammerEnum" 
                  @change="updateHammerEnum(pile)"></v-select>
              <br v-if="!showHammer">

              <div v-if="showPileImpacts">
                <ImpactMeasureField 
                  :placeholder="'#1'"
                  :readonly="isReadOnly" 
                  :ismetric="isMetricImpact()" 
                  :valueInInches="pile.impact1_ft" 
                  :objectToUpdate="pile"
                  @changed="updateImpact1Change"
                  :class="{ 'errored' : pile.errorImpact }"
                />
                
                <ImpactMeasureField 
                  :placeholder="'#2'"
                  :readonly="isReadOnly" 
                  :ismetric="isMetricImpact()" 
                  :valueInInches="pile.impact2_ft" 
                  :objectToUpdate="pile"
                  @changed="updateImpact2Change"
                  :class="{ 'errored' : pile.errorImpact }"
                />

                <ImpactMeasureField 
                  :placeholder="'#3'"
                  :readonly="isReadOnly" 
                  :ismetric="isMetricImpact()" 
                  :valueInInches="pile.impact3_ft" 
                  :objectToUpdate="pile"
                  @changed="updateImpact3Change"
                  :class="{ 'errored' : pile.errorImpact }"
                />

              </div>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && validMotors.length > 1" style="min-width:225px;">
            <div v-if="!pile.isNotInstalled">
              <v-select class="small-text" style="display:inline-block;margin-top:25px;background-color:white;width:100%;" dense outlined hide-details :class="{ 'errored' : pile.errorSiteDealerMachineMotor }"
                  :items="validMotors"
                  :item-text="dealerMachineMotor => getDealerMachineMotorDisplayName(dealerMachineMotor)"
                  item-value="id"
                  :disabled="isReadOnly"
                  v-model="pile.siteDealerMachineMotorId"
                  @change="updateSiteDealerMachineMotorPile(pile)"
              ></v-select>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showCompression" style="text-align:center;min-width:100px;">
            <div v-if="!pile.isNotInstalled">
              <v-text-field style="display:inline-block;margin-top:25px;background-color:white;" outlined dense hide-details
                          type="number" @wheel="$event.target.blur()" disabled :value="displayedCompressionValue(pile)" ></v-text-field>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showTension" style="text-align:center;min-width:100px;">
            <div v-if="!pile.isNotInstalled">
              <v-text-field style="display:inline-block;margin-top:25px;background-color:white;" outlined dense hide-details
                          type="number" @wheel="$event.target.blur()" disabled :value="displayedTensionValue(pile)"></v-text-field>
            </div>
          </td>
          <td v-if="showPileInstalledColumns && showTorque" style="text-align:center;min-width:100px;">
            <div v-if="!pile.isNotInstalled">
              <v-text-field style="display:inline-block;margin-top:25px;background-color:white;" outlined dense hide-details
                          type="number" @wheel="$event.target.blur()" disabled :value="displayedTorqueValue(pile)">
              </v-text-field>
            </div>
          </td>
          <td v-if="showPileNotInstalledColumns" style="text-align:center;min-width:200px;">
            <div v-if="pile.isNotInstalled">
              <v-select
                class="small-text"
                outlined
                dense
                hide-details
                :items="pileNotInstalledReasons"
                item-text="value"
                item-value="id"
                :disabled="isReadOnly"
                v-if="!isReadOnly || pile.notInstalledReason != PILE_NOT_INSTALLED_REASON.OTHER"
                v-model="pile.notInstalledReason"
                style="display:inline-block;width:100%;max-width:250px;background-color:white;vertical-align:top;"
                :style="{ 'margin-top': (pile.notInstalledReason == PILE_NOT_INSTALLED_REASON.OTHER ? '0px' : '25px') }"
                :class="{ 'errored' : pile.errorNotInstalledReason }"
                @change="updateNotInstalledReason(pile)">
            </v-select>
              
            <v-text-field v-if="pile.notInstalledReason == PILE_NOT_INSTALLED_REASON.OTHER"
                          style="background-color:white;margin-top: 0px;" outlined dense hide-details
                          :value="pile.notInstalledOtherReason" 
                          @change="v => { pile.notInstalledOtherReason = v; updateNotInstalledOtherReason(pile); }" 
                          maxlength="100" 
                          :style="{ 'margin-top': (isReadOnly ? '25px' : '0px') }"
                          :class="{ 'errored' : pile.errorNotInstalledReason }"
                          :disabled="isReadOnly"></v-text-field>
            </div>
          </td>
        </div>
        </v-lazy>
        </draggable>
      </table>
      </div>

      <div class="add-container" v-if="selectedReport" style="margin: 10px 0px 0px 0px;">
        <div style="display:inline-block;">
          <v-btn :disabled="isReadOnly" :loading="addingPile" class="techno-green" fab v-on:click="addPile()" :class="{ 'btn-errored' : selectedReport.errorAddPile }">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <span style="display:block;font-size:14px;">{{ $t('reportInfo.newpile') }}</span>
        </div>
        <div style="display:inline-block;margin-left:25px;" v-if="selectedReport.piles.length > 0">
          <v-btn :disabled="isReadOnly" :loading="copyingPile" class="techno-green" fab v-on:click="copyLastPileConfirm()">
            <img src="/assets/icon/copy.svg" style="padding:10px;">
          </v-btn>
          <span style="display:block;font-size:14px;">{{ singleSelectedPile ? $t('reportInfo.copypile') : $t('reportInfo.copylast') }}</span>
        </div>
        <div v-if="singleSelectedPile" style="display:inline-block;">
          <div style="display:inline-block;margin-left:25px;">
            <v-btn :disabled="isReadOnly" class="techno-green" fab v-on:click="upPile()">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
            <span style="display:block;font-size:14px;">{{ $t('reportInfo.up_pile') }}</span>
          </div>
          <div style="display:inline-block;margin-left:25px;">
            <v-btn :disabled="isReadOnly" class="techno-green" fab v-on:click="downPile()">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <span style="display:block;font-size:14px;">{{ $t('reportInfo.down_pile') }}</span>
          </div>
          <div style="display:inline-block;margin-left:25px;">
            <v-btn :disabled="isReadOnly" class="techno-green" fab v-on:click="deletePile()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <span style="display:block;font-size:14px;">{{ $t('reportInfo.delete_pile') }}</span>
          </div>
          <div v-if="showNotInstalled" style="display:inline-block;margin-left:25px;">
            <v-btn :disabled="isReadOnly" class="techno-green" fab v-on:click="toggleNotInstalled()">
              <v-icon v-if="singleSelectedPile.isNotInstalled">mdi-check-circle-outline</v-icon>
              <v-icon v-else>mdi-cancel</v-icon>
            </v-btn>
            <span style="display:block;font-size:14px;">{{ singleSelectedPile.isNotInstalled ? $t('reportInfo.installed') : $t('reportInfo.not_installed') }}</span>
          </div>
        </div>
        <div v-else-if="selectedPiles.length > 1" style="display:inline-block;">
          <div style="display:inline-block;margin-left:25px;">
            <v-btn :disabled="isReadOnly" class="techno-green" fab v-on:click="deletePile()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <span style="display:block;font-size:14px;">{{ $t('reportInfo.delete_pile') }}</span>
          </div>
        </div>
      </div>
    </v-card>

    <div v-if="selectedReport && showTorqueReadings">
      <v-card class="border10 mx-3 my-3" style="padding:10px;overflow:hidden;">
        <h4 style="margin-bottom:10px;" :class="{ 'errored': selectedReport.errorReadings, 'required': torqueReadingMandatory(false) }">{{ $t("reportInfo.testprofile") }}</h4>
        <div id="torque-piles" v-for="(torqueReadingPile, index) in torqueReadingPiles" v-bind:key="'I' + index">
          <v-row no-gutters>
            <span style="min-width:80px;">{{ $t('reportInfo.pile') }} #{{ torqueReadingPile.pileNumber }} {{ selectedReport.piles[torqueReadingPile.pileNumber-1].name }}</span>
          </v-row>

          <v-row no-gutters>
            <v-col v-for="(torqueReading, index) in torqueReadingPile.readings" v-bind:key="'J' + index" 
                  xl="1" lg="2" md="2" sm="3" cols="6"
                  style="padding:2px;">
          
              <v-card style="position:relative;padding:8px;" elevation="5">
                <span class="custom-label">{{ isMetric() ? $t('reportInfo.depth_m') : $t('reportInfo.depth_ft') }}</span>
                <MeasureFields
                    :ref="'torqueReadingRef'"
                    :readonly="isReadOnly" 
                    :ismetric="isMetric()" 
                    :valueInFt="torqueReading.depth_ft" 
                    :maxValue="9999"
                    :objectToUpdate="torqueReading"
                    @changed="torqueDepthChanged"
                  />

                <v-row>
                  <v-col>
                    <v-text-field
                      :class="{ 'errored' : torqueReading.pressureError, 'required': torqueReadingMandatory(true)}"
                      :label="$t('reportInfo.pressure')"
                      type="number" @wheel="$event.target.blur()"
                      min="0"
                      dense
                      maxlength="5"
                      oninput="if (this.value && this.value.length > this.maxLength) { this.value=this.value.slice(0,this.maxLength) }"
                      hide-details
                      :value="torqueReading.pressure_psi" @change="v => { torqueReading.pressure_psi = v; updateTorqueReadingPressure(torqueReading); }" 
                      :disabled="isReadOnly"
                    ></v-text-field>
                  </v-col>
                </v-row>
              
                <v-icon style="position:absolute;top:5px;right:5px;" 
                        v-if="!isReadOnly"
                        v-on:click="deleteTorqueReading(torqueReading)">mdi-close</v-icon>
              </v-card>
            </v-col>
            <v-col v-if="!isReadOnly" xl="1" lg="2" md="2" sm="3" cols="6" class="add-test-col">
              <div class="add-test-container">
                <v-btn class="techno-green" :loading="isAddingReadingForPileNumber(torqueReadingPile.pileNumber)" fab v-on:click="addTorqueReading(torqueReadingPile)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <span style="display:block;font-size:14px;">{{ $t('general.add') }}</span>
              </div>
            </v-col>
          </v-row>
          <v-divider style="margin: 5px 0px 5px 0px;" />
        </div>

        <v-progress-circular v-if="isAddingReadingForPileNumber()" indeterminate class="my-3"></v-progress-circular>
        <v-row v-else-if="!isReadOnly" class="mt-0">
          <v-col lg="4" md="6" sm="12" xs="12" cols="12">
            <v-select
              dense
              :items="availableTorqueReadingPileNumbers"
              :label="$t('reportInfo.add_torque_readings_pile')"
              item-text="name"
              item-value="id"              
              v-model="addTorqueReadingPileNumber"
              @change="addTorqueReadingPileChanged()"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div class="mx-3" v-if="selectedReport && selectedReport.requestType == REQUEST_TYPES.PERMIT_REQUEST" style="font-weight:bold;font-style: italic;">
       {{ $t('reportInfo.permit_pile_comments') }}
    </div>

    <v-card class="border10 mx-3 my-3" style="padding:10px;overflow:hidden;" v-if="selectedReport">
      <v-row>
        <v-col v-if="showComments" :md="userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_VIEW_LOGS || uf.id == FUNCTIONS.DOCUMENT_REQUEST_VIEW_LOGS) ? 6 : 12" cols="12" class="border-right">
          <h3 style="margin-bottom:10px;">{{ $t("reportInfo.comments") }}</h3>
          <div style="max-height:400px;overflow:auto;">
            <div
              class="comment-container"
              v-for="(comment, index) in selectedReport.comments"
              v-bind:key="'K'+ index">
              <div>
                <span v-if="comment.user.colorCode" :style="{ 'background': comment.user.colorCode }" style="margin-right:3px;width:15px;height:15px;display:inline-block;vertical-align:middle;border-radius:3px;"></span>
                <span style="font-weight: bold; display:inline-block;color:#6b6b6b!important;font-size:14px;vertical-align:middle;">{{ comment.user.firstname + ' ' + comment.user.lastname }}</span>
              </div>
              <span style="float:right;font-size:13px;">{{ comment.updatedAt }}</span>
              <v-textarea v-if="comment.editcomment" rows="2" hide-details :value="comment.comment" @change="v => comment.comment = v" style="margin: 5px 0px;white-space: pre-wrap;"></v-textarea>
              <div v-else style="margin:5px 0px;white-space: pre-wrap;" v-html="comment.formattedComment"></div>

              <div v-if="!comment.editcomment && currentUser && comment.userId == currentUser.id && !commentReadOnly">
                <v-btn v-on:click="editComment(comment)" style="font-weight:bold;color:#000000;font-size:11px;" x-small plain>{{ $t("reportInfo.modify") }}</v-btn>
                <div class="black-dot"></div>
                <v-btn v-on:click="confirmDeleteComment(index)" style="font-weight:bold;color:#000000;font-size:11px;" x-small plain>{{ $t("status.canceled") }}</v-btn>
              </div>

              <div v-if="comment.editcomment">
                <v-btn v-on:click="cancelComment(comment)" style="min-width: 150px;">{{ $t("report.cancel") }}</v-btn>
                <v-btn v-on:click="updateCommentChanged(comment)" color="#009f4d" style="color:white;min-width:150px;margin-left:5px;">{{ $t("report.save") }}</v-btn>
              </div>
            </div>

            <div v-if="addingComment" style="text-align: center;">
              <v-progress-circular indeterminate class="my-3"></v-progress-circular>
            </div>

            <div v-if="addCommentClicked" style="border-top: 1px solid #d0d0d0;">
              <v-textarea rows="2" hide-details :value="newComment" @change="v => newComment = v" style="margin: 5px 0px;white-space: pre-wrap;" :placeholder="$t('reportInfo.newcomment') + '...'"></v-textarea>
                <div>
                  <v-btn v-on:click="toggleNewComment(false)" style="min-width: 150px;">{{ $t("report.cancel") }}</v-btn>
                  <v-btn v-on:click="saveNewComment()" color="#009f4d" style="color:white;min-width:150px;margin-left:5px;">{{ $t("report.save") }}</v-btn>
                </div>
            </div>
            <div v-else-if="!commentReadOnly" style="text-align:center;">
              <v-btn class="techno-green" style="height:40px;width:40px;" fab v-on:click="toggleNewComment(true)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <span style="display:block;font-size:14px;">{{ $t('reportInfo.addcomment') }}</span>
            </div>
          </div>
        </v-col>
        <v-col v-if="userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_VIEW_LOGS || uf.id == FUNCTIONS.DOCUMENT_REQUEST_VIEW_LOGS)" md="6" cols="12">
          <h3 style="margin-bottom:10px;">{{ $t("reportInfo.history") }}</h3>
          <div style="max-height:400px;overflow:auto;">
             <div v-if="userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_VIEW_LOGS)">
                <div v-for="(history, index) in selectedReport.logs" v-bind:key="'L'+ index" style="padding:5px;" :class="{ 'gray': index % 2 == 1 }">
                  <span>{{ getHistoryDetail(history, index) }}</span>
                  <span style="float: right; font-size: 13px;">{{ history.createdAt }}</span>
                </div>
             </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="confirmDialog" max-width="600">
      <v-card>
        <v-card-title>
          {{ confirmDialogTitle }}
        </v-card-title>
        <v-card-text>
          <v-alert v-if="confirmAction == ConfirmAction.HardDeleteReport" type="error">{{ confirmDialogBody }}</v-alert>
          <span v-else>{{ confirmDialogBody }}</span>
        </v-card-text>
        <v-card-actions>
          <v-text-field ref="copyPileAmount" v-if="confirmAction == ConfirmAction.CopyLastPile" :value="copyPileAmount" @change="v => copyPileAmount = v" @focus="$event.target.select()" type="number" @wheel="$event.target.blur()" min="1" max="100"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmDialogNo()">{{ confirmYesNo ? $t("reportInfo.no") : $t("confirm.cancel") }}</v-btn>
          <v-btn color="green darken-1" text @click="confirmDialogYes()">{{ confirmYesNo ? $t("reportInfo.yes") : $t("confirm.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="photosDialog" width="unset" content-class="photosDialog">
      <v-carousel height="auto" v-model="selectedPhotoIndex">
        <v-carousel-item v-for="(photo, i) in allPhotos" :key="i">
          <v-sheet height="100%" tile style="max-height:90vh; max-width: 90vw;">
            <img :src="photo.url.image" style="max-height:90vh;max-width: 90vw;">
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>

    <v-dialog v-if="transformDialog" v-model="transformDialog" max-width="900">
      <v-card>
        <v-card-title>
          {{ transformDialogTitle }}
        </v-card-title>
        <v-card-text>{{ transformDialogBody }}</v-card-text>
        <v-card-actions>
          <v-select
            dense
            :items="[ { name: $t('confirm.select_similar_client'), id: 0 }, { name: $t('confirm.save_new_client'), id: 1 } ]"
            item-text="name"
            item-value="id"
            v-model="transformCustomerOption"
          >
          </v-select>
        </v-card-actions>
        <v-card-actions>
          <v-select
                dense
                :items="similarCustomers"
                :item-text="similarCustomer => similarCustomer.formattedNameAndCompany + ' - ' + similarCustomer.formattedAddress"
                item-value="id"
                v-model="selectedSimilarCustomerId"
                v-if="transformCustomerOption == 0"
                :label="$t('confirm.update_similar_client')"
              >
          </v-select>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="transformDialog = false;">{{ $t("confirm.cancel") }}</v-btn>
          <v-btn color="green darken-1" text @click="saveCustomerToDB()" v-if="transformCustomerOption == 1">{{ $t("confirm.save_client") }}</v-btn>
          <v-btn color="green darken-1" text @click="updateExistingCustomer()" v-else>{{ $t("confirm.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="conformityAttestationModal" max-width="600" persistent>
      <v-card>
        <v-card-title v-if="newDocumentRequestType == DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM">
          {{ $t('documentRequests.dr_buildinginspectionform') }}
        </v-card-title>
        <v-card-title v-else-if="newDocumentRequestType == DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST">
          {{ selectedReport.project.dealer.engineering_department && selectedReport.project.dealer.engineering_department.conformityReportAsDoe ? $t('documentRequests.dr_doe') : $t('documentRequests.dr_attestation') }}
        </v-card-title>
        <v-card-title v-else-if="newDocumentRequestType == DOCUMENT_REQUEST_TYPES.STAMPED_FIELD_REPORT">
          {{ $t('documentRequests.dr_stampedfieldreport') }}
        </v-card-title>
        <v-card-title v-else-if="newDocumentRequestType == DOCUMENT_REQUEST_TYPES.T_PRO">
          {{ $t('documentRequests.dr_tpro') }}
        </v-card-title>
        <v-card-title v-else>
          {{ $t('reportInfo.requests')}}
        </v-card-title>
        <v-card-text>
             <v-container>
                <v-select
                  :items="availableConformityReportDate"
                  :item-text="availableDate => availableDate.numberDays + ' ' + availableDate.unit + ' (' + availableDate.day + ' ' + availableDate.date  + ')'"
                  item-value="date"
                  v-model="selectedDateForRequest"
                  :label="$t('reportInfo.expected_date')"
                >
                </v-select>

                <div style="position:relative;" v-if="newDocumentRequestType == DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM">
                  <div>
                    <h4 class="techno-text required">{{ $t("reportInfo.approvedpermitplan") }}</h4>
                    <div class="upload-btn-wrapper">
                      <v-btn fab color="#009f4d" dark v-on:click="uploadBtnClicked(fileCategoryPermitPlan)">
                        <v-progress-circular v-if="isSavingPermitPlan" color="white" indeterminate></v-progress-circular>
                        <v-icon v-else>mdi-cloud-upload</v-icon>
                      </v-btn>

                      <input style="display:none;" type="file" name="file" multiple :id="fileCategoryPermitPlan.id" v-on:change="uploadFile(fileCategoryPermitPlan)" :accept="fileCategoryPermitPlan.acceptFiles" />
                    </div>
                  </div>
                  <div
                    @dragover.prevent="dragOver"
                    @dragleave.prevent="dragLeave"
                    @drop.prevent="dropFile($event, fileCategoryPermitPlan)"
                    class="mt-3"
                  >
                    <div class="request-container" v-if="selectedReport.approvedPermitPlans.length == 0">
                      <div class="requests-anchor">
                          <img class="required-img">
                          <span class="wrappedfile">{{ $t('reportInfo.required') }}</span>
                      </div>
                    </div>

                    <div class="request-container" v-for="(permitPlan, index) in selectedReport.approvedPermitPlans" v-bind:key="'M'+ index">
                        <div v-if="permitPlan.url.thumbnail != null" target="_blank" class="requests-anchor" @click="selectPhoto(permitPlan)" style="cursor:pointer;">
                          <img :src="permitPlan.url.thumbnail">
                          <span class="wrappedfile">{{ permitPlan.filename }}</span>
                        </div>
                        <a v-else :href="permitPlan.url.download" target="_blank" class="requests-anchor">
                          <img src="/assets/icon/doc-file.svg">
                          <span class="wrappedfile">{{ permitPlan.filename }}</span>
                        </a>
                      <v-progress-circular class="delete-photo-icon" style="height:24px;width:24px;" v-if="permitPlan.isInRotation" indeterminate></v-progress-circular>
                      <v-icon v-on:click="rotateImage(permitPlan)" 
                              v-else-if="permitPlan.url.thumbnail != null && (userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION))" 
                              class="delete-photo-icon" :title="$t('reportInfo.rotate_image')">mdi-rotate-3d-variant</v-icon>
                    </div>
                  </div>
                </div>
            </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="conformityAttestationDateCanceled">{{ $t("confirm.cancel") }}</v-btn>
          <v-btn color="green" style="color:white;"
                 :disabled="newDocumentRequestType == DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM && 
                            permitPlansMandatory &&
                            selectedReport.approvedPermitPlans.length == 0"
                 @click="conformityAttestationDateValided">{{ selectedReport && selectedReport.statusEnum == SITE_REPORT_STATUS.DRAFT ? $t("reportInfo.valid") :  $t("general.save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reportChangedModal" max-width="600" persistent>
      <v-card>
        <v-card-title>
          {{ $t('reportInfo.report_changed', {name: reportChangedModalBy})}}
        </v-card-title>
        <v-card-text>
          {{ $t('reportInfo.report_changed_details')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="reportChangedModal = false;reloadPage();">{{ $t("general.reload_page") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="tproDialog" width="500">
      <v-card>
        <v-card-title>
          {{ $t('reportInfo.tpro_title')}}
        </v-card-title>

        <v-card-text>
          {{ $t('reportInfo.tpro_body')}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="tproDialog = false"
          >
            {{ $t('confirm.ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="showMap">
      <MapEmbed
        :key = "mapkey"
        :lat = "selectedReport?.siteAddressLatitude"
        :lng = "selectedReport?.siteAddressLongitude"
        :countries = "countries"
        :fallbackAddress = "(selectedReport != null && selectedReport.project != null && selectedReport.project.dealer != null && selectedReport.project.dealer.addresses != null && selectedReport.project.dealer.addresses.length > 0) ? selectedReport.project.dealer.addresses[0] : null"
        :addressCurrentlySet = "selectedReport?.address1 != null && selectedReport.address1.toString().trim().length > 0"
        :close = "() => showMap = false"
        :latLngChanged = "(latLng) => {
          selectedReport.siteAddressLongitude = latLng.longitude;
          selectedReport.siteAddressLatitude = latLng.latitude;
          selectedReport.changes.siteAddressLongitude = latLng.longitude;
          selectedReport.changes.siteAddressLatitude = latLng.latitude;
          saveChanges();
        }"
        :addressChanged = "(addr) => {
          if (selectedReport.isCustomerAddress) saveCustomerAddress(addr);
          else saveProjectAddress(addr);
        }"
        :askConfirmApplyGPS = "() => askConfirmApplyGPS()"
      ></MapEmbed>
    </v-dialog>


    <v-dialog v-model="libraryDialog">
      <v-card>
        <v-card-actions>
          {{ $t("reportInfo.availableFiles") }}
          <v-spacer></v-spacer>
          <v-btn icon color="green darken-1" text @click="libraryDialog = !libraryDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row v-if="projectFiles.length == 0">
            <v-col class="text-center">
            <h3>{{ $t("reportInfo.noData") }}</h3>
          </v-col>
          </v-row>
          <v-row v-else>
            <v-col v-for="file in projectFiles" :key="file.id" lg="2" md="3" sm="6" cols="12">
              <v-card :disabled='file.disable' :style="file.disable?'opacity: 0.4;':''" @click="addProjectFile(file.id, libraryDialogCategory)">
                <v-card-text class="text-center pb-0">
                  <img :src="file.url.thumbnail" width="100%" v-if="file.isImage == true">
                  <v-icon size="165" v-if="file.isImage != true">mdi-file-outline</v-icon>
                </v-card-text>
                <v-card-title class="pt-0">{{file.filename}}</v-card-title>
                <!--<v-card-subtitle>LOrem</v-card-subtitle>-->
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import Breadcrumbs from "@/components/Breadcrumbs";
import MapEmbed from "@/components/MapEmbed";
import moment from 'moment'
import axios from "axios";
import authHeader from "@/services/auth-header";
import FileService from "@/services/file.service";
import ReportService from "@/services/report.service";
import UserService from "@/services/user.service";
import DealerService from "@/services/dealer.service";
import CustomerService from "@/services/customer.service";
import MeasureFields from '@/components/MeasureFields';
import ImpactMeasureField from '@/components/ImpactMeasureField';
import AddressField from '@/components/reusableFields/AddressField';
import { yyyymmdd } from '@/utils/validation';
import { REQUEST_TYPES, 
         REQUEST_TYPES_TEXT,
         PLATE_FORMS, 
         AVAILABLE_COUNTRIES, 
         CUSTOMER_CATEGORY, 
         SITE_REPORT_STATUS,
         SITE_REPORT_STATUS_TEXT,
         SITE_REPORT_STATUS_COLOR,
         DOCUMENT_REQUEST_TYPES,
         FUNCTIONS, 
         CALCULATION_FORMULA_TYPE, 
         REPORT_FILE_TYPE, 
         BEDROCK_ENUM,
         SITE_REPORT_DISPLAY_ZONES,
         SITE_REPORT_DISPLAY_DISPLAYS,
         PILE_NOT_INSTALLED_REASON,
         SOIL_TYPES
         } 
        from "@/enums";
import { updateCustomerFormattedName, getLongFormattedAddress } from "@/store/utility"
import { sortByProperty, sortByProperties, sortByInteger, getLanguage, parseError, formatDealerSupportingPlates, getFirstDayOfWeek } from "@/utils/utils"
import { WEEK_DAYS } from "@/utils/days"
import { convertFeetsToMeters, convertMetersToFeets, convertFeetsToFeetAndInches } from "@/utils/conversion"
import { ROLES, UNIT_MEASURES } from "@/enums";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

const ConfirmAction = Object.freeze({ 
  DeleteComment: 0, 
  DeleteFile: 1,
  DeletePile: 4, 
  DeleteReport: 5, 
  ReturnToDraft: 6, 
  CopyLastPile: 7, 
  DeletePermitPlan: 8, 
  ChangeGps: 9,
  PileNotInstalled: 10,
  ChangeProject: 11,
  HardDeleteReport: 12
 });

export default {
  components: {
    Breadcrumbs,
    MapEmbed,
    draggable,
    MeasureFields,
    ImpactMeasureField,
    AddressField,
    VuePdfApp
  },
  watch: {
    transformDialog() {
      if (!this.transformDialog && !this.savingCustomer) {
        this.transformingCustomer = false;
      }
    }
  },
  data() {
    return {
      SITE_REPORT_STATUS: SITE_REPORT_STATUS,
      SITE_REPORT_STATUS_TEXT: SITE_REPORT_STATUS_TEXT,
      SITE_REPORT_STATUS_COLOR: SITE_REPORT_STATUS_COLOR,
      FUNCTIONS: FUNCTIONS,
      AVAILABLE_COUNTRIES: AVAILABLE_COUNTRIES,
      REQUEST_TYPES: REQUEST_TYPES,
      CUSTOMER_CATEGORY: CUSTOMER_CATEGORY,
      BEDROCK_ENUM: BEDROCK_ENUM,
      DOCUMENT_REQUEST_TYPES: DOCUMENT_REQUEST_TYPES,
      PILE_NOT_INSTALLED_REASON: PILE_NOT_INSTALLED_REASON,
      ConfirmAction: ConfirmAction,
      selectedReportId: null,
      selectedReport: null,
      displayErrors: false,
      isSaving: false,
      locale: this.$i18n.locale,
      copyPileAmount: 1,
      startDateModal: false,
      endDateModal: false,
      conformityReportModal: false,
      date: null,
      filecategory: [
        { id: 1, cat: this.$t("reportInfo.sitephoto") },
        { id: 2, cat: this.$t("reportInfo.pilelocation") },
        { id: 3, cat: this.$t("reportInfo.otherdoc") },
      ],
      soiltypelist: SOIL_TYPES,
      installerAdd: null,
      collaboratorAdd: null,
      welderAdd: null,
      addProjectType: null,
      addCommentClicked: false,
      newComment: '',
      transformCustomerOption: 0,
      selectedSimilarCustomerId: null,
      transformDialog: false,
      transformDialogTitle: null,
      transformDialogBody: null,
      downloadDialog: false,
      downloadDialogTitle: null,
      libraryDialog: false,
      libraryDialogCategory: null,
      confirmDialog: false,
      confirmDialogTitle: null,
      confirmDialogBody: null,
      confirmAction: null,
      confirmYesNo: true,
      deleteCommentIndex: null,
      fileToDelete: null,
      singleSelectedPile: null,
      selectedPiles: [],
      isUploading: {
        pileLocation: false,
        sitePhoto: false,
        pilePhoto: false,
        permitPlan: false,
        otherDoc: false
      },
      isRenamingAFile: false,
      isGeneratingReport: false,
      similarCustomers: [],
      pageOrientation: 0,
      includePicture: false,
      includeAttachment: false,
      emailRules: [
        (v) => v == '' || /.+@.+\..+/.test(v) || this.$t("customer.email_valid"),
      ],
      PLATE_FORMS: PLATE_FORMS,
      photosDialog: false,
      selectedPhotoIndex: null,
      conformityAttestationModal: false,
      availableConformityReportDate: [],
      selectedDateForRequest: null,
      reportChangedModal: false,
      reportChangedModalBy: '',
      addTorqueReadingPileNumber: null,
      forceTorqueReadingPilesRecompute: 0,
      forceAvailablePileModelsRecompute: 0,
      forceValidMotorsRecompute: 0,
      availableInstallers: [],
      availableCollaborators: [],
      availableWelders: [],
      machines: [],
      motors: [],
      pileModels: [],
      helixModels: [],
      helixModelsWithEmpty: [],
      pilePlateDimensions: [],
      concreteBlockTypes: [],
      clickUpTaskInProgress: true,
      isLastValidationNearEnough: false,
      tproDialog: false,
      allowExcel: false,
      showMap: false,
      markerLatLng: null,
      map: null,
      marker: null,
      transformingCustomer: false,
      savingCustomer: false,
      savingContact: false,
      copyingPile: false,
      addingPile: false,
      addingProjectType: false,
      addingComment: false,
      addingReadingPileNumbers: [],
      addingDealerMachineMotor: false,
      selectingCustomer: false,
      newDocumentRequestType: null,
      changeProjectModal: false,
      otherProjects: null,
      otherProjectId: null,
      projectFiles: [],
      inputAddr: null, 
      centerMapTimer: null,
      mapAutoComplete: null,
      promiseAskConfirm: null,
      mapkey: Date.now(),
      isSavingPermitPlan: false,
      allPilesChecked: false,
      filesCategories: [
        {
          id: 'sitePhoto',
          relatableType: REPORT_FILE_TYPE.FIELD_PHOTOS,
          reportArray: 'fileSitePhotos',
          displayCondition: this.showSitePhotos,
          mandatory: this.sitePhotosMandatory,
          errored: 'errorSitePhoto',
          title: 'reportInfo.sitephoto',
          acceptFiles: 'image/png, image/jpeg, image/heic, image/webp, image/gif',
          imageCategory: true
        },
        {
          id: 'pileLocation',
          relatableType: REPORT_FILE_TYPE.PILE_LOCATION_PLAN,
          reportArray: 'filePileLocations',
          displayCondition: this.showPilesLocation,
          mandatory: this.pilesLocationMandatory,
          errored: 'errorPileLocation',
          title: 'reportInfo.pilelocation'
        },
        {
          id: 'permitPlan',
          relatableType: REPORT_FILE_TYPE.PERMIT_PLAN,
          reportArray: 'approvedPermitPlans',
          displayCondition: this.showPermitPlans,
          mandatory: this.permitPlansMandatory,
          errored: 'errorApprovedPermitPlan',
          title: 'reportInfo.approvedpermitplan'
        },
        {
          id: 'otherDoc',
          relatableType: REPORT_FILE_TYPE.OTHER_DOCUMENTS,
          reportArray: 'fileOtherDocs',
          displayCondition: () => true,
          mandatory: () => false,
          title: 'reportInfo.otherdoc'
        }
      ],
      viewPdf: { show: false, title: null, data: null }
    };
  },
  async mounted() {
    this.$socket.on('reportChanged', (data) => {
      // Show a modal that request the user to reload the report if another user modified this report
      if (this.selectedReportId == data.reportId && this.$socket.id != data.socketId) {
        this.reportChangedModalBy = data?.byFullName || 'N/A';
        this.reportChangedModal = true;
      }
    });

    this.$socket.on('projectChanged', (data) => {
      // Show a modal that request the user to reload the report if another user modified this report
      if (this.selectedReport.projectId == data.projectId && this.$socket.id != data.socketId) {
        this.reportChangedModalBy = data?.byFullName || 'N/A';
        this.reportChangedModal = true;
      }
    });
    
    await this.reloadPage();

    // À l'ouverture de la page, afficher un dialog si on a une demande en attente de création
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'newDocumentRequestType')) {
      
      let documentRequestType = parseInt(this.$route.query.newDocumentRequestType);

      // Effacer le flag du URL
      let query = JSON.parse(JSON.stringify(this.$route.query, null, 2));
      delete query.newDocumentRequestType;
      this.$router.replace({ query: query }).catch(()=>{});

      if (this.selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED &&
          ((documentRequestType == DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST && !this.selectedReport.requireConformityAttestation) ||
           (documentRequestType == DOCUMENT_REQUEST_TYPES.STAMPED_FIELD_REPORT && !this.selectedReport.requireStampedFieldReport) ||
           (documentRequestType == DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM && !this.selectedReport.requireBuildingInspectionForm) ||
           (documentRequestType == DOCUMENT_REQUEST_TYPES.PERMIT_REQUEST && !this.selectedReport.requirePermit) ||
           (documentRequestType == DOCUMENT_REQUEST_TYPES.T_PRO && !this.selectedReport.requireTpro))) {
        this.openRequestDateDialog(documentRequestType);
      }
    }
  },
  created() {
     //console.log('Report created')
  },
  updated() {
    //console.log('Report updated')
  },
  beforeDestroy() {
    //document.querySelectorAll(".pac-container").forEach(e => { console.log(e); e.remove(); });
  },
  computed: {
    ...mapGetters(['canManageCustomers','hasAccessToProjects']),
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      currentUser: state => state.currentUser,
      countries: (state) => state.countries,
      userFunctions: (state) => state.userFunctions,
      userRoles: (state) => state.userRoles
    }),
    fileCategoryPermitPlan() { return this.filesCategories[2]; },
    // General computed
    isSyncToProject() {
      return this.selectedReport &&
             this.selectedReport.project &&
             this.selectedReport.project.addressId == this.selectedReport.siteAddressId;
    },
    getMinSelectableDate() {
      return "1993-01-01";
    },
    getMaxSelectableDate() {
      return moment().endOf('year').add(2, 'years').format('YYYY-MM-DD');
    },
    getComputedFirstDayOfWeek() {
      return getFirstDayOfWeek();
    },
    isReturnToDraftDisabled() {
      return this.returnToDraftTooltip != null;
    },
    orderedPiles: {
      get() {
        return this.selectedReport.piles;
      },
      set(piles) {
        this.selectedReport.piles = piles
        this.reindexPiles(this.selectedReport.piles)
        this.saveChanges();
      },
    },
    availablePileModels() {
      this.forceAvailablePileModelsRecompute;
      let availablePileModels = [];
      for (let index in this.pileModels) {
        let pileModel = this.pileModels[index];

        // Le modèle de pieux est disponible s'il est spécifié dans le département d'ingénierie
        if (this.selectedReport.project.dealer.engineering_department) {
          let pileEngineeringDepartment = this.selectedReport.project.dealer.engineering_department.pile_model_engineering_departments.find(d => d.pileModelId == pileModel.id);
          if (pileEngineeringDepartment) {
            availablePileModels.push(pileModel); 
          }
        }
      }

      return availablePileModels;
    },
    availableTorqueReadingPileNumbers() {
      let availablePileNumbers = [];
      let prefix = this.$t('reportInfo.pile') + " #";

      for (let i = 1; i <= this.selectedReport.piles.length; i++) {
        let presentPile = this.selectedReport.site_torque_readings.find(str => str.pileNumber == i);
        if (!presentPile) {
          availablePileNumbers.push({id:i, name: prefix + i + " " + (this.selectedReport.piles[i-1].name??'')});
        }
      }
      return availablePileNumbers;
    },
    torqueReadingPiles() {
      this.forceTorqueReadingPilesRecompute;
      let piles = [];
      
      for (let index in this.selectedReport.site_torque_readings) {
        let reading = this.selectedReport.site_torque_readings[index];
        let pile = piles.find(p => p.pileNumber == reading.pileNumber);
        if (!pile){
          pile = { pileNumber: reading.pileNumber, isFalseRefusal: reading.isFalseRefusal, readings: [] }
          piles.push(pile);
        }
        pile.readings.push(reading);
      }
      sortByInteger(piles, 'pileNumber');

      for (let refIndex in this.$refs.torqueReadingRef) {
        let ref = this.$refs.torqueReadingRef[refIndex];
        ref.updateValues(this.isMetric());
      }
      
      return piles;
    },
    allPhotos() {
      let photos = [];
      if (this.selectedReport) {
        
        for (let index in this.selectedReport.fileSitePhotos) {
          let photo = this.selectedReport.fileSitePhotos[index];
          if (photo.url?.image) {
            photos.push(photo);
          }
        }
        for (let index in this.selectedReport.fileOtherDocs) {
          let photo = this.selectedReport.fileOtherDocs[index];
          if (photo.url?.image) {
            photos.push(photo);
          }
        }
        for (let index in this.selectedReport.filePileLocations) {
          let photo = this.selectedReport.filePileLocations[index];
          if (photo.url?.image) {
            photos.push(photo);
          }
        }
        for (let index in this.selectedReport.approvedPermitPlans) {
          let photo = this.selectedReport.approvedPermitPlans[index];
          if (photo.url?.image) {
            photos.push(photo);
          }
        }
      }
      return photos;
    },
    pileLengths() {
      let array = [ { id: null, value: '' } ];
      for (let lengthFt of this.selectedReport.PILE_LENGTHS_FT) {
        array.push({ id: lengthFt, value: this.selectedReport.project.dealer.globalMeasureUnit == UNIT_MEASURES.METRIC ? 
                                            convertFeetsToMeters(lengthFt) + 'm' :
                                            convertFeetsToFeetAndInches(lengthFt) });
      }
      return array;
    },
    validMotors() {
      this.forceValidMotorsRecompute;

      let motorValues = [];
      if (!this.selectedReport || !this.selectedReport.site_dealer_machine_motors) {
        return motorValues;
      }
      for (let siteDealerMachineMotor of this.selectedReport.site_dealer_machine_motors) {
        // Is it an OTHER machine ?
        if (siteDealerMachineMotor.id > 0) {
          // It's a real dealer machine, add it if both the machine and motor is selected
          if (siteDealerMachineMotor.dealerMachineId && siteDealerMachineMotor.dealerMachineMotorId) {
            motorValues.push(siteDealerMachineMotor);
          }
        } 
        else {
          // It's an OTHER machine, only add it to the valid list if its machine and motor is selected
          if (siteDealerMachineMotor.machineId > 0 && siteDealerMachineMotor.motorNumber) {
            let motor = this.motors.find(m => m.id == siteDealerMachineMotor.motorNumber);
            let motorNumber = siteDealerMachineMotor.motorNumber;
            if (motor) {
              motorNumber = motor.name;
            }
            let machineObj = this.machines.find(m => m.id == siteDealerMachineMotor.machineId);
            let machine = null;
            if (machineObj) {
              machine = machineObj.name;
            }
            motorValues.push({ id: siteDealerMachineMotor.id, machineName: siteDealerMachineMotor.machineName, serialNumber: siteDealerMachineMotor.serialNumber, motorNumber: motorNumber, machine: machine });
          }
        }
      }
      return motorValues;
    },
    isSiteReportViewOnly() {
      return this.userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION) == null;
    },
    isReadOnly() {
      let readonly = true;
      switch (this.selectedReport.statusEnum) {
        case SITE_REPORT_STATUS.DRAFT:
          readonly = false;
          break;
        case SITE_REPORT_STATUS.VALIDATION:
          readonly = this.userFunctions.find(f => f.id == FUNCTIONS.SITE_REPORT_VALIDATION) == null;
          break;
        case SITE_REPORT_STATUS.VALIDATED:
        case SITE_REPORT_STATUS.CANCELED:
        case SITE_REPORT_STATUS.DELETED:
          readonly = true;
          break;
      }

      // Check if the site report has to be readonly because of the View only function
      return readonly || this.isSiteReportViewOnly;
    },
    canDelete() {
      let canDelete = false;
      switch (this.selectedReport.statusEnum) {
        case SITE_REPORT_STATUS.DRAFT:
          canDelete = true;
          break;
        case SITE_REPORT_STATUS.VALIDATION:
          canDelete = this.userFunctions.find(f => f.id == FUNCTIONS.SITE_REPORT_VALIDATION) == null;
          break;
        case SITE_REPORT_STATUS.VALIDATED:
        case SITE_REPORT_STATUS.CANCELED:
        case SITE_REPORT_STATUS.DELETED:
          canDelete = false;
          break;
      }

      return canDelete && !this.isSiteReportViewOnly;
    },
    commentReadOnly() {
      let commentReadonly = false;
      switch (this.selectedReport.statusEnum) {
        case SITE_REPORT_STATUS.DRAFT:
        case SITE_REPORT_STATUS.VALIDATION:
        case SITE_REPORT_STATUS.VALIDATED:
          commentReadonly = false;
          break;
        case SITE_REPORT_STATUS.CANCELED:
        case SITE_REPORT_STATUS.DELETED:
          commentReadonly = true;
          break;
      }

      return commentReadonly || this.isSiteReportViewOnly;
    },
    isMapDisabled() {
      return this.isSiteReportViewOnly;
    },
    returnToDraftTooltip() {
      
      switch (this.selectedReport.statusEnum) {
        case SITE_REPORT_STATUS.VALIDATED:
        {
          // Si le rapport est validé, un admin put retourner un rapport en draft même s'il n'a pas les droits d'édition du rapport
          if (this.userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION) ||
              this.userFunctions.find(uf => uf.id == FUNCTIONS.MOVE_REPORTS_TO_DRAFT_INDEFINITELY)) {
            if (this.clickUpTaskInProgress) {
              return this.$t("reportInfo.valided_report_button_tooltip");
            }
            else if (!this.isLastValidationNearEnough && 
                    !this.userFunctions.find(uf => uf.id == FUNCTIONS.MOVE_REPORTS_TO_DRAFT_INDEFINITELY)) {
              return this.$t("reportInfo.valided_report_expired");
            }
          }
          else {
            return this.$t("reportInfo.insufficient_rights");
          }
          break;
        }
        case SITE_REPORT_STATUS.CANCELED:
        case SITE_REPORT_STATUS.DELETED:
        {
          // Pour retourner un rapport supprimé en draft, cela prend les droits d'édition
          if (!this.userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION)) {
            return this.$t("reportInfo.insufficient_rights");
          }
          break;
        }
        case SITE_REPORT_STATUS.VALIDATION:
        {
          // Pour retourner un rapport en validation en draft, il faut avoir l'un des rôles suivants
          if (!this.userRoles.find(ur => ur.id == ROLES.ADMIN ||
                                         ur.id == ROLES.ENGINEER_ADMIN || 
                                         ur.id == ROLES.ENGINEERING_DEPARTMENT_ADMIN ||
                                         ur.id == ROLES.DEALER ||
                                         ur.id == ROLES.ADVANCED_INSTALLER ||
                                         ur.id == ROLES.INSTALLER ||
                                         ur.id == ROLES.APPRENTICE)) {
            return this.$t("reportInfo.insufficient_rights");
          }
          break;
        }
      }
      
      return null;
    },
    pileNotInstalledReasons() {
      let array = [ { id: null, value: '' } ];
      let hasOther = false; // We add other at the end of the list, if it (still) exists
      for (let reasonEnum of Object.keys(PILE_NOT_INSTALLED_REASON)) {
        if (reasonEnum.toString().toUpperCase() != 'OTHER') array.push({ id: reasonEnum, value: this.$t("enums.PILE_NOT_INSTALLED_REASON." + reasonEnum) })
        else hasOther = reasonEnum;
      }
      if (hasOther != false) array.push({ id: hasOther, value: this.$t("enums.PILE_NOT_INSTALLED_REASON." + hasOther) })
      return array;
    },
    // -- End of general
    // ----------- Display/Show ---------------
    showProjectDimensions() {
      return (this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PROJECT_DIMENSIONS) ||
              this.selectedReport.projectTypes.find(p => p.projectDimensions))
    },
    showProjectTypeHeight() {
      return (this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PROJECT_HEIGHT) ||
              this.selectedReport.projectTypes.find(p => p.heightAboveGround_ft))
    },
    showProjectFreeStructure() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PROJECT_FREE_STRUCTURE);
    },
    showAnchoredBedrock() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.ANCHORED_BEDROCK) ||
             this.selectedReport.piles.some(p => p.isAnchoredToBedrock > 0);
    },
    showSafetyFactor() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.SAFETY_FACTOR);
    },
    showDocuments() {
      let ed = this.selectedReport.project.dealer.engineering_department;
      if (ed) {
        return ed.stampedFieldReportInfo ||
               ed.conformityReportInfo ||
               ed.buildingInspectionFormInfo ||
               ed.tproInfo;
      }
      return false;
    },
    showContacts() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.CONTACTS) ||
             this.selectedReport.contactFirstname ||
             this.selectedReport.contactLastname ||
             this.selectedReport.contactPhoneMobile ||
             this.selectedReport.contactEmail;
    },
    showMachines() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.MACHINES);
    },
    showPileLength() {
      // On affiche les longueurs de pieux si le concessionnaire le veut ou s'il contient déjà des valeurs
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILE_LENGTH) ||
             this.selectedReport.piles.find(p => p.pileLength_ft > 0);
    },
    showDealerDropdown() {
      // On cache la sélection d'un concessionnaire si 
      // - Un seul concessionnaire disponible
      // - Seulement pour les Dealer/Installer/Advanced Certified Installer
      if (this.selectedReport && this.selectedReport.dealers.length == 1 && this.userRoles != null) {
        let anyOtherRole = this.userRoles.find(ur => ur.id != ROLES.DEALER && 
                                                     ur.id != ROLES.INSTALLER && 
                                                     ur.id != ROLES.ADVANCED_INSTALLER &&
                                                     ur.id != ROLES.APPRENTICE);
        return anyOtherRole != undefined;
      }

      return true;
    },
    showProjectTypeDetails() {
      return this.showProjectFreeStructure || this.showProjectTypeHeight || this.showProjectDimensions;
    },
    showWelders() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.WELDERS) || this.selectedReport.welders.length > 0;
    },
    showInfoWelding() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INFO_WELDING) || this.selectedReport.isWeldingDone;
    },
    showInfoSelfTappingScrew() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INFO_SELF_TAPPING_SCREW) || this.selectedReport.isSelfTappingScrew;
    },
    showInfoStickers() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INFO_STICKERS) || this.selectedReport.areStickersPosed;
    },
    showInfoGreenSleeve() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INFO_GREEN_SLEEVE) || this.selectedReport.isGreenSleeve;
    },
    showInfoPileAngleConform() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INFO_PILE_ANGLE_CONFORM) || this.selectedReport.isPileAngleConform;
    },
    showPermit() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PERMIT);
    },
    showInfoPilePositionConform() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INFO_PILE_POSITION_CONFORM) || this.selectedReport.isPilePositionConform;
    },
    showCollaborators() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.COLLABORATORS) || this.selectedReport.collaborators.length > 0;
    },
    showBedrockDropdown() {
      if (this.selectedReport.project.dealer.engineering_department &&
          this.selectedReport.project.dealer.engineering_department.bedrockDropdown) {
        return true;
      }
      return false;
    },
    showPlateTypeColumn() {

      // Pour rétrocompatibilité
      if (this.selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED) {
        let anyPileWithPlateType = this.selectedReport.piles.find(p => p.plateTypeEnum != null || p.isCustomPlate);
        if (anyPileWithPlateType) {
          return true;
        }
      }
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PLATE_TYPE);
    },
    showConcreteSlab() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PROJECT_CONCRETE_SLAB);
    },
    showBrick() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PROJECT_BRICK);
    },
    showSpa() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PROJECT_SPA);
    },
    showInstallers() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INSTALLERS) || this.selectedReport.installers.length > 0;
    },
    showInstallationDates() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INSTALLATION_DATES);
    },
    showRequests() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.REQUESTS);
    },
    showPiles() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILES);
    },
    showSoilType() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.SOIL_TYPE) || (this.soilTypeSelected(this.selectedReport));
    },
    showTorqueReadings() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.TORQUE_READINGS) || this.selectedReport.site_torque_readings.length > 0;
    },
    showImportantInformation() {
      return this.selectedReport && 
             (this.showInfoPilePositionConform ||
              this.showInfoPileAngleConform ||
              this.showInfoGreenSleeve ||
              this.showInfoStickers ||
              this.showInfoSelfTappingScrew ||
              this.showInfoWelding);
    },
    showFourthColumn() {
      return this.selectedReport &&
             (this.showInstallationDates ||
              this.showWelders ||
              this.showCollaborators ||
              this.showInstallers);
    },
    showNotInstalled() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILE_NOT_INSTALLED) ||
             this.selectedReport.piles.some(p => p.isNotInstalled);
    },
    showPileInstalledColumns() {
      return this.selectedReport.piles.some(p => !p.isNotInstalled);
    },
    showPileNotInstalledColumns() {
      return this.selectedReport.piles.some(p => p.isNotInstalled);
    },
    showPileFinish() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILE_FINISH) ||
             this.selectedReport.piles.find(p => p.surfaceFinishEnum !== null);
    },
    showInvoiceLength() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.INVOICE_LENGTH) ||
             this.selectedReport.piles.find(p => p.invoicedLength_ft > 0);
    },
    showTorque() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.TORQUE);
    },
    showCompression() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.COMPRESSION);
    },
    showTension() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.TENSION);
    },
    showExtension() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.EXTENSION) ||
             this.selectedReport.piles.find(p => p.extensionLength_ft > 0);
    },
    showCustomerCategory() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.CUSTOMER_CATEGORY) ||
             this.selectedReport.customerCategory;
    },
    showPileName() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILE_NAME) ||
             this.selectedReport.piles.find(p => p.name);
    },
    showCustomerTelephone() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.CUSTOMER_PHONE) ||
             this.selectedReport.customerPhoneMobile;
    },
    showCustomerEmail() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.CUSTOMER_EMAIL) ||
             this.selectedReport.customerEmailMain;
    },
    showHammer() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.HAMMER) ||
             (this.selectedReport.piles.find(p => p.hammerEnum  !== null) && this.selectedReport.piles.find(p => p.impact1_ft));
    },
    showProjectReferenceNumber() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PROJECT_REFERENCE_NUMBER) ||
             this.selectedReport.project.referenceNumber;
    },
    showComments() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.COMMENTS) || this.selectedReport.comments.length > 0;
    },
    showPilePlateDimension() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILE_PLATE_DIMENSION);
    },
    showPileConcreteBlock() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILE_CONCRETE_BLOCK) || this.selectedReport.piles.find(p => p.concreteBlockTypeId > 0);
    },
    showPileHeightAboveGround() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILE_HEIGHT_ABV_GROUND);
    },
    showPileImpacts() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PILE_IMPACTS) ||
             this.selectedReport.piles.find(p => p.impact1_ft);
    },
    showProjectTypes() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.PROJECT_TYPES);
    },
    // -- End of Display/Show
    // ----------- Mandatory ---------------
    safetyFactorMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.SAFETY_FACTOR);
    },
    projectTypeHeightMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PROJECT_HEIGHT);
    },
    projectDimensionsMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PROJECT_DIMENSIONS);
    },
    projectFreeStructureMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PROJECT_FREE_STRUCTURE);
    },
    contactsMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.CONTACTS);
    },
    machinesMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.MACHINES);
    },
    weldersMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.WELDERS, this.selectedReport.isWeldingDone);
    },
    infoWeldingMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.INFO_WELDING);
    },
    infoSelfTappingScrewMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.INFO_SELF_TAPPING_SCREW);
    },
    infoStickersMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.INFO_STICKERS);
    },
    infoGreenSleeveMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.INFO_GREEN_SLEEVE);
    },
    infoPileAngleConformMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.INFO_PILE_ANGLE_CONFORM);
    },
    infoPilePositionConformMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.INFO_PILE_POSITION_CONFORM);
    },
    collaboratorsMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.COLLABORATORS);
    },
    plateTypeMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PLATE_TYPE);
    },
    installersMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.INSTALLERS);
    },
    installationDatesMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.INSTALLATION_DATES);
    },
    pilesMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PILES);
    },
    soilTypeMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.SOIL_TYPE);
    },
    pileFinishMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PILE_FINISH);
    },
    pilePlateDimensionMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PILE_PLATE_DIMENSION);
    },
    pileConcreteBlockMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PILE_CONCRETE_BLOCK);
    },
    pileHeightAboveGroundMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PILE_HEIGHT_ABV_GROUND);
    },
    pileImpactsMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PILE_IMPACTS);
    },
    projectsMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PROJECT_TYPES);
    }
    // -- End of Mandatory
  }, // -- End of computed
  methods: {
    log: console.warn,
    imgLoadingDone(img) {
      document.querySelector(`#loading-${img.id}`).remove();
    },
    ...mapMutations([
      'openToast',
      'showErrorMessage'
    ]),
    ...mapActions([ 
      'setBreadcrumbs',
    ]),
    soilTypeSelected: (report) => report.site_reports_soil_types != null && report.site_reports_soil_types.length > 0,
    onShowMap() {
      this.showMap = true;
      this.mapkey = Date.now();
    },
    async reloadPage() {
      await this.setSelectedReport(this.$route.params.id);
    },
    async setSelectedReport(reportId) {

      //console.log('setSelectedReport. Selected: ' + this.selectedReportId + ', Setting: ' + reportId);

      this.resetReport();
      this.setSelectedReportId(reportId);
      await this.retrieveReportData();
    },
    async retrieveReportData() {
      //console.log("Retrieving report data...");
      try {
        let response = await UserService.getReportData(this.selectedReportId, getLanguage());

        let breadCrumbs = [];
        if (this.hasAccessToProjects) {
          breadCrumbs.push({text: this.$t('projects.projects'), href: '/projects'});
          breadCrumbs.push({text: this.$t('projects.project') + ' # ' + response.data.report.projectId, href: '/project/' + response.data.report.projectId});
        }
        else {
          breadCrumbs.push({text: this.$t('documentRequests.dr_fieldreports'), href: '/reports'});
        }
        breadCrumbs.push({text: this.$t(REQUEST_TYPES_TEXT[response.data.report.requestType]) + " # " + this.$route.params.id});
        this.setBreadcrumbs(breadCrumbs);

        this.pilePlateDimensions = response.data.pilePlateDimensions;
        this.concreteBlockTypes = response.data.concreteBlockTypes;
        this.machines = response.data.machines;
        this.motors = response.data.motors;
        this.pileModels = response.data.pileModels;
        this.helixModels = response.data.helixModels;
        this.allowExcel = response.data.allowExcel;
        this.clickUpTaskInProgress = response.data.report.clickUpTaskInProgress;
        this.isLastValidationNearEnough = response.data.report.isLastValidationNearEnough;

        // Il est permis de spécifier des dimensions "Autre"
        this.pilePlateDimensions.splice(0, 0, {
          id: -1,
          isOther: true,
          name: "",
        });

        // Il est permis de spécifier des types de plot de béton "Autre" et également d'effacer l'entrée
        this.concreteBlockTypes.splice(0, 0, {
          id: -1,
          name: "",
        });
        this.concreteBlockTypes.splice(0, 0, {
          id: null,
          name: "",
        });

        this.helixModelsWithEmpty = JSON.parse(JSON.stringify(this.helixModels, null, 2));
        this.helixModelsWithEmpty.splice(0, 0, { id: -1, name: '' });
        
        this.updateSelectedReport(response.data.report);

        this.updateAvailableInstallers();
        this.updateAvailableCollaborators();
        this.updateAvailableWelders();
        this.updateAvailablePilePlatesDimensions();
        
        if (!this.selectedReport.project.customerId) {
          this.setSelectedReportCustomerId(-1);
        }
      }
      catch (error) {
        // handle error
        console.log(error)
        let errorMsg = parseError(error);
        console.log(errorMsg);
        this.showErrorMessage(errorMsg);

        if (error.toString().includes("401")) {
          this.$store.dispatch("logout", this.$socket);
        }
        else if (
          this.$router.currentRoute.path.includes("/report/") &&
          this.$router.currentRoute.path.length > "/report/".length
        ) {
          //console.log("Redirecting to reports from store...");
          this.$router.push("/reports/");
        }
        else {
          this.$router.push("/");
        }
      }
    },
    async updateSelectedReport(report) {
      this.selectedReport = report;

      if (!report) {
        return;
      }

      this.formatSelectedReportDealer();
      this.formatContacts();

      this.resetReportChanges(this.selectedReport);
      this.resetReportErrors(this.selectedReport);

      this.formatReport();
      this.formatReportMachines();

      this.setDefaultMachineIfNeeded(true);

      if (this.selectedReport.isWeldingDone) {
        this.selectedReport.isWeldingDoneTrue = true;
      } else if (this.selectedReport.isWeldingDone == false) {
        this.selectedReport.isWeldingDoneFalse = true;
      }

      if (this.selectedReport.isGreenSleeve) {
        this.selectedReport.isGreenSleeveTrue = true;
      } else if (this.selectedReport.isGreenSleeve == false) {
        this.selectedReport.isGreenSleeveFalse = true;
      }

      this.displayErrors = false;

      // Select first test reading profile pile
      sortByProperties(this.selectedReport.site_torque_readings, ['pileNumber', 'depth_ft'])
      if (this.selectedReport.site_torque_readings && this.selectedReport.site_torque_readings.length > 0) {
        this.selectedReport.selectedTorqueReadingPileNumber = this.selectedReport.site_torque_readings[0].pileNumber;
      }

      //console.log('this.selectedReport', this.selectedReport);
    },
    getFormattedSiteDealerMachineMotor(report, smm) {
      let dealerMachine = null;
      let motor = null;
      ({ dealerMachine, motor } = this.findDealerMachineAndMotorFromDealerMachineMotorId(report.project.dealer, smm.dealerMachineMotorId));
      let machine = null;
      if (dealerMachine) {
        machine = this.machines.find(m => m.id == dealerMachine.machineId); 
      }

      let formattedSiteDealerMachineMotor = { 
        id: smm.id, 
        siteReportId: smm.siteReportId, 
        dealerMachineMotorId: smm.dealerMachineMotorId, 
        dealerMachineId: dealerMachine ? dealerMachine.id : null, 
        machine: machine,
        motorId: motor ? motor.id : null, 
        machineName: smm.machineName, 
        serialNumber: smm.serialNumber,
        motorNumber: smm.motorName
      };

      return formattedSiteDealerMachineMotor;
    },
    formatReportMachines() {
      let report = this.selectedReport;

      // Format the report site_dealer_machine_motors as a flat object, easier to work with, specially for the OTHER option
      let site_dealer_machine_motors = [];
      if (report.site_dealer_machine_motors) {
        report.site_dealer_machine_motors.forEach(smm => {
          let formattedSiteDealerMachineMotor = this.getFormattedSiteDealerMachineMotor(report, smm);
          site_dealer_machine_motors.push(formattedSiteDealerMachineMotor);
        })
    
        delete report.site_dealer_machine_motors;
      }
      report.site_dealer_machine_motors = site_dealer_machine_motors;

      if (report.tempDealerMachineMotor) {
        report.tempDealerMachineMotor.forEach((machineMotor) => {
          machineMotor.isOther = true;
          machineMotor.dealerMachineId = -1;
          report.site_dealer_machine_motors.push(machineMotor);
        });
      }
    },
    setDefaultMachineIfNeeded(save) {
      let requireSave = false;

      // If the report has no machine or exactly one machine/motor with no selection, select the user preferred machine
      // On réalise cette assignation au niveau du frontend puisqu'un usager a souvent une machine par défaut,
      // mais la machine n'a pas forcément un moteur par défaut, ce qui fait que ce n'est pas un MachineMotor complet.
      let dealer = this.selectedReport.project.dealer;
      if (!this.selectedReport.site_dealer_machine_motors) {
        return;
      }
      
      // Il est possible que la machine préférée de l'usager ne soit pas pour ce concessionnaire
      let userPreferredDealerMachine = dealer.dealer_machines.find(dm => dm.id == this.currentUser.dealerMachineId);
      if (!userPreferredDealerMachine) {
        return;
      }
      
      // Si aucune machine, on l'ajoute
      if (this.selectedReport.site_dealer_machine_motors.length == 0) {
        //console.log('Report doesn\'t have any machine, adding the user preferred machine')

        let newDealerMachineMotor = {
          id: this.getLowestDealerMachineMotorId() - 1,
          dealerMachineId: userPreferredDealerMachine.id,
          machineName: userPreferredDealerMachine.name,
          serialNumber: userPreferredDealerMachine.serialNumber
        };

        // Si la machine a un moteur par défaut, on l'assigne
        if (userPreferredDealerMachine.defaultMachineMotorId) {
          newDealerMachineMotor.dealerMachineMotorId = userPreferredDealerMachine.defaultMachineMotorId;
          newDealerMachineMotor = this.getFormattedSiteDealerMachineMotor(this.selectedReport, newDealerMachineMotor);
        }
        
        // On sauvegarde le nouveau SiteMachineMotor dans la BD
        let changedDealerMachineMotor = JSON.parse(JSON.stringify(newDealerMachineMotor));
        this.selectedReport.changes.addedOrModifiedDealerMachineMotors.push(changedDealerMachineMotor);

        requireSave = true;
      }
      // Si une seule machine vide (souvent un nouveau rapport), on y assigne le moteur préféré de l'usager
      else if (this.selectedReport.site_dealer_machine_motors.length == 1 &&
               !this.selectedReport.site_dealer_machine_motors[0].dealerMachineId) {
        
        //console.log('Report doesn\'t have a selected machine, selecting the user preferred machine')
        let reportMachineMotor = this.selectedReport.site_dealer_machine_motors[0];
        reportMachineMotor.dealerMachineId = userPreferredDealerMachine.id;
        reportMachineMotor.machineName = userPreferredDealerMachine.name;
        reportMachineMotor.serialNumber = userPreferredDealerMachine.serialNumber;

        // Si la machine a un moteur par défaut, on l'assigne et on sauvegarde le changement puisqu'on a un MachineMotor complet
        if (userPreferredDealerMachine.defaultMachineMotorId) {
          reportMachineMotor.dealerMachineMotorId = userPreferredDealerMachine.defaultMachineMotorId;
          let formattedMachineMotor = this.getFormattedSiteDealerMachineMotor(this.selectedReport, reportMachineMotor);
          reportMachineMotor.motorId = formattedMachineMotor.motorId;
          reportMachineMotor.motorNumber = formattedMachineMotor.motorNumber;
          reportMachineMotor.machine = formattedMachineMotor.machine;

          let dealerMachineMotorChange = this.getChangedDealerMachineMotor(reportMachineMotor.id);
          dealerMachineMotorChange.dealerMachineMotorId = reportMachineMotor.dealerMachineMotorId;
          this.forceValidMotorsRecompute++;

          requireSave = true;
        }
      }

      if (save && requireSave) {
        this.saveChanges();
      }
    },
    findDealerMachineAndMotorFromDealerMachineMotorId(dealer, dealerMachineMotorId) {
      if (!dealer) { return { dealerMachine: null, motor: null } }

      for (let dealerMachine of dealer.dealer_machines) {
        if (dealerMachine.dealer_machine_motors) {
          for (let dealerMachineMotor of dealerMachine.dealer_machine_motors) {
            if (dealerMachineMotor.id == dealerMachineMotorId) {
              return { dealerMachine, motor: dealerMachineMotor.motor };
            }
          }
        }
      }
      return { dealerMachine: null, motor: null };
    },
    saveReportChanges() {
      var state = this;
      return new Promise((resolve, reject) => {

        let changes = this.selectedReport.changes;
        this.cleanupReportChanges(changes);
        //console.log('saving selectedReport.changes', changes);

        this.resetReportChanges(this.selectedReport);

        if (Object.keys(changes).length === 0) {
          //console.log('No changes. Skipping saveReportCahnges.')
          return;
        }

        ReportService.saveReportChanges(
            this.selectedReport.id, 
            changes, 
            this.selectedReport.locale, 
            getLanguage()
          ).then(async response => {

          if (response.status == 200) {
            //Check if there's prevented changes due to authorization
            if (response.data.message && response.data.message.length > 0) {
              let errorMsg = parseError(response.data.message);
              state.showErrorMessage(errorMsg);
            }
            
            if (this.selectedReport) {
              // Une mise à jour du client est reçue (à la suite d'un changement de client)
              if (response.data.customer) {
                Object.assign(this.selectedReport, response.data.customer);
                this.$refs.customerAddressRef.updateAddress(this.selectedReport.customerAddress);
              }

              if (Object.prototype.hasOwnProperty.call(response.data, 'contactId') && response.data.contactId == null) {
                this.selectedReport.contact = null;
                this.selectedReport.contactId = -1;
              }

              if (response.data.addedPiles) {

                this.copyingPile = false;
                this.addingPile = false;

                for (let newPile of response.data.addedPiles) {
                  this.formatPile(this.selectedReport, newPile);
                  this.formatPileStandardSupportingPlate(newPile);
                  this.selectedReport.piles.push(newPile);
                }
              }

              if (response.data.updatedPiles) {

                for (let updatedPile of response.data.updatedPiles) {
                  let pileToUpdate = this.selectedReport.piles.find(p => p.id == updatedPile.id);
                  Object.assign(pileToUpdate, updatedPile);

                  if (updatedPile.maxPressureReset) {
                    this.openToast({ message: this.$t("reportInfo.exceed_max_pressure", { maxPressure: updatedPile.maxPressureReset }), duration: 15000, color: 'red' });
                  }
                  if (updatedPile.minPressureReset) {
                    this.openToast({ message: this.$t("reportInfo.inferior_min_pressure", { minPressure: updatedPile.minPressureReset }), duration: 15000, color: 'red' });
                  }

                  if (updatedPile.readings) {
                    for (let reading of updatedPile.readings) {
                      let torqueReading = this.selectedReport.site_torque_readings.find(r => r.id == reading.id);
                      torqueReading.pressure_psi = reading.pressure_psi;

                      if (reading.maxPressureReset) {
                        this.openToast({ message: this.$t("reportInfo.exceed_max_pressure", { maxPressure: reading.maxPressureReset }), duration: 15000, color: 'red' });
                      }
                      if (reading.minPressureReset) {
                        this.openToast({ message: this.$t("reportInfo.inferior_min_pressure", { minPressure: reading.minPressureReset }), duration: 15000, color: 'red' });
                      }
                    }
                  }
                }
              }
              
              if (response.data.addedProjectTypes) {

                for (let projectType of response.data.addedProjectTypes) {
                  
                  let dealerProjectType = this.selectedReport.project.dealer.projectTypes.find(pt => pt.id == projectType.projectTypeId);
                  projectType.displayName = dealerProjectType ? dealerProjectType.name : '';
             
                  this.selectedReport.projectTypes.push(projectType);
                }
              }

              if (response.data.addedComments) {

                this.addingComment = false;

                for (let comment of response.data.addedComments) {
                  comment.user = this.currentUser;
                  comment.updatedAt = moment(comment.updatedAt).format('YYYY/MM/DD HH:mm:ss');
                  this.formatComment(comment)
                  this.selectedReport.comments.push(comment);
                }
              }

              if (response.data.addedTorqueReadings) {

                for (let reading of response.data.addedTorqueReadings) {

                  let index = this.addingReadingPileNumbers.indexOf(reading.pileNumber);
                  if (index >= 0) {
                    this.addingReadingPileNumbers.splice(index, 1);
                  }

                  this.selectedReport.site_torque_readings.push(reading);
                }
              }

              if (response.data.addedDealerMachineMotors) {

                this.addingDealerMachineMotor = false;

                for (let dealerMachineMotor of response.data.addedDealerMachineMotors) {
                  this.selectedReport.site_dealer_machine_motors.push(dealerMachineMotor);
                }

                // On a reçu des nouveaux ids de siteMachineMotor, on s'assure de lier les pieux correctement
                this.forceValidMotorsRecompute++;
              }

              if (response.data.officialCustomer) {

                this.savingCustomer = false;
                this.transformingCustomer = false;

                let customer = response.data.officialCustomer.customer;
                customer.contacts = [];

                this.selectedReport.project.dealer.customers.push(customer);
                this.selectedReport.project.customer = customer;
                this.selectedReport.project.customerId = customer.id;

                updateCustomerFormattedName(this.selectedReport.project.customer);
                this.formatContacts();

                this.openToast({ message: this.$t("reportInfo.client_saved"), duration: 10000, color: 'green'});
              }

              if (response.data.updateContact) {
                this.selectedReport.contactFirstname = response.data.updateContact.contactFirstname;
                this.selectedReport.contactLastname = response.data.updateContact.contactLastname;
                this.selectedReport.contactPhoneMobile = response.data.updateContact.contactPhoneMobile;
                this.selectedReport.contactEmail = response.data.updateContact.contactEmail;
              }

              if (response.data.newContact) {
                this.savingContact = false;

                let contact = response.data.newContact;
                this.selectedReport.contact = contact;
                this.selectedReport.contactId = contact.id;

                this.formatContact(contact);
                
                let contacts = this.selectedReport.project.customer.contacts;
                contacts.push(contact);
                // Patch nécessaire pour forcer la mise à jour de l'affichage de la liste dans le v-autocomplete
                this.selectedReport.project.customer.contacts = [];
                await this.$nextTick();
                this.selectedReport.project.customer.contacts = contacts;

                this.openToast({ message: this.$t("reportInfo.contact_saved"), duration: 10000, color: 'green'});
              }

              if (Object.prototype.hasOwnProperty.call(response.data, 'projectAtSameLocationId')) {
                this.otherProjectId = response.data.projectAtSameLocationId;
                this.confirmDialog = true;
                this.confirmDialogTitle = this.$t('reportInfo.existing_project_detected_title');
                this.confirmDialogBody = this.$t('reportInfo.existing_project_detected_subtitle');
                this.confirmAction = ConfirmAction.ChangeProject;
                this.confirmYesNo = true;
              }

              // GPS
              if (Object.prototype.hasOwnProperty.call(response.data, 'gps')) {
                this.selectedReport.siteAddressLatitude = response.data.gps.siteAddressLatitude;
                this.selectedReport.siteAddressLongitude = response.data.gps.siteAddressLongitude;
                this.mapkey = Date.now();

                // Nouvelles valeurs reçues de l'adresse du site, mettre à jour le bon affichage (Customer ou Site)
                if (Object.prototype.hasOwnProperty.call(response.data.gps, 'address1')) {
                  if (this.selectedReport.isCustomerAddress) {
                    this.selectedReport.customerAddress.address1 = response.data.gps.address1;
                  }
                  else {
                    this.selectedReport.siteAddress.address1 = response.data.gps.address1;
                  }
                }
                if (Object.prototype.hasOwnProperty.call(response.data.gps, 'address2')) {
                  if (this.selectedReport.isCustomerAddress) {
                    this.selectedReport.customerAddress.address2 = response.data.gps.address2;
                  }
                  else {
                    this.selectedReport.siteAddress.address2 = response.data.gps.address2;
                  }
                }
                if (Object.prototype.hasOwnProperty.call(response.data.gps, 'city')) {
                  if (this.selectedReport.isCustomerAddress) {
                    this.selectedReport.customerAddress.city = response.data.gps.city;
                  }
                  else {
                    this.selectedReport.siteAddress.city = response.data.gps.city;
                  }
                }
                if (Object.prototype.hasOwnProperty.call(response.data.gps, 'postalCode')) {
                  if (this.selectedReport.isCustomerAddress) {
                    this.selectedReport.customerAddress.postalCode = response.data.gps.postalCode;
                  }
                  else {
                    this.selectedReport.siteAddress.postalCode = response.data.gps.postalCode;
                  }
                }
                if (Object.prototype.hasOwnProperty.call(response.data.gps, 'countryId')) {
                  if (this.selectedReport.isCustomerAddress) {
                    this.selectedReport.customerAddress.countryId = response.data.gps.countryId;
                  }
                  else {
                    this.selectedReport.siteAddress.countryId = response.data.gps.countryId;
                  }
                }
                if (Object.prototype.hasOwnProperty.call(response.data.gps, 'stateId')) {
                  if (this.selectedReport.isCustomerAddress) {
                    this.selectedReport.customerAddress.stateId = response.data.gps.stateId;
                  }
                  else {
                    this.selectedReport.siteAddress.stateId = response.data.gps.stateId;
                  }
                }
                this.$refs.customerAddressRef.updateAddress(this.selectedReport.customerAddress);
                this.$refs.siteAddressRef.updateAddress(this.selectedReport.siteAddress);
              }

              if (response.data.addedWelders) {
                for (let w of (response.data.addedWelders || [])) {
                  let exists = this.selectedReport.welders?.find(e => e.id == w.id);
                  w.check = true; //to force updateAvailableWelders to validate the expiration
                  if (!exists) {
                    this.selectedReport.welders.push(w);
                  }
                  else {
                    for (let k in w) {
                      if (exists[k] == null) exists[k] = w[k];
                    }
                  }
                }
                this.updateAvailableWelders();
              }
            }
            else {
              console.log('After save report changes, report was null. Skipping data updates on the selected report.')
            }

            resolve(response.data)
          }
          else{
            let errorMsg = parseError(response);
            state.showErrorMessage(errorMsg);
            reject()
          }
        }).catch((err) => {
          // On reset tous les "loadings" states
          state.transformingCustomer = false;
          state.savingCustomer = false;
          state.savingContact = false;
          state.copyingPile = false;
          state.addingPile = false;
          state.addingProjectType = false;
          state.addingComment = false;
          state.addingReadingPileNumbers = [];
          state.addingDealerMachineMotor = false;
          
          let errorMsg = parseError(err);
          state.showErrorMessage(errorMsg);
          reject(err)
        })
      })
    },
    cleanupReportChanges(changes) {

      if (changes.customers.length == 0) {
        delete changes.customers;
      }
      if (changes.addedInstallers.length == 0) {
        delete changes.addedInstallers;
      }
      if (changes.deletedInstallers.length == 0) {
        delete changes.deletedInstallers;
      }
      if (changes.addedCollaborators.length == 0) {
        delete changes.addedCollaborators;
      }
      if (changes.deletedCollaborators.length == 0) {
        delete changes.deletedCollaborators;
      }
      if (changes.addedWelders.length == 0) {
        delete changes.addedWelders;
      }
      if (changes.deletedWelders.length == 0) {
        delete changes.deletedWelders;
      }
      if (changes.addedProjectTypes.length == 0) {
        delete changes.addedProjectTypes;
      }
      if (changes.deletedProjectTypes.length == 0) {
        delete changes.deletedProjectTypes;
      }
      if (changes.addedOrModifiedPiles.length == 0) {
        delete changes.addedOrModifiedPiles;
      }
      if (changes.deletedPiles.length == 0) {
        delete changes.deletedPiles;
      }
      if (Object.keys(changes.siteAddress).length == 0) {
        delete changes.siteAddress;
      }
      if (Object.keys(changes.customer).length == 0) {
        delete changes.customer;
      }
      if (changes.addedOrModifiedComments.length == 0) {
        delete changes.addedOrModifiedComments;
      }
      if (changes.deletedComments.length == 0) {
        delete changes.deletedComments;
      }
      if (changes.addedOrModifiedTorqueReadings.length == 0) {
        delete changes.addedOrModifiedTorqueReadings;
      }
      if (changes.deletedTorqueReadings.length == 0) {
        delete changes.deletedTorqueReadings;
      }
      if (changes.addedOrModifiedDealerMachineMotors.length == 0) {
        delete changes.addedOrModifiedDealerMachineMotors;
      }
      if (changes.deletedDealerMachineMotors.length == 0) {
        delete changes.deletedDealerMachineMotors;
      }
    },
    resetReportChanges(report) {
      report.changes = {
        customers: [],
        addedInstallers: [],
        deletedInstallers: [],
        addedCollaborators: [],
        deletedCollaborators: [],
        addedWelders: [],
        deletedWelders: [],
        addedProjectTypes: [],
        deletedProjectTypes: [],
        addedOrModifiedPiles: [],
        deletedPiles: [],
        addedOrModifiedComments: [],
        deletedComments: [],
        addedOrModifiedTorqueReadings: [],
        deletedTorqueReadings: [],
        addedOrModifiedDealerMachineMotors: [],
        deletedDealerMachineMotors: [],
        customer: {},
        siteAddress: {}
      };
    },
    formatSelectedReportDealer() {

      let dealer = this.selectedReport.project.dealer;

      for (let c of dealer.customers) {
        updateCustomerFormattedName(c);
        c.formattedAddress = getLongFormattedAddress(this.countries, c.address);
      }
      sortByProperty(dealer.customers, 'formattedName')

      formatDealerSupportingPlates(dealer);

      dealer.customers.splice(0, 0, {
        id: -1,
        isOther: true,
        firstname: "",
        lastname: "",
        address: {
          id: -1,
          address1: "",
          address2: "",
          city: "",
          countryId: null,
          stateId: null,
          poBox: "",
          postalCode: "",
        },
      });
      // Set the formatted name for the OTHER option
      updateCustomerFormattedName(dealer.customers[0]);

      dealer.dealer_machines.splice(0, 0, {
        id: -1,
        isOther: true,
        machineName: "",
        serialNumber: "",
        motorNumber: "",
      });
      
      dealer.welders.splice(0, 0, {
        id: -1,
        isOther: true,
        isInstaller: false,
        isWelder: true,
        firstname: "",
        lastname: "",
        welderExpirationDateCwb: null,
      });

      // Il est permis de spécifier un type de projet "Autre"
      dealer.projectTypes.splice(0, 0, {
        id: -1,
        isOther: true,
        name: "",
      });

      if (!this.selectedReport.project.customerId) {
        this.selectedReport.project.customerId = -1;
      }

      // Set pile default helix list
      this.selectedReport.piles.forEach((p) => {
        if (!p.pile_helixes) {
          p.pile_helixes = [];
        }
      });
    },
    formatContact(contact) {
      contact.formattedName = '';
      if (contact.firstname) {
        contact.formattedName += contact.firstname;
      }
      if (contact.lastname) {
        if (contact.formattedName.length > 0) {
          contact.formattedName += ' ';
        }
        contact.formattedName += contact.lastname;
      }
    },
    formatContacts() {
      if (this.selectedReport && this.selectedReport.project.customer) {
        for (let contact of this.selectedReport.project.customer.contacts) {
          this.formatContact(contact);
        }
        sortByProperty(this.selectedReport.project.customer.contacts, 'formattedName')

        this.selectedReport.project.customer.contacts.splice(0, 0, {
          id: -1,
          firstname: "",
          lastname: "",
          phoneMobile: "",
          email: "",
          formattedName: this.$t('reportInfo.other')
        });

        // Si le rapport n'a pas de contact permanent, on sélectionne l'option AUTRE
        if (this.selectedReport.contactId == null) {
          this.selectedReport.contactId = -1;
        }
      }
    },
    updateAvailableInstallers() {
      this.availableInstallers = [];
      if (!this.selectedReport || !this.selectedReport.project || !this.selectedReport.project.dealer) {
        return;
      }
      this.selectedReport.project.dealer.installers.forEach((installer) => {
        let reportInstaller = this.selectedReport.installers.find(
          (i) => i.id == installer.id
        );
        if (!reportInstaller) {
          this.availableInstallers.push(installer);
        }
      });
    },
    updateAvailableCollaborators() {
      this.availableCollaborators = [];
      if (!this.selectedReport || !this.selectedReport.project || !this.selectedReport.project.dealer) {
        return;
      }
      this.selectedReport.project.dealer.collaborators.forEach((collaborator) => {
        let reportCollaborator = this.selectedReport.collaborators.find(
          (i) => i.id == collaborator.id
        );
        if (!reportCollaborator) {
          this.availableCollaborators.push(collaborator);
        }
      });
    },
    updateAvailableWelders() {
      this.availableWelders = [];
      if (!this.selectedReport || !this.selectedReport.project || !this.selectedReport.project.dealer) {
        return;
      }
      this.selectedReport.project.dealer.welders.forEach((welder) => {
        let reportWelder = this.selectedReport.welders.find(
          (i) => i.id == welder.id
        );
        if ((!reportWelder && welder.id > 0) || (reportWelder?.check === true)) {
          if (reportWelder) delete reportWelder.check;
          let current = new Date();
          let expiration = new Date(welder.welderExpirationDateCwb);

          if (current.getFullYear() > expiration.getFullYear()) {
            welder.isExpired = true;
          } else if (current.getFullYear() == expiration.getFullYear()) {
            if (current.getMonth() > expiration.getMonth()) {
              welder.isExpired = true;
            } else if (current.getMonth() == expiration.getMonth()) {
              if (current.getDate() > expiration.getDate()) {
                welder.isExpired = true;
              } else {
                welder.isExpired = false;
              }
            } else {
              welder.isExpired = false;
            }
          } else {
            welder.isExpired = false;
          }
          if (!reportWelder) this.availableWelders.push(welder);
        }
        if (
          welder.id == -1 &&
          welder.firstname == "" &&
          welder.lastname == ""
        ) {
          this.availableWelders.push({
            id: welder.id,
            isOther: true,
            isInstaller: false,
            isWelder: true,
            firstname: "",
            lastname: "",
            welderExpirationDateCwb: null,
          });
        } else if (welder.id < 0 && welder.id != -1) {
          let tempId = welder.id;
          tempId--;
          this.availableWelders.push({
            id: tempId,
            isOther: true,
            isInstaller: false,
            isWelder: true,
            firstname: "",
            lastname: "",
            welderExpirationDateCwb: null,
          });
        }
      });
    },
    updateAvailablePilePlatesDimensions() {

      if (!this.selectedReport) {
        return;
      }

      this.selectedReport.pilePlateDimensions = JSON.parse(JSON.stringify(this.pilePlateDimensions, null, 2));

      if (this.selectedReport.project.dealer && this.selectedReport.project.dealer.standard_supporting_plates) {
        for (let i = 0; i < this.selectedReport.project.dealer.standard_supporting_plates.length; i++) {
          let index = this.selectedReport.pilePlateDimensions.length;
          
          let standardPlate = this.selectedReport.project.dealer.dealerSupportingPlates.find(sp => sp.id == this.selectedReport.project.dealer.standard_supporting_plates[i].id);

          this.selectedReport.pilePlateDimensions.splice(index, 0, { 
            id: index, 
            plateId: this.selectedReport.project.dealer.standard_supporting_plates[i].id, 
            dimension: standardPlate ? standardPlate.name + ' - ' + standardPlate.translatedName : this.selectedReport.project.dealer.standard_supporting_plates[i].name,
            mobility: this.selectedReport.project.dealer.standard_supporting_plates[i].mobility,
            form: this.selectedReport.project.dealer.standard_supporting_plates[i].form
          })
        }
      }

      for (let pile of this.selectedReport.piles) {
        this.formatPileStandardSupportingPlate(pile);
      }
    },
    formatPileStandardSupportingPlate(pile) {
      if (!this.selectedReport.pilePlateDimensions) {
        return;
      }

      if (pile.standardSupportingPlateId) {
        var plateSelected = this.selectedReport.pilePlateDimensions.find(s => s.plateId == pile.standardSupportingPlateId);
        pile.pilePlatesDimensionId = plateSelected ? plateSelected.id : null;
        pile.plateReadonly = pile.pilePlatesDimensionId != null;
      }
      else {
        pile.plateReadonly = false;
      }

      if (!pile.pilePlatesDimensionId && pile.customPlateDimensions) {
        pile.pilePlatesDimensionId = -1;
      }
    },
    setSelectedReportCustomerId(id) {
      this.selectedReport.project.customerId = id;

      if (this.selectedReport.project && this.selectedReport.project.dealer) {
        this.selectedReport.project.customer = this.selectedReport.project.dealer.customers.find(
          (c) => c.id == id
        );
      }
      else {
        this.selectedReport.project.customer = null;
      }
    },
    validateReport() {
      let report = this.selectedReport;
      //console.log("Validating report...", report);

      this.resetReportErrors(report);

      if (!report.project.dealerId) {
        report.errorDealer = true;
        report.errors.push(this.$t("validation.dealer"));
      }

      if (!report.project.customerId && report.project.customerId != 0) {
        report.errorCustomerId = true;
        report.errors.push(this.$t("validation.customerId"));
      }

      // Le nom et prénom est obligatoire que si le nom de compagnie n'est pas spécifié
      if (!report.customerCompany) {
        if (!report.customerFirstname && !report.customerLastname) {
          report.errorCustomerName = true;
          report.errorCustomerFirstname = true;
          report.errorCustomerLastname = true;
          report.errorCustomerCompany = true;
        }
      }

      // L'adresse du client est obligatoire si
      // - Il y a une demande de conformité
      if (report.isCustomerAddress) {
        if (!report.customerAddress || !report.customerAddress.countryId) {
          report.errorCustomerAddress = true;
          report.errorCustomerCountry = true;
        }
        if (!report.customerAddress || !report.customerAddress.stateId) {
          report.errorCustomerAddress = true;
          report.errorCustomerState = true;
        }
        if (!report.customerAddress || !report.customerAddress.address1) {
          report.errorCustomerAddress = true;
          report.errorCustomerAddress1 = true;
        }
        if (!report.customerAddress || !report.customerAddress.city) {
          report.errorCustomerAddress = true;
          report.errorCustomerCity = true;
        }
      }

      if (report.errorCustomerName) {
        report.errors.push(this.$t("validation.customer_name"));
      }
      if (report.errorCustomerAddress) {
        report.errors.push(this.$t("validation.customer_address"));
      }

      if (!report.isCustomerAddress) {
        if (!report.siteAddress.countryId) {
          report.errorSiteAddress = true;
          report.errorSiteCountry = true;
        }
        if (!report.siteAddress.stateId) {
          report.errorSiteAddress = true;
          report.errorSiteState = true;
        }
        if (!report.siteAddress.address1) {
          report.errorSiteAddress = true;
          report.errorSiteAddress1 = true;
        }
        if (!report.siteAddress.city) {
          report.errorSiteAddress = true;
          report.errorSiteCity = true;
        }
        if (report.errorSiteAddress) {
          report.errors.push(this.$t("validation.site_address"));
        }
      }

      if (this.projectsMandatory && report.projectTypes.length == 0) {
        report.errorProjectType = true;
        report.errors.push(this.$t("validation.project_type"));
      }

      if (this.installationDatesMandatory &&
          (!report.installationStart || !report.installationEnd)) {
        report.errorInstallationDates = true;
        report.errors.push(this.$t("validation.project_duration"));
      }

      if (this.installersMandatory && report.installers.length == 0) {
        report.errorSiteInstaller = true;
        report.errors.push(this.$t("validation.site_installer"));
      }

      if (this.weldersMandatory && report.welders.length == 0) {
        report.errorSiteWelder = true;
        report.errors.push(this.$t("validation.site_welder"));
      }

      if (this.infoPileAngleConformMandatory && !report.isPileAngleConform) {
        report.errorPileAngleConform = true;
        report.errors.push(this.$t("validation.info_pile_angle"));
      }
      if (this.infoPilePositionConformMandatory && !report.isPilePositionConform) {
        report.errorPilePositionConform = true;
        report.errors.push(this.$t("validation.info_pile_position"));
      }
      if (this.infoWeldingMandatory && !report.isWeldingDone) {
        report.errorInfoWelding = true;
        report.errors.push(this.$t("validation.info_welding"));
      }
      if (this.infoSelfTappingScrewMandatory && !report.isSelfTappingScrew) {
        report.errorInfoSelfTappingScrew = true;
        report.errors.push(this.$t("validation.info_self_tapping_screw"));
      }
      if (this.infoStickersMandatory && !report.areStickersPosed) {
        report.errorInfoStickers = true;
        report.errors.push(this.$t("validation.info_stickers"));
      }
      if (this.infoGreenSleeveMandatory && !report.isGreenSleeve) {
        report.errorInfoGreenSleeve = true;
        report.errors.push(this.$t("validation.info_green_sleeve"));
      }

      if (this.machinesMandatory) {
        if (report.site_dealer_machine_motors.length > 0) {
          for (let sdmm of report.site_dealer_machine_motors) {
            if (!((sdmm.id > 0 && sdmm.dealerMachineId && sdmm.dealerMachineMotorId > 0) || // Valid real machine/motor
                (sdmm.id < 0 && sdmm.motorNumber && sdmm.machineId))) { // Valid other machine motor
              report.errorDealerMachineMotor = true;
              report.errors.push(this.$t("validation.invalid_machine_motor"));
              break;
            }
          }
        } 
        else {
          report.errorDealerMachineMotor = true;
          report.errors.push(this.$t("validation.dealer_machine_motor"));
        }
      }

      if (report.piles.length == 0) {
        report.errors.push(this.$t("validation.add_pile"));
        report.errorAddPile = true;
      }

      for (let projectType of report.projectTypes) {
        //console.log(projectType)
        if (this.projectDimensionsMandatory) {
          if (!projectType.projectDimensions) {
            report.errorProjectDimension = true;
            projectType.errorProjectDimension = true;
            report.errors.push(this.$t("validation.project_dimension"));
          }
        }

        // On doit permettre la valeur 0
        if (
          !projectType.heightAboveGround_ft &&
          projectType.heightAboveGround_ft != 0 &&
          this.projectTypeHeightMandatory
        ) {
          projectType.errorHeightAboveGround = true;
          report.errors.push(this.$t("validation.heightaboveground"));
        }

        if (this.projectFreeStructureMandatory &&
            (projectType.isFreeStandingStructure == null || projectType.isFreeStandingStructure == undefined)) {
            projectType.errorProjectFreeStructure = true;
            report.errors.push(this.$t("validation.project_free_structure"));
        }

        if (this.concreteSlabMandatory(projectType) &&
            !(projectType.concreteSlabThickness_ft > 0)) {
            projectType.errorConcreteSlab = true;
            report.errors.push(this.$t("validation.error_concrete_slab"));
        }

        if (this.brickMandatory(projectType) &&
            !(projectType.brickHeight_ft > 0)) {
            projectType.errorBrick = true;
            report.errors.push(this.$t("validation.error_brick"));
        }
      }

      if (this.pilesMandatory) {
        for (let p of report.piles) {

          if (p.isNotInstalled) {

            if (this.pileNotInstalledMandatory(p) &&
                (!p.notInstalledReason || 
                 (p.notInstalledReason == PILE_NOT_INSTALLED_REASON.OTHER && !p.notInstalledOtherReason))) {
              p.error = true;
              p.errorNotInstalledReason = true;
            }
          }
          else {
            if (!p.pileModelId) {
              p.error = true;
              p.errorNoPileModel = true;
            }
            if (!p.isAnchoredToBedrock && p.pile_helixes.length == 0) {
              p.errorNoHelixes = true;
              p.error = true;
            }
            if (this.pileFinishMandatory && (p.surfaceFinishEnum == null || p.surfaceFinishEnum == undefined)) {
              p.errorSurfaceFinishEnum = true;
              p.error = true;
            }

            if (this.pilePlateDimensionMandatory &&
                ((p.pilePlatesDimensionId == -1 && !p.customPlateDimensions) ||
                 (p.pilePlatesDimensionId != -1 && !(p.pilePlatesDimensionId > 0)))) {
              p.errorPlateDimensions = true;
              p.error = true;
            }

            if (this.plateTypeMandatory) {
              if (!p.isCustomPlate) {
                if (
                  p.isFixPlate == null ||
                  p.isFixPlate == undefined ||
                  p.plateTypeEnum == null ||
                  p.plateTypeEnum == undefined
                ) {
                  p.errorPlateTypeEnum = true;
                  p.error = true;
                }
              }
            }

            if (!p.isAnchoredToBedrock && !p.pressure_psi) {
              p.errorMissingPressure = true;
              p.error = true;
            }
            if (!p.depth_ft && !p.isAnchoredToBedrock) {
              p.errorDepth = true;
              p.error = true;
            }
            if (this.pileHeightAboveGroundMandatory && !p.heightAboveGround_ft && p.heightAboveGround_ft != 0) {
              p.errorHeightAboveGround = true;
              p.error = true;
            }
            if (this.machinesMandatory) {
              if (!p.siteDealerMachineMotorId) {
                p.errorSiteDealerMachineMotor = true;
                p.error = true;
              }
            }

            if (this.pileImpactsMandatory && (!p.impact1_ft || !p.impact2_ft || !p.impact3_ft)) {
              p.errorImpact = true;
              p.error = true;
            }

            if (this.pileConcreteBlockMandatory &&
                ((p.concreteBlockTypeId == -1 && !p.customConcreteBlockType) ||
                 (p.concreteBlockTypeId != -1 && !(p.concreteBlockTypeId > 0)))) {
              p.errorConcreteBlock = true;
              p.error = true;
            }
          }

          if (p.error) {
            report.errors.push(
              this.$t("validation.pile_validate") + p.index + "!"
            );
          }
        }
      }

      if (this.soilTypeMandatory && !this.soilTypeSelected(report)) {
        report.errors.push(this.$t("validation.soil_type"));
        report.errorSoilType = true;
      }

      let readingsGloballyMandatory = this.torqueReadingMandatory(false);
      let readingMandatoryWhenAdded = this.torqueReadingMandatory(true);
      
      if (readingsGloballyMandatory || readingMandatoryWhenAdded) {
        if (readingsGloballyMandatory) {

          if (!report.site_torque_readings || report.site_torque_readings.length == 0) {
            report.errorReadings = true;
          }
          else {
            for (let pile of report.piles) {
              if (!report.site_torque_readings.find(r => r.pileNumber == pile.index)) {
                report.errorReadings = true;
                break;
              }
            }
          }
        }
        if (report.site_torque_readings) {
          // On valide que chaque lecture a une pression
          for (let reading of report.site_torque_readings) {
            if (!reading.pressure_psi) {
              report.errorReadings = true;
              reading.pressureError = true;
            }
          }
        }
        if (report.errorReadings) {
          report.errors.push(this.$t("validation.torque_pressure"));
        }
      }

      if (this.sitePhotosMandatory() && report.fileSitePhotos.length == 0) {
        report.errors.push(this.$t("validation.site_photo"));
        report.errorSitePhoto = true;
        report.errorPilePhoto = true;
        report.errorPlatePhoto = true;
      }

      if (this.pilesLocationMandatory() && report.filePileLocations.length == 0) {
        report.errors.push(this.$t("validation.pile_location"));
        report.errorPileLocation = true;
      }

      if (this.permitPlansMandatory() && report.approvedPermitPlans.length == 0) {
        report.errors.push(this.$t("validation.permit_plan"));
        report.errorApprovedPermitPlan = true;
      }

      if (this.collaboratorsMandatory) {
        if (report.collaborators.length == 0) {
            report.errorCollaborators = true;
            report.errors.push(this.$t("validation.collaborators"));
        }
      }

      if (this.safetyFactorMandatory) {
        if (!report.safetyFactorTension || !report.safetyFactorCompression) {
          report.errorSafetyFactor = true;
          report.errors.push(this.$t("validation.safety_factor"));
        }
      }

      if (this.contactsMandatory) {
        if (!report.contactFirstname && !report.contactLastname) {
          report.errorContacts = true;
          report.errors.push(this.$t("validation.contact")); 
        }
      }
    },
    resetReportErrors(report) {
      if (!report) {
        return;
      }
      //console.log("Reseting report errors !");
      report.errors = [];
      report.errorDealer = false;
      report.errorCustomerId = false;
      report.errorCustomerName = false;
      report.errorCustomerAddress = false;
      report.errorSiteAddress = false;
      report.errorProjectType = false;
      report.errorProjectDimension = false;
      report.errorInstallationDates = false;
      report.errorSiteInstaller = false;
      report.errorSiteWelder = false;
      report.errorPileAngleConform = false;
      report.errorPilePositionConform = false;
      report.errorDealerMachineMotor = false;
      report.errorAddPile = false;
      report.errorSoilType = false;
      report.errorSitePhoto = false;
      report.errorPilePhoto = false;
      report.errorPlatePhoto = false;
      report.errorPileLocation = false;
      report.errorCustomerFirstname = false;
      report.errorCustomerLastname = false;
      report.errorCustomerCompany = false;
      report.errorCustomerCountry = false;
      report.errorCustomerState = false;
      report.errorCustomerPoBox = false;
      report.errorCustomerAddress1 = false;
      report.errorCustomerCity = false;
      report.errorSiteCountry = false;
      report.errorSiteState = false;
      report.errorSiteAddress1 = false;
      report.errorSiteCity = false;
      report.errorSitePoBox = false;
      report.errorApprovedPermitPlan = false;
      report.errorCollaborators = false;
      report.errorSafetyFactor = false;
      report.errorContacts = false;
      report.errorInfoWelding = false;
      report.errorInfoSelfTappingScrew = false;
      report.errorInfoStickers = false;
      report.errorInfoGreenSleeve = false;
      report.errorReadings = false;
    
      for (let projectType of report.projectTypes) {
        projectType.errorProjectDimension = false;
        projectType.errorHeightAboveGround = false;
        projectType.errorProjectFreeStructure = false;
        projectType.errorConcreteSlab = false;
        projectType.errorBrick = false;
      }
    
      for (let p of report.piles) {
        p.error = false;
        p.errorNoPileModel = false;
        p.errorNoHelixes = false;
        p.errorSurfaceFinishEnum = false;
        p.errorPlateTypeEnum = false;
        p.errorPlateDimensions = false;
        p.errorMissingPressure = false;
        p.errorDepth = false;
        p.errorHeightAboveGround = false;
        p.errorSiteDealerMachineMotor = false;
        p.errorNotInstalledReason = false;
        p.errorImpact = false;
        p.errorConcreteBlock = false;
      }

      if (report.site_torque_readings) {
        for (let reading of report.site_torque_readings) {
          reading.pressureError = false;
        }
      }
    },
    resetReport() {
      //console.log("Resetting report !");
      this.displayErrors = false;
      this.selectedReport = null;
    },
    setSelectedReportId(id) {
      this.selectedReportId = id;
      //console.log("Setting selected report id to " + id);
    },
    setDisplayErrors(value) {
      this.displayErrors = value;
    },
    selectPhoto(photo) {
      if (photo.renaming) return;
      this.photosDialog = true;
      
      let allPhotos = this.allPhotos;
      this.selectedPhotoIndex = allPhotos.indexOf(photo);
    },
    isCalculationInPounds() {
      return !this.selectedReport.project.dealer.engineering_department ||
             this.selectedReport.project.dealer.engineering_department.calculationFormulaType != CALCULATION_FORMULA_TYPE.FRANCE;
    },
    isMetric() {
        return this.selectedReport && this.selectedReport.project && this.selectedReport.project.dealer && this.selectedReport.project.dealer.globalMeasureUnit == UNIT_MEASURES.METRIC;
    },
    isMetricImpact() {
        return this.selectedReport && this.selectedReport.project && this.selectedReport.project.dealer && this.selectedReport.project.dealer.impactMeasureUnit == UNIT_MEASURES.METRIC;
    },
    getDealerMachineMotors(dealerMachineId) {
      let motors = [];
       if (!dealerMachineId || !this.selectedReport.project.dealer || !this.selectedReport.project.dealer.dealer_machines) {
        return motors;
      }

      let dealerMachine = this.selectedReport.project.dealer.dealer_machines.find(m => m.id == dealerMachineId);
      if (dealerMachine && dealerMachine.dealer_machine_motors) {
        for (let dealerMachineMotor of dealerMachine.dealer_machine_motors) {
          if (dealerMachineMotor.motor) {
            motors.push(dealerMachineMotor.motor);
          }
        }
      }
      return motors;
    },

    moment() {
      return moment();
    },

    capitalizeFirstLetter (str) {
      if (!str || str.length == 0) {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    async projectChanged(newId) {
      this.selectedReport.changes.projectId = newId;

      this.isSaving = true;
      await this.saveChanges();
      this.isSaving = false;

      this.reloadPage();
    },

    async dealerChanged() {
      try {
        this.isSaving = true;
        //console.log('isSaving', this.isSaving)

        this.singleSelectedPile = null;
        
        this.selectedReport.changes.dealerId = this.selectedReport.project.dealerId;
        await this.saveChanges();

        await this.retrieveReportData();

        this.$refs.customerAddressRef.updateAddress(this.selectedReport.customerAddress);
        this.$refs.siteAddressRef.updateAddress(this.selectedReport.siteAddress);
      }
      finally {
        this.isSaving = false;
        //console.log('isSaving', this.isSaving)
      }
    },
    async getOtherProjects() {
      try {
        this.otherProjects = null;

        let response = await DealerService.getOtherProjects(this.selectedReport.project.id, this.selectedReport.project.dealerId, getLanguage());

        this.otherProjects = response && response.data ? response.data : [];
      }
      catch {
        this.otherProjects = [];
      }
    },
    
    async customerChanged() {

      this.selectingCustomer = true;

      //console.log("Selected customer " + this.selectedReport.project.customerId)
      let customerId = this.selectedReport.project.customerId;
      this.selectedReport.changes.customerId = customerId > 0 ? customerId : null;

      this.setSelectedReportCustomerId(this.selectedReport.project.customerId);

      // Si on change pour un client officiel, on va chercher sa liste de contacts
      if (customerId > 0) {
        this.selectedReport.project.customer.contacts = await CustomerService.getContacts(customerId);
        this.formatContacts();
      }

      if (this.$refs && this.$refs.emailForm1) {
        this.$refs.emailForm1.resetValidation();
      }
      if (this.$refs && this.$refs.emailForm2) {
        this.$refs.emailForm2.resetValidation();
      }

      await this.saveChanges();

      this.selectingCustomer = false;
    },

    contactChanged() {
      this.selectedReport.changes.contactId = this.selectedReport.contactId > 0 ? this.selectedReport.contactId : null;
      this.saveChanges();
    },
    siteContactFirstnameChanged(value) {
      this.selectedReport.contactFirstname = this.capitalizeFirstLetter(value);
      this.selectedReport.changes.contactFirstname = this.selectedReport.contactFirstname;
      this.saveChanges();
    },
    siteContactLastnameChanged(value) {
      this.selectedReport.contactLastname = this.capitalizeFirstLetter(value);
      this.selectedReport.changes.contactLastname = this.selectedReport.contactLastname;
      this.saveChanges();
    },
    siteContactPhoneChanged(value) {
      this.selectedReport.contactPhoneMobile = value;
      this.selectedReport.changes.contactPhoneMobile = this.selectedReport.contactPhoneMobile;
      this.saveChanges();
    },
    siteContactEmailChanged(value) {
      this.selectedReport.contactEmail = value;
      this.selectedReport.changes.contactEmail = this.selectedReport.contactEmail;
      this.saveChanges();
    },

    onCustomerLastNameChange(value, justRefresh) {
      this.selectedReport.customerLastname = this.capitalizeFirstLetter(value);
      this.selectedReport.changes.customer.lastName = this.selectedReport.customerLastname;
      if (justRefresh !== true) this.saveChanges();
    },

    onCustomerFirstNameChange(value, justRefresh) {
      this.selectedReport.customerFirstname = this.capitalizeFirstLetter(value);
      this.selectedReport.changes.customer.firstName = this.selectedReport.customerFirstname;
      if (justRefresh !== true) this.saveChanges();
    },

    onCompanyChange(value, justRefresh) {
      this.selectedReport.customerCompany = value;
      this.selectedReport.changes.customer.company = this.selectedReport.customerCompany;
      if (justRefresh !== true) this.saveChanges();
    },

    onPhoneMobileChange(value) {
      this.selectedReport.customerPhoneMobile = value;
      this.selectedReport.changes.customer.phoneMobile = this.selectedReport.customerPhoneMobile;
      this.saveChanges();
    },

    emailMainChanged(value) {
      this.selectedReport.customerEmailMain = value;
      this.selectedReport.changes.customer.emailMain = this.selectedReport.customerEmailMain;
      this.saveChanges();
    },

    saveCustomerAddress(addressChanges) {
      Object.assign(this.selectedReport.changes.customer, addressChanges);
      Object.assign(this.selectedReport.customerAddress, addressChanges)
      
      this.saveChanges();
    },
    updateCustomerCategory() {
      this.selectedReport.changes.customer.category = this.selectedReport.customerCategory;
      this.saveChanges();
    },
    saveProjectAddress(addressChanges) {
      Object.assign(this.selectedReport.changes.siteAddress, addressChanges);
      Object.assign(this.selectedReport.siteAddress, addressChanges);
      this.saveChanges();
    },
    async transformCustomer() {

      if (!this.selectedReport.customerCompany && 
          (!(this.selectedReport.customerFirstname && this.selectedReport.customerLastname))) {
        this.openToast({ message: this.$t("reportInfo.valid_customer"), duration: 5000, color: 'red'});
        return;
      }

      this.transformingCustomer = true;

      this.similarCustomers = await CustomerService.getReportOtherCustomerSimilarCustomers(this.selectedReport.projectId);
      if (this.similarCustomers.length > 0) {
        this.transformDialog = true;
        this.transformDialogTitle = this.$t('confirm.similar_client');
        this.transformDialogBody = this.$t('confirm.transform_client');
        this.selectedSimilarCustomerId = this.similarCustomers[0].id;
      }
      else {
        // Save directly since no similar clients
        this.saveCustomerToDB();
      }
    },

    async transformContact() {

      if (!(this.selectedReport.contactFirstname)) {
        this.openToast({ message: this.$t("reportInfo.valid_contact"), duration: 5000, color: 'red'});
        return;
      }

      this.savingContact = true;

      this.selectedReport.changes.transformContact = true;
      this.saveChanges();
    },

    async updateExistingCustomer() {
      this.transformDialog = false;

      this.selectedReport.project.customerId = this.selectedSimilarCustomerId;
      this.selectedReport.changes.updateExistingCustomer = this.selectedSimilarCustomerId;
      this.saveChanges();
    },

    async saveCustomerToDB() {

      this.transformDialog = false;
      this.transformingCustomer = true;
      this.savingCustomer = true;

      this.selectedReport.changes.transformCustomer = true;
      this.saveChanges();
    },

    updateReferenceNumber(value, save) {
      this.selectedReport.project.referenceNumber = value;

      this.selectedReport.project.referenceNumber = this.capitalizeFirstLetter(this.selectedReport.project.referenceNumber);
      this.selectedReport.changes.referenceNumber = this.selectedReport.project.referenceNumber;
      if (save) {
        this.saveChanges();
      }
    },

    isCustomerAddressClicked()  {
      this.selectedReport.changes.isCustomerAddress = this.selectedReport.isCustomerAddress;
      this.saveChanges();
    },

    async updateInstallationStart() {
      // Si la date de début modifiée est plus grande que la date de fin,  mettre à jour la date de fin
      if (this.selectedReport.installationStart > this.selectedReport.installationEnd) {
        this.selectedReport.installationEnd = this.selectedReport.installationStart;
        this.selectedReport.changes.installationEnd = this.selectedReport.installationEnd;
      }

      this.selectedReport.changes.installationStart = this.selectedReport.installationStart;
      this.saveChanges();
    },

    async updateInstallationEnd() {
      // Si la date de fin modifiée est plus petite que la date de début, mettre à jour la date de début
      if (this.selectedReport.installationEnd < this.selectedReport.installationStart) {
        this.selectedReport.installationStart = this.selectedReport.installationEnd;
        this.selectedReport.changes.installationStart = this.selectedReport.installationStart;
      }

      this.selectedReport.changes.installationEnd = this.selectedReport.installationEnd;
      this.saveChanges();
    },
    
    async installerAdded(installer) {
      if (!installer) {
        return;
      }
      this.selectedReport.installers.push(installer);
      this.updateAvailableInstallers();

      this.selectedReport.changes.addedInstallers.push(installer);
      this.saveChanges();

      // Using netTick to allow time for vuejs to detect the change and update
      await this.$nextTick();
      this.installerAdd = null;
    },

    removeInstaller(index) {
      let removedInstaller = this.selectedReport.installers[index];
      
      this.selectedReport.installers.splice(index, 1);
      this.updateAvailableInstallers();

      this.selectedReport.changes.deletedInstallers.push(removedInstaller.id);
      this.saveChanges();
    },

    async collaboratorAdded(collaborator) {
      if (!collaborator) {
        return;
      }
      this.selectedReport.collaborators.push(collaborator);
      this.updateAvailableCollaborators();

      this.selectedReport.changes.addedCollaborators.push(collaborator);
      this.saveChanges();

      // Using nextTick to allow time for vuejs to detect the change and update
      await this.$nextTick();
      this.collaboratorAdd = null;
    },

    removeCollaborator(index) {
      let removedCollaborator = this.selectedReport.collaborators[index];
      
      this.selectedReport.collaborators.splice(index, 1);
      this.updateAvailableCollaborators();

      this.selectedReport.changes.deletedCollaborators.push(removedCollaborator.id);
      this.saveChanges();
    },

    async welderAdded(welder) {
      if (!welder) {
        return;
      }

      this.selectedReport.welders.push(welder);
      this.updateAvailableWelders();

      if (welder.id > 0) {
        this.selectedReport.changes.addedWelders.push(welder);
      }
      else {
        let tempWelder = [];
        for (let i= 0; i < this.selectedReport.welders.length; i++) {
          if (this.selectedReport.welders[i].id < 0) {
            let w = this.selectedReport.welders[i];
            tempWelder.push({ firstname: w.firstname, lastname: w.lastname });
          }
        }
        this.selectedReport.changes.tempWelder = JSON.stringify(tempWelder);
      }
      this.saveChanges();

      // Using nextTick to allow time for vuejs to detect the change and update
      await this.$nextTick();
      this.welderAdd = null;
    },

    removeWelder(index) {
      let removedWelder = this.selectedReport.welders[index];

      this.selectedReport.welders.splice(index, 1);
      this.updateAvailableWelders();
      if (removedWelder.id > 0) {
        this.selectedReport.changes.deletedWelders.push(removedWelder.id);
      }
      else {
        let tempWelder = [];
        for (let i= 0; i < this.selectedReport.welders.length; i++) {
          if (this.selectedReport.welders[i].id < 0) {
            let w = this.selectedReport.welders[i];
            tempWelder.push({ firstname: w.firstname, lastname: w.lastname });
          }
        }
        this.selectedReport.changes.tempWelder = JSON.stringify(tempWelder);
      }
      this.saveChanges();
      
    },

    updateTempWelder() {
      let tempWelder = [];
      for (let i= 0; i < this.selectedReport.welders.length; i++) {
        if (this.selectedReport.welders[i].id < 0) {
          let w = this.selectedReport.welders[i];
          w.firstname = this.capitalizeFirstLetter(w.firstname);
          w.lastname = this.capitalizeFirstLetter(w.lastname);
          tempWelder.push({ firstname: w.firstname, lastname: w.lastname });
        }
      }
      this.selectedReport.changes.tempWelder = JSON.stringify(tempWelder);
      this.saveChanges();
    },

    getLowestProjectTypeId() {
      let lowestProjectTypeId = 0;
      for (let i = 0; i < this.selectedReport.projectTypes.length; i++) {
        if (this.selectedReport.projectTypes[i].id < lowestProjectTypeId) {
          lowestProjectTypeId = this.selectedReport.projectTypes[i].id;
        }
      }
      return lowestProjectTypeId;
    },

    async projectTypeAdd(projectType) {
      if (!projectType) {
        return;
      }

      this.addingProjectType = true;

      if (projectType.id > 0) {
        let newProjectType = {
          id: this.getLowestProjectTypeId() -1,
          projectTypeId: projectType.id,
          name: projectType.name,
          displayName: projectType.name,
          projectDimensions: null,
          heightAboveGround_ft: null
        }
        this.selectedReport.changes.addedProjectTypes.push(newProjectType);
      }
      else {
        let newProjectType = {
          id: projectType.id,
          name: projectType.name,
          displayName: projectType.name,
          isOther: projectType.isOther,
          projectDimensions: null,
          heightAboveGround_ft: null
        };
        this.selectedReport.projectTypes.push(newProjectType);
        let otherProjectType = [];
        for (let i= 0; i < this.selectedReport.projectTypes.length; i++) {
          if (this.selectedReport.projectTypes[i].id < 0) {
            let p = this.selectedReport.projectTypes[i];
            otherProjectType.push({ name: p.name, projectDimensions: null, heightAboveGround_ft: null });
          }
        }
        this.selectedReport.changes.otherProjectType = JSON.stringify(otherProjectType);
      }
      await this.$nextTick();
      this.addProjectType = null;

      await this.saveChanges();
      this.addingProjectType = false;
    },

    setRemovedProjectChanges(projectType) {
      if (projectType.id > 0) {
        this.selectedReport.changes.deletedProjectTypes.push(projectType.id);
      }
      else {
        let otherProjectType = [];
        for (let i= 0; i < this.selectedReport.projectTypes.length; i++) {
          if (this.selectedReport.projectTypes[i].id < 0) {
            let p = this.selectedReport.projectTypes[i];
            otherProjectType.push({ name: p.name, isFreeStandingStructure: p.isFreeStandingStructure, projectDimensions: p.projectDimensions, heightAboveGround_ft: p.heightAboveGround_ft });
          }
        }
        this.selectedReport.changes.otherProjectType = JSON.stringify(otherProjectType);
      }
    },

    removeProjectType(index) {
      let removedProjectType = this.selectedReport.projectTypes[index];

      this.selectedReport.projectTypes.splice(index, 1);
      this.setRemovedProjectChanges(removedProjectType);
      this.saveChanges();
    },

    updateOtherProjectTypeChanges() {
      let otherProjectType = [];
      for (let i= 0; i < this.selectedReport.projectTypes.length; i++) {
        if (this.selectedReport.projectTypes[i].id < 0) {
          let p = this.selectedReport.projectTypes[i];
          otherProjectType.push({ 
            name: p.name, 
            isFreeStandingStructure: p.isFreeStandingStructure, 
            projectDimensions: p.projectDimensions, 
            heightAboveGround_ft: p.heightAboveGround_ft,
            hasConcreteSlab: p.hasConcreteSlab,
            concreteSlabThickness_ft: p.concreteSlabThickness_ft,
            hasBrick: p.hasBrick,
            brickHeight_ft: p.brickHeight_ft,
            hasSpa: p.hasSpa
           });
        }
      }
      this.selectedReport.changes.otherProjectType = JSON.stringify(otherProjectType);
      this.saveChanges();
    },

    updateProjectTypeFreeStandingStructure(projectType) {
      if (projectType.id < 0) {
        this.updateOtherProjectTypeChanges();
      }
      else {
        let changedProjectType = this.getChangedProjectType(projectType.id);
        changedProjectType.isFreeStandingStructure = projectType.isFreeStandingStructure;
        this.saveChanges();
      }
    },
    updateProjectTypeProperty(projectType, property) {
      if (projectType.id < 0) {
        this.updateOtherProjectTypeChanges();
      }
      else {
        let changedProjectType = this.getChangedProjectType(projectType.id);
        changedProjectType[property] = projectType[property];
        this.saveChanges();
      }
    },
    updateProjectDimensions(projectType) {
      if (projectType.id < 0) {
        this.updateOtherProjectTypeChanges();
      }
      else {
        let changedProjectType = this.getChangedProjectType(projectType.id);
        changedProjectType.projectDimensions = projectType.projectDimensions;
        this.saveChanges();
      }
    },

    async updateProjectHeightAboveGround(newValue, projectType) {
     
      projectType.heightAboveGround_ft = newValue;

      if (projectType.id < 0) {
        this.updateOtherProjectTypeChanges();
      }
      else {
        let changedProjectType = this.getChangedProjectType(projectType.id);
        changedProjectType.heightAboveGround_ft = newValue;
        this.saveChanges();
      }
    },

    async updateProjectConcreteSlab(newValue, projectType) {
      projectType.concreteSlabThickness_ft = newValue;
      this.updateProjectTypeProperty(projectType, 'concreteSlabThickness_ft');
    },
    async updateProjectBrickHeight(newValue, projectType) {
      projectType.brickHeight_ft = newValue;
      this.updateProjectTypeProperty(projectType, 'brickHeight_ft');
    },

    dealerMachineChanged(dealerMachineId, dealerMachineMotor, index) {
      
      if (!dealerMachineId) {
        return;
      }

      dealerMachineMotor.machineName = null;
      dealerMachineMotor.serialNumber = null;
      dealerMachineMotor.motorNumber = null;
      dealerMachineMotor.machineId = null;

      // Erase the selected motor when the machine is changed
      dealerMachineMotor.motorId = null;

      let wasOther = dealerMachineMotor.id < 0;

      // Machine changed to a real machine
      if (dealerMachineId > 0) {
      
        dealerMachineMotor.dealerMachineId = dealerMachineId;
        this.updateTempMotorChanges(false);

        // Si on switch de AUTRE à une vrai machine, on enlève l'entrée de la liste, il sera ajouté au retour du backend (addedDealerMachineMotors)
        if (wasOther) {
          this.addingDealerMachineMotor = true;
          this.selectedReport.site_dealer_machine_motors.splice(index, 1);
        }

        // Retrieve the corresponding dealer machine motor to get its machine name and serial number
        let fullDealerMachineMotor = this.selectedReport.project.dealer.dealer_machines.find(dm => dm.id == dealerMachineId);
        if (fullDealerMachineMotor) {
            dealerMachineMotor.machineName = fullDealerMachineMotor.name;
            dealerMachineMotor.serialNumber = fullDealerMachineMotor.serialNumber;
            dealerMachineMotor.defaultMachineMotorId = fullDealerMachineMotor.defaultMachineMotorId;
        }
        // Retrieve the machine info from the user data
        let dealerMachine = this.selectedReport.project.dealer.dealer_machines.find(dm => dm.id == dealerMachineMotor.dealerMachineId);
        if (dealerMachine) {
          dealerMachineMotor.machine = this.machines.find(m => m.id == dealerMachine.machineId);
        }
        
        // On assigne le moteur par défaut de la machine s'il y en a un
        let defaultMachineMotorId = dealerMachineMotor.defaultMachineMotorId; // peut être null, ce qui est correct
        dealerMachineMotor.dealerMachineMotorId = defaultMachineMotorId;

        // S'il y a une moteur par défaut, on met à jour l'objet de la liste en allant chercher ses informations
        if (defaultMachineMotorId) {
          let formattedDealerMachineMotor = this.getFormattedSiteDealerMachineMotor(this.selectedReport, dealerMachineMotor);
          if (formattedDealerMachineMotor) {
            dealerMachineMotor.motorId = formattedDealerMachineMotor.motorId;
            dealerMachineMotor.motorNumber = formattedDealerMachineMotor.motorNumber;
          }
        }

        // Save the machinemotor change
        let dealerMachineMotorChange = this.getChangedDealerMachineMotor(dealerMachineMotor.id);
        dealerMachineMotorChange.dealerMachineMotorId = defaultMachineMotorId;
        dealerMachineMotorChange.dealerMachineId = dealerMachineId;
      }
      else {
        // Machine changed to OTHER. Delete the real site_dealer_machine_motor if any.
        if (dealerMachineMotor.id > 0) {
          this.selectedReport.changes.deletedDealerMachineMotors.push(dealerMachineMotor.id);
        }
        dealerMachineMotor.id = this.getLowestDealerMachineMotorId() - 1;
        this.updateTempMotorChanges(false);
      }
      this.forceValidMotorsRecompute++;

      this.saveChanges();
    },

    motorChanged(motorId, dealerMachineMotor) {
      if (!motorId) {
        return;
      }

      // Retrieve the new MachineMotorId from the Dealers list of machines searching with the MotorId and the MachineId
      let selectedDealerMachineMotor = null;
      let dealerMachine = this.selectedReport.project.dealer.dealer_machines.find(m => m.id == dealerMachineMotor.dealerMachineId);
      if (dealerMachine) {
        for (let dealerMachineMotor of dealerMachine.dealer_machine_motors) {
          if (dealerMachineMotor.motor && dealerMachineMotor.motor.id == motorId) {
            selectedDealerMachineMotor = dealerMachineMotor;
            break;
          }
        }
      }

      if (selectedDealerMachineMotor) {
        let dealerMachineMotorChange = this.getChangedDealerMachineMotor(dealerMachineMotor.id);
        dealerMachineMotorChange.dealerMachineMotorId = selectedDealerMachineMotor.id;
        
        dealerMachineMotor.dealerMachineMotorId = selectedDealerMachineMotor.id;
        dealerMachineMotor.motorNumber = selectedDealerMachineMotor.motor.name;

        this.forceValidMotorsRecompute++;

        this.saveChanges();
      }
    },

    updateTempMotorChanges(save) {
      let tempDealerMachineMotor = [];
      
      for (let i= 0; i < this.selectedReport.site_dealer_machine_motors.length; i++) {
        if ((this.selectedReport.site_dealer_machine_motors[i].id < 0 && !this.selectedReport.site_dealer_machine_motors[i].dealerMachineId) ||
            (this.selectedReport.site_dealer_machine_motors[i].dealerMachineId < 0)) {
          let m = this.selectedReport.site_dealer_machine_motors[i];
          tempDealerMachineMotor.push({id: m.id, machineName: m.machineName, serialNumber: m.serialNumber, motorNumber: m.motorNumber, machineId: m.machineId });
        }
      }

      this.selectedReport.changes.tempDealerMachineMotor = JSON.stringify(tempDealerMachineMotor);

      if (save) {
        this.forceValidMotorsRecompute++;
        this.saveChanges();
      }
    },

    getChangedDealerMachineMotor(dealerMachineMotorId) {
      let dealerMachineMotorChanged = this.selectedReport.changes.addedOrModifiedDealerMachineMotors.find(m => m.id == dealerMachineMotorId);
      if (dealerMachineMotorChanged == null) {
        dealerMachineMotorChanged = { id: dealerMachineMotorId };
        this.selectedReport.changes.addedOrModifiedDealerMachineMotors.push(dealerMachineMotorChanged);
      }
      return dealerMachineMotorChanged;
    },

    getLowestDealerMachineMotorId() {
      let lowestDealerMachineMotorId = 0;
      for (let i = 0; i < this.selectedReport.site_dealer_machine_motors.length; i++) {
        if (this.selectedReport.site_dealer_machine_motors[i].id < lowestDealerMachineMotorId) {
          lowestDealerMachineMotorId = this.selectedReport.site_dealer_machine_motors[i].id;
        }
      }
      return lowestDealerMachineMotorId;
    },

    addDealerMachineMotor() {

      if (this.selectedReport.site_dealer_machine_motors.length >= 10) {
        this.openToast({ message: this.$t("reportInfo.machine_maximum"), duration: 5000, color: 'red'});
        return;
      }

      this.addingDealerMachineMotor = true;

      let newDealerMachineMotor = {
        id: this.getLowestDealerMachineMotorId() - 1,
        dealerMachineId: null
      };

      let changedDealerMachineMotor = JSON.parse(JSON.stringify(newDealerMachineMotor));
      this.selectedReport.changes.addedOrModifiedDealerMachineMotors.push(changedDealerMachineMotor);

      this.saveChanges(); 
    },

    deleteDealerMachineMotor(index) {
      let dealerMachineMotorToRemove = this.selectedReport.site_dealer_machine_motors[index];
      this.selectedReport.site_dealer_machine_motors.splice(index,1);
      
      if (dealerMachineMotorToRemove.id > 0) {
        this.selectedReport.changes.deletedDealerMachineMotors.push(dealerMachineMotorToRemove.id);
      }
      else {
        this.updateTempMotorChanges(false);
      }

      // Unset this machine motor from the piles.
      for (let p of this.selectedReport.piles) {
        if (p.siteDealerMachineMotorId == dealerMachineMotorToRemove.id) {
          let pileChange = this.getChangedPile(p.id);
          pileChange.siteDealerMachineMotorId = null;
          p.siteDealerMachineMotorId = null;
        }
      }

      this.forceValidMotorsRecompute++;

      //console.log('this.saveChanges')
      this.saveChanges();
    },

    checkWelding() {
      this.selectedReport.changes.isWeldingDone = this.selectedReport.isWeldingDone;
      this.saveChanges();
    },

    checkSelfTappingScrew() {
      this.selectedReport.changes.isSelfTappingScrew = this.selectedReport.isSelfTappingScrew;
      this.saveChanges();
    },

    checkStickersPosed() {
      this.selectedReport.changes.areStickersPosed = this.selectedReport.areStickersPosed;
      this.saveChanges();
    },

    checkGreenSleeve() {
      this.selectedReport.changes.isGreenSleeve = this.selectedReport.isGreenSleeve;
      this.saveChanges();
    },

    checkpileangle() {
      this.selectedReport.changes.isPileAngleConform = this.selectedReport.isPileAngleConform;
      this.saveChanges();
    },

    checkpileposition() {
      this.selectedReport.changes.isPilePositionConform = this.selectedReport.isPilePositionConform;
      this.saveChanges();
    },

    getChangedProjectType(projectTypeId) {
      let projectTypeChange = this.selectedReport.changes.addedProjectTypes.find(p => p.id == projectTypeId);
      if (projectTypeChange == null) {
        projectTypeChange = { id: projectTypeId };
        this.selectedReport.changes.addedProjectTypes.push(projectTypeChange);
      }
      return projectTypeChange;
    },

    getChangedPile(pileId) {
      var pileChange = this.selectedReport.changes.addedOrModifiedPiles.find(p => p.id == pileId);
      if (pileChange == null) {
        pileChange = { id: pileId };
        this.selectedReport.changes.addedOrModifiedPiles.push(pileChange);
      }
      return pileChange;
    },

    pileModelChanged: function (pile)  {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.pileModelId = pile.pileModelId;
      this.saveChanges();
    },

    helixModelChanged: function (pile, helix, event, index) {
      let pileChange = this.getChangedPile(pile.id);
      if (event > 0) {
        if (!pileChange.addedHelixModels) {
          pileChange.addedHelixModels = [];
        }
        pileChange.addedHelixModels.push(event);
      }
      else {
        pile.pile_helixes.splice(index, 1);
      }
      if (!pileChange.removedHelixModels) {
        pileChange.removedHelixModels = [];
      }
      pileChange.removedHelixModels.push(helix.helixModelId);
      helix.helixModelId = event;

      this.saveChanges();
    },

    async helixAdded(pile, helix) {
      if (!helix) {
        return;
      }

      let helixModelId = helix.id;

      let pileChange = this.getChangedPile(pile.id);

      if (!pileChange.addedHelixModels) {
        pileChange.addedHelixModels = [];
      }
      pileChange.addedHelixModels.push(helixModelId);
      pile.pile_helixes.push({ id: -1, pileId: pile.id, helixModelId: helix.id, helix_model: helix });

      this.saveChanges();

      await this.$nextTick();
      pile.addHelix = null;
    },

    updatePileAnchored(pile) {
      let pileChange = this.getChangedPile(pile.id);
      if (pile.pile_helixes.length != 0) {
        pileChange.anchored = {isAnchoredToBedrock: pile.isAnchoredToBedrock, deleteHelix: true};
        pile.pile_helixes = [];
      }
      else {
        pileChange.anchored = {isAnchoredToBedrock: pile.isAnchoredToBedrock, deleteHelix: false};
      }
      this.saveChanges();
    },

    isCustomPlateChanged(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.isCustomPlate = pile.isCustomPlate;
      this.saveChanges();
    },

    isFixPlateChanged(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.isFixPlate = pile.isFixPlate;
      this.saveChanges();
    },

    plateTypeEnumChanged(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.plateTypeEnum = pile.plateTypeEnum;
      this.saveChanges();
    },

    getLowestPileId() {
      let lowestPileId = 0;
      for (let i = 0; i < this.selectedReport.piles.length; i++) {
        if (this.selectedReport.piles[i].id < lowestPileId) {
          lowestPileId = this.selectedReport.piles[i].id;
        }
      }
      return lowestPileId;
    },

    addPile() {

      if (this.selectedReport.piles.length >= 1000) {
        this.openToast({ message: this.$t("reportInfo.maximum_pile_quantity"), duration: 5000, color: 'orange'});
        return;
      }

      this.addingPile = true;
      
      let newPile = {
        // id: this.getLowestPileId() - 1,
        pile_helixes: [],
        pilePlatesDimensionId: null,
        isCustomPlate: false,
        index: this.selectedReport.piles.length + 1,
        impact1_ft: null,
        impact2_ft: null,
        impact3_ft: null,
        hammerEnum: this.selectedReport.project.dealer.defaultHammerEnum,
        surfaceFinishEnum: this.selectedReport.project.dealer ? this.selectedReport.project.dealer.defaultSurfaceFinishEnum : null,
        extensionLength_ft: this.selectedReport.project.dealer.defaultExtensionLength_ft,
        pileLength_ft: this.selectedReport.project.dealer.defaultPileLength_ft
      };
      if (this.validMotors && this.validMotors.length == 1) {
        newPile.siteDealerMachineMotorId = this.validMotors[0].id;
      }

      this.formatPile(this.selectedReport, newPile);

      let changedPile = JSON.parse(JSON.stringify(newPile));

      this.selectedReport.changes.addedOrModifiedPiles.push(changedPile);
      this.saveChanges();
      this.allPilesChecked = false;
    },

    removePiles() {
      for (let pileToRemove of this.selectedPiles) {
        this.selectedReport.changes.deletedPiles.push(pileToRemove.id);
        for (let i = 0; i < this.selectedReport.piles.length; i++) {
          if (this.selectedReport.piles[i].id == pileToRemove.id) {
            this.selectedReport.piles.splice(i, 1);
            break;
          }
        }
        this.reindexPiles(this.selectedReport.piles); // Have to reindex each loop
        // Effacer les torque readings associés
        // le champ pileNumber pointe sur l'index du pieux dans le tableau, il faut donc aussi réindexer les pileNumber qui sont au dessus de l'index du pieu supprimé
        for (let i = 0; i < this.selectedReport.site_torque_readings.length; i++) {
          let reading = this.selectedReport.site_torque_readings[i];
          if (reading.pileNumber == pileToRemove.index) {
            this.deleteTorqueReading(reading, false);
            i--;
          }
          else if (reading.pileNumber > pileToRemove.index) {
            reading.pileNumber--;
            if (this.selectedReport.changes.addedOrModifiedTorqueReadings == null) this.selectedReport.changes.addedOrModifiedTorqueReadings = [];
            this.selectedReport.changes.addedOrModifiedTorqueReadings.push({id: reading.id, pileNumber: reading.pileNumber});
          }
        }
      }
      
      this.singleSelectedPile = null;
      this.selectedPiles = [];
      this.allPilesChecked = false;
      
      this.saveChanges();
    },

    reindexPiles(piles) {
      for (let i = 0; i < piles.length; i++) {
          let newIndex = i + 1;
          if (piles[i].index != newIndex) {
            piles[i].index = newIndex;
            let pileChange = this.getChangedPile(piles[i].id);
            pileChange.index = piles[i].index;
          }
      }
    },

    copyPile(pile) {

      this.copyingPile = true;

      let copyPileQty = parseInt(this.copyPileAmount);
      if (isNaN(copyPileQty)) return; //TODO: not supposed to happen, but?

      // Limiter à 1000
      if (copyPileQty + this.selectedReport.piles.length > 1000) {
        copyPileQty = 1000 - this.selectedReport.piles.length;
      }

      for (let i = 0; i < copyPileQty; i++) {
        let addedHelixModels = [];

        let copiedPile = JSON.parse(JSON.stringify(pile))
        delete copiedPile.id;
        copiedPile.index = this.selectedReport.piles.length + 1 + i;
        copiedPile.createdAt = null;
        copiedPile.updatedAt = null;
        copiedPile.pile_helixes.forEach(h => {
          h.id = -1;
          h.createdAt = null;
          h.updatedAt = null;
          addedHelixModels.push(h.helixModelId)
        });
        
        // C1327-841 Copier toutes les infomations du pieu
        copiedPile.isChecked = false;

        // Set pilePlatesDimensionId to null if the pile has a standard supporting plate as it is only set for the GUI
        if (copiedPile.standardSupportingPlateId) {
          copiedPile.pilePlatesDimensionId = null;
        }

        let changedPile = JSON.parse(JSON.stringify(copiedPile));
        changedPile.addedHelixModels = addedHelixModels; 
        this.selectedReport.changes.addedOrModifiedPiles.push(changedPile);
      }

      this.saveChanges();
      this.allPilesChecked = false;
    },

    copyLastPile() {
      let pileToBeCopied = null;
      if (this.singleSelectedPile) {
        pileToBeCopied = this.singleSelectedPile;
      }
      else {
        pileToBeCopied = this.selectedReport.piles[this.selectedReport.piles.length - 1];
      }
      this.copyPile(pileToBeCopied);
    },

    upPile() {
      if (this.singleSelectedPile.index <= 1) {
        return;
      }
      this.changePileIndex(this.singleSelectedPile.index, this.singleSelectedPile.index - 1);
    },

    downPile() {
      if (this.singleSelectedPile.index >= this.selectedReport.piles.length) {
        return;
      }
      this.changePileIndex(this.singleSelectedPile.index, this.singleSelectedPile.index + 1);
    },

    deletePile() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$tc('confirm.pile_message', this.selectedPiles.length);
      this.confirmAction = ConfirmAction.DeletePile;
      this.confirmYesNo = true;
    },

    toggleNotInstalled() {

      if (!this.singleSelectedPile.isNotInstalled) {
        this.confirmDialog = true;
        this.confirmDialogTitle = this.$t('confirm.head');
        this.confirmDialogBody = this.$t('confirm.not_installed');
        this.confirmAction = ConfirmAction.PileNotInstalled;
        this.confirmYesNo = true;
      }
      else {
        this.confirmPileNotInstalled();
      }
    },

    confirmPileNotInstalled() {
      let pileChange = this.getChangedPile(this.singleSelectedPile.id);
      this.singleSelectedPile.isNotInstalled = !this.singleSelectedPile.isNotInstalled;
      pileChange.isNotInstalled = this.singleSelectedPile.isNotInstalled;

      // Si le pieux n'est pas installé, on efface toutes les autres valeurs non affichées
      if (this.singleSelectedPile.isNotInstalled) {
        this.singleSelectedPile.pileModelId = null;
        pileChange.pileModelId = null;

        this.singleSelectedPile.pileLength_ft = null;
        pileChange.pileLength_ft = null;
        
        if (!pileChange.removedHelixModels) {
          pileChange.removedHelixModels = [];
        }
        for (let pileHelix of this.singleSelectedPile.pile_helixes) {
          pileChange.removedHelixModels.push(pileHelix.helixModelId);
        }
        this.singleSelectedPile.pile_helixes = [];

        this.singleSelectedPile.surfaceFinishEnum = null;
        pileChange.surfaceFinishEnum = null;

        this.singleSelectedPile.pilePlatesDimensionId = null;
        pileChange.pilePlatesDimensionId = null;

        this.singleSelectedPile.standardSupportingPlateId = null;
        pileChange.standardSupportingPlateId = null;

        this.singleSelectedPile.isFixPlate = false;
        pileChange.isFixPlate = false;

        this.singleSelectedPile.plateTypeEnum = null;
        pileChange.plateTypeEnum = null;

        this.singleSelectedPile.isCustomPlate = false;
        pileChange.isCustomPlate = false;

        this.singleSelectedPile.concreteBlockTypeId = null;
        pileChange.concreteBlockTypeId = null;

        this.singleSelectedPile.customConcreteBlockType = null;
        pileChange.customConcreteBlockType = null;

        this.singleSelectedPile.bedrockEnum = null;
        pileChange.bedrockEnum = null;

        this.singleSelectedPile.isFalseRefusal = false;
        pileChange.isFalseRefusal = false;

        this.singleSelectedPile.pressure_psi = null;
        pileChange.pressure_psi = null;

        this.singleSelectedPile.editInvoicedLength = null;
        pileChange.invoicedLength_ft = null;

        this.singleSelectedPile.extensionLength_ft = null; 
        this.singleSelectedPile.editCustomExtensionLength = null;
        pileChange.extensionLength_ft = null;

        this.singleSelectedPile.extensionUnitsCount = null;
        pileChange.extensionUnitsCount = null;

        this.singleSelectedPile.heightAboveGround_ft = null;
        pileChange.heightAboveGround_ft = null;

        this.singleSelectedPile.hammerEnum = null;
        pileChange.hammerEnum = null;

        this.singleSelectedPile.impact1_ft = null;
        pileChange.impact1_ft = null;

        this.singleSelectedPile.impact2_ft = null;
        pileChange.impact2_ft = null;

        this.singleSelectedPile.impact3_ft = null;
        pileChange.impact3_ft = null;
      }
      else {
        this.singleSelectedPile.notInstalledOtherReason = null;
        pileChange.notInstalledOtherReason = null;
        this.singleSelectedPile.notInstalledReason = null;
        pileChange.notInstalledReason = null;
      }

      this.saveChanges();
    },

    copyLastPileConfirm() {
      if (this.selectedReport.piles.length >= 1000) {
        this.openToast({ message: this.$t("reportInfo.maximum_pile_quantity"), duration: 5000, color: 'orange'});
        return;
      }

      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.copy_message');
      this.confirmAction = ConfirmAction.CopyLastPile;
      this.confirmYesNo = false;
      this.copyPileAmount = 1;
      
      setTimeout(() => {
        this.$refs.copyPileAmount.focus();
      }, 500);
    },

    pileChecked(pile) {
      if (pile.isChecked) this.selectedPiles.push(pile);
      else {
        let index = this.selectedPiles.indexOf(pile);
        if (index >= 0) this.selectedPiles.splice(index, 1);
      }
      if (this.selectedPiles.length == 1) this.singleSelectedPile = this.selectedPiles[0];
      else this.singleSelectedPile = null;

      if (this.selectedPiles.length == this.selectedReport.piles.length) this.allPilesChecked = true;
      else this.allPilesChecked = false;
    },

    checkAllPiles() {
      this.selectedPiles = [];
      for (let pile of this.selectedReport.piles) {
        if (this.allPilesChecked) {
          pile.isChecked = true;
          this.selectedPiles.push(pile);
        }
        else {
          pile.isChecked = false;
        }
      }
      if (this.selectedPiles.length == 1) this.singleSelectedPile = this.selectedPiles[0];
      else this.singleSelectedPile = null;
    },

    changePileIndex(selectedPileIndex, impactedPileIndex) {
      for (let i = 0; i < this.selectedReport.piles.length; i++) {
        let pileIndexChanged = false;
        if (this.selectedReport.piles[i].index == impactedPileIndex) {
          pileIndexChanged = true;
          this.selectedReport.piles[i].index = selectedPileIndex;
        }
        else if (this.selectedReport.piles[i].index == selectedPileIndex) {
          pileIndexChanged = true;
          this.selectedReport.piles[i].index = impactedPileIndex;
        }
        
        if (pileIndexChanged) {
          let pileChange = this.getChangedPile(this.selectedReport.piles[i].id);
          pileChange.index = this.selectedReport.piles[i].index;
        }
      }
      
      sortByInteger(this.selectedReport.piles, 'index');
      this.saveChanges();
    },

    pileLengthChanged(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.pileLength_ft = pile.pileLength_ft;
      this.saveChanges();
    },

    updatePileExtensionLengthEdit(pile) {
      if (pile.editExtensionLength > 0) { 
        pile.extensionLength_ft = pile.editExtensionLength; 
      }
      else {
        pile.extensionLength_ft = null;
      }

      this.$forceUpdate();
      this.updatePileExtensionLength(pile);
    },

    updatePileExtensionLength(pile) {

      let extensionLengthToSave_in = pile.extensionLength_ft;
      if (!(pile.extensionLength_ft <= 999 && pile.extensionLength_ft > 0)) {
        extensionLengthToSave_in = null;
        this.$nextTick(() => {
          pile.extensionLength_ft = null; 
          pile.editCustomExtensionLength = null;
        });
      }

      let pileChange = this.getChangedPile(pile.id);
      pileChange.extensionLength_ft = extensionLengthToSave_in;
      this.saveChanges();
    },

    customExtensionLengthChanged(pile) {
      if (this.isMetric()) {
        pile.extensionLength_ft = convertMetersToFeets(pile.editCustomExtensionLength);
      }
      else {
        pile.extensionLength_ft = pile.editCustomExtensionLength;
      }
      this.$forceUpdate();
      this.updatePileExtensionLength(pile);
    },

    updatePileExtensionUnits(pile) {
      // On arrondit à 2 décimales max et valide min/max
      pile.extensionUnitsCount = Math.round((pile.extensionUnitsCount) * 100) / 100;

      let extensionUnitsCountToSave = pile.extensionUnitsCount;

      if (!(pile.extensionUnitsCount <= 999999 && pile.extensionUnitsCount > 0)) {
        extensionUnitsCountToSave = null;
        this.$nextTick(() => {
          pile.extensionUnitsCount = null;
        });
      }

      this.$forceUpdate();

      let pileChange = this.getChangedPile(pile.id);
      pileChange.extensionUnitsCount = extensionUnitsCountToSave;
      this.saveChanges();
    },

    updatePlateDimensionId(pile) {
      
      // Pile plate dimension selection changed, find the new selection
      // Note: pile.pilePlatesDimensionId est utilisé pour tous les éléments de la liste, même pour un standardSupportingPlateId
      //       où pilePlatesDimensionId est null dans la BD dans ce cas. Les Standard Supporting Plates sont ajoutés à la fin de la liste.
      let selectedPilePlateDimension = this.selectedReport.pilePlateDimensions.find(p => p.id == pile.pilePlatesDimensionId);
      if (selectedPilePlateDimension) {

        let pileChange = this.getChangedPile(pile.id);

        // If the selected dimension is a Standard Supporting Plate
        if (selectedPilePlateDimension.plateId) {

          pile.plateReadonly = true;
          
          // Update plate mobility and form according to the standard supporting plate settings
          pile.standardSupportingPlateId = selectedPilePlateDimension.plateId;

          // Update the changes in the DB
          pileChange.standardSupportingPlateId = pile.standardSupportingPlateId;
          pileChange.pilePlatesDimensionId = null;

          // Si la colonne "Type de platine" est disponible pour ce rapport, on assigne ces valeurs par défaut
          if (this.showPlateTypeColumn) {
            pile.isFixPlate = selectedPilePlateDimension.mobility == 1;
            pile.plateTypeEnum = selectedPilePlateDimension.form;
            pile.isCustomPlate = false;

            pileChange.isFixPlate = pile.isFixPlate;
            pileChange.isCustomPlate = pile.isCustomPlate;
            pileChange.plateTypeEnum = selectedPilePlateDimension.form;
          }
        }
        else {
          // The selected dimension is either a global supporting plate or OTHER
          pile.plateReadonly = false;

          pile.standardSupportingPlateId = null;

          pileChange.pilePlatesDimensionId = pile.pilePlatesDimensionId;
          pileChange.standardSupportingPlateId = null;
        }
        this.saveChanges();
        this.$forceUpdate();
      }
    },

    updateConcreteBlockTypeId(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.concreteBlockTypeId = pile.concreteBlockTypeId > 0 ? pile.concreteBlockTypeId : null;

      // On efface la valeur personnalisée si on sélectionne autre chose que AUTRE
      if (pile.concreteBlockTypeId != -1) {
        pile.customConcreteBlockType = '';
        pileChange.customConcreteBlockType = '';
      }

      this.saveChanges();
    },

    updateCustomerPlateDimensions(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.customPlateDimensions = pile.customPlateDimensions;
      this.saveChanges();
    },

    updateCustomConcreteBlockType(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.customConcreteBlockType = pile.customConcreteBlockType;
      this.saveChanges();
    },

    updateSurfaceFinishEnum(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.surfaceFinishEnum = pile.surfaceFinishEnum;
      this.saveChanges();
    },

    updateBedrockEnum(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.bedrockEnum = pile.bedrockEnum;
      this.saveChanges();
    },

    updatePileName(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pile.name = this.capitalizeFirstLetter(pile.name);
      pileChange.name = pile.name;
      this.saveChanges();
    },

    async updatePressureChange(pile) {

      let pressureToSave = pile.pressure_psi;

      let pileChange = this.getChangedPile(pile.id);
      if (pressureToSave == '' || !pressureToSave) {
        pressureToSave = null;
      }
      else if (pressureToSave[0] == '-') {
        pressureToSave = null;

        this.openToast({ message: this.$t("reportInfo.non_negative"), duration: 5000, color: 'red'});
        return;
      }
      else {
        let machine = null;

        let pileDealerMachineMotor = this.selectedReport.site_dealer_machine_motors.find(sdmm => sdmm.id == pile.siteDealerMachineMotorId);

        if (pileDealerMachineMotor) {
          if (pile.siteDealerMachineMotorId > 0) {
            machine = pileDealerMachineMotor.machine;
          }
          else {
            machine = this.machines.find(m => m.id == pileDealerMachineMotor.machineId);
          }
        }

        if (machine) {
          let minPressure = machine.minPsi;
          let maxPressure = machine.maxPsi;
          if (pressureToSave > maxPressure) {
            pressureToSave = null;

            // F.R. Patch pour s'assurer que la valeur affichée est updatée.
            this.$nextTick(() => {
              pile.pressure_psi = null;
            });
            this.openToast({ message: this.$t("reportInfo.exceed_max_pressure", { maxPressure: maxPressure }), duration: 15000, color: 'red'});
          }
          else if (pressureToSave < minPressure) {
            pressureToSave = null;

            // F.R. Patch pour s'assurer que la valeur affichée est updatée.
            this.$nextTick(() => {
              pile.pressure_psi = null;
            });
            this.openToast({ message: this.$t("reportInfo.inferior_min_pressure", { minPressure: minPressure }), duration: 15000, color: 'red'});
          }
        }
      }

      pileChange.pressure_psi = pressureToSave;
      this.saveChanges();
    },

    async updateInvoicedLength(pile, invoicedLengthToSave) {
      if (this.isMetric()) {
        invoicedLengthToSave = convertMetersToFeets(invoicedLengthToSave);
      }

      if (invoicedLengthToSave > 999) invoicedLengthToSave = 999;
      else if (invoicedLengthToSave < 0) invoicedLengthToSave = 0;

      let pileChange = this.getChangedPile(pile.id);
      pileChange.invoicedLength_ft = invoicedLengthToSave;
      this.saveChanges();
    },

    async updateDepthChange(newValue, pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.depth_ft = newValue
      pile.depth_ft = newValue
      this.saveChanges();
    },

    checkFalseRefusal(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.isFalseRefusal = pile.isFalseRefusal;
      this.saveChanges();
    },

    getPileMachine(pile) {
      let machine = null;
      let pileDealerMachineMotor = this.selectedReport.site_dealer_machine_motors.find(m => m.id == pile.siteDealerMachineMotorId);

      if (pileDealerMachineMotor) {
        if (pile.siteDealerMachineMotorId > 0) {
          machine = pileDealerMachineMotor.machine;
        }
        else {
          machine = this.machines.find(m => m.id == pileDealerMachineMotor.machineId);
        }
      }
      return machine;
    },

    validateReadingPressure(pile, reading, machine) {
      if (!machine) {
        machine = this.getPileMachine(pile);
      }

      if (machine) {
        let minPressure = machine.minPsi;
        let maxPressure = machine.maxPsi;

        // Only validate pressure min and max if a value is set
        if (reading.pressure_psi != null && reading.pressure_psi != undefined && reading.pressure_psi != '') {

          if (reading.pressure_psi > maxPressure) {

            // F.R. Patch pour s'assurer que la valeur affichée est updatée.
            this.$nextTick(() => {
              reading.pressure_psi = null;
            });

            let torqueReadingChange = this.getChangedTorqueReading(reading.id);
            torqueReadingChange.pressure_psi = null;

            this.openToast({ message: this.$t("reportInfo.exceed_max_pressure", { maxPressure: maxPressure }), duration: 15000, color: 'red'});
          }
          else if (reading.pressure_psi < minPressure) {

            // F.R. Patch pour s'assurer que la valeur affichée est updatée.
            this.$nextTick(() => {
              reading.pressure_psi = null;
            });

            let torqueReadingChange = this.getChangedTorqueReading(reading.id);
            torqueReadingChange.pressure_psi = null;
            
            this.openToast({ message: this.$t("reportInfo.inferior_min_pressure", { minPressure: minPressure }), duration: 15000, color: 'red'});
          }
        }
      }
    },

    updateSiteDealerMachineMotorPile(pile) {
      if (!pile.siteDealerMachineMotorId) {
        return;
      }

      let pileChange = this.getChangedPile(pile.id);
      pileChange.siteDealerMachineMotorId = pile.siteDealerMachineMotorId;

      this.saveChanges();
    },

    updateHeightAboveGround(newValue, pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.heightAboveGround_ft = newValue
      
      this.saveChanges();
      pile.heightAboveGround_ft = newValue
    },

    updateHammerEnum(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.hammerEnum = pile.hammerEnum;

      this.saveChanges();
    },

    async updateImpact1Change(newValue, pile) {
      const pileChange = this.getChangedPile(pile.id);
      pile.impact1_ft = newValue
      pileChange.impact1_ft = newValue
      this.saveChanges();
    },

    async updateImpact2Change(newValue, pile) {
      const pileChange = this.getChangedPile(pile.id);
      pile.impact2_ft = newValue
      pileChange.impact2_ft = newValue
      this.saveChanges();
    },

    async updateImpact3Change(newValue, pile) {
      const pileChange = this.getChangedPile(pile.id);
      pile.impact3_ft = newValue
      pileChange.impact3_ft = newValue
      this.saveChanges();
    },

    soilTypeChanged() {
      this.selectedReport.changes.site_reports_soil_types = this.selectedReport.site_reports_soil_types;
      this.saveChanges();
    },

    safetyFactorCompressionChanged() {
      this.selectedReport.changes.safetyFactorCompression = this.selectedReport.safetyFactorCompression;
      this.saveChanges();
    },

    safetyFactorTensionChanged() {
      this.selectedReport.changes.safetyFactorTension = this.selectedReport.safetyFactorTension;
      this.saveChanges();
    },

    addTorqueReadingPileChanged() {

      if (!this.addingReadingPileNumbers.includes(this.addTorqueReadingPileNumber)) {
        this.addingReadingPileNumbers.push(this.addTorqueReadingPileNumber);
      }

      let torqueReading = { 
        id: this.getLowestTorqueReadingId(), 
        siteReportId: this.selectedReportId, 
        pileNumber: this.addTorqueReadingPileNumber,
        isFalseRefusal: false,
        depth_ft: 2, 
        pressure_psi: null 
      };

      this.selectedReport.changes.addedOrModifiedTorqueReadings.push(torqueReading);
      this.saveChanges();

      this.selectedReport.selectedTorqueReadingPileNumber = this.addTorqueReadingPileNumber;

      this.forceTorqueReadingPilesRecompute++;

      this.$nextTick(() => {
        this.addTorqueReadingPileNumber = null;
      });
    },

    checkReportFalseRefusal(torqueReadingPile) {
      torqueReadingPile.readings.forEach((reading) => {

          let torqueReadingChange = this.getChangedTorqueReading(reading.id);
          torqueReadingChange.isFalseRefusal = torqueReadingPile.isFalseRefusal;

          let index = this.selectedReport.site_torque_readings.findIndex(str => str.id == reading.id);
          this.selectedReport.site_torque_readings[index].isFalseRefusal = torqueReadingPile.isFalseRefusal;
      });

      this.saveChanges();
    },

    getLowestTorqueReadingId() {
      let lowestTorqueReadingId = 0;
      for (let i = 0; i < this.selectedReport.site_torque_readings.length; i++) {
        if (this.selectedReport.site_torque_readings[i].id < lowestTorqueReadingId) {
          lowestTorqueReadingId = this.selectedReport.site_torque_readings[i].id;
        }
      }
      return lowestTorqueReadingId;
    },

    getChangedTorqueReading(torqueReadingId) {
      let torqueReadingChange = this.selectedReport.changes.addedOrModifiedTorqueReadings.find(t => t.id == torqueReadingId);
      if (torqueReadingChange == null) {
        torqueReadingChange = { id: torqueReadingId };
        this.selectedReport.changes.addedOrModifiedTorqueReadings.push(torqueReadingChange);
      }
      return torqueReadingChange;
    },

    addTorqueReading(torqueReadingPile) {

      if (!this.addingReadingPileNumbers.includes(torqueReadingPile.pileNumber)) {
        this.addingReadingPileNumbers.push(torqueReadingPile.pileNumber);
      }

      let biggestTorqueReadingDepth_ft = null;
      let secondBiggestTorqueReadingDepth_ft = null;

      let torqueReadingPileReadings = JSON.parse(JSON.stringify(torqueReadingPile.readings));
      sortByInteger(torqueReadingPileReadings, 'depth_ft');

      // On va chercher les 2 plus grandes valeurs (s'il y en a)
      for (let i = torqueReadingPileReadings.length-1; i >= 0 && i >= torqueReadingPileReadings.length - 2; i--) {
        let torqueReadingDepth_ft = torqueReadingPileReadings[i];

        if (biggestTorqueReadingDepth_ft == null) {
          biggestTorqueReadingDepth_ft = torqueReadingDepth_ft.depth_ft;
        }
        else if (secondBiggestTorqueReadingDepth_ft == null) {
          secondBiggestTorqueReadingDepth_ft = torqueReadingDepth_ft.depth_ft;
        }
      }

      // On regarde la différence entre les 2 dernières valeurs et on incrémente de ce nombre
      let diff_ft = secondBiggestTorqueReadingDepth_ft ? biggestTorqueReadingDepth_ft - secondBiggestTorqueReadingDepth_ft : biggestTorqueReadingDepth_ft;
      if (!(diff_ft > 0)) {
        diff_ft = 1;
      }
      let depth_ft = biggestTorqueReadingDepth_ft + diff_ft;
    
      let torqueReading = { 
        id: this.getLowestTorqueReadingId(), 
        siteReportId: this.selectedReportId, 
        pileNumber: torqueReadingPile.pileNumber,
        isFalseRefusal: false,
        depth_ft: depth_ft, 
        pressure_psi: null 
      };

      this.selectedReport.changes.addedOrModifiedTorqueReadings.push(torqueReading);
      this.saveChanges();

      for (let refIndex in this.$refs.torqueReadingRef) {
        let ref = this.$refs.torqueReadingRef[refIndex];
        ref.setDepthDuplicate(false);
      }
    },

    deleteTorqueReading(torqueReadingToRemove, save = true) {
      this.selectedReport.changes.deletedTorqueReadings.push(torqueReadingToRemove.id);
      if (save) {
        this.saveChanges();
      }
     
      let index = this.selectedReport.site_torque_readings.findIndex(str => str.id == torqueReadingToRemove.id);
      this.selectedReport.site_torque_readings.splice(index, 1);

      this.forceTorqueReadingPilesRecompute++;
    },

    async torqueDepthChanged(newValue, torqueReading) {

      // Empêcher le changement si déjà une valeur de profondeur identique pour ce pieu
      let ref = this.$refs.torqueReadingRef.find(rr => rr.objectToUpdate.id == torqueReading.id);
      let torqueWithSameDepth = this.selectedReport.site_torque_readings.find(r => r.id != torqueReading.id &&
                                                                                   r.pileNumber == torqueReading.pileNumber &&
                                                                                   r.depth_ft == newValue);
      if (torqueWithSameDepth) {
        ref.setDepthDuplicate(true);
        return;
      }
      else {
        ref.setDepthDuplicate(false);
      }

      let torqueReadingChange = this.getChangedTorqueReading(torqueReading.id);
      torqueReadingChange.depth_ft = newValue;

      let index = this.selectedReport.site_torque_readings.findIndex(str => str.id == torqueReading.id);
      this.selectedReport.site_torque_readings[index].depth_ft = newValue;

      this.saveChanges();
    },

    async updateTorqueReadingPressure(torqueReading) {
      let torqueReadingChange = this.getChangedTorqueReading(torqueReading.id);
      if (torqueReading.pressure_psi == '' || !torqueReading.pressure_psi) {
        torqueReading.pressure_psi = null;
      }
      else if (torqueReading.pressure_psi[0] == '-') {
        torqueReading.pressure_psi = null;
        
        this.openToast({ message: this.$t("reportInfo.non_negative"), duration: 5000, color: 'red'});
        return;
      }
      else if (torqueReading.pressure_psi.length > 10) {
        this.openToast({ message: this.$t("reportInfo.exceed_length"), duration: 5000, color: 'red'});
        return;
      }

      torqueReadingChange.pressure_psi = torqueReading.pressure_psi;

      let pile = this.selectedReport.piles.find(p => p.index == torqueReading.pileNumber);
      if (pile) {
        this.validateReadingPressure(pile, torqueReading);
      }
      
      this.saveChanges();
    },

    calculateAvailableConformityDate() {
        this.availableConformityReportDate = [];
        let date = new Date();
        var dow = date.getDay();
        var addedDays = 1;

        while (addedDays <= 5) {
          var daysToAdd = addedDays;
          
          //if current day is Sunday add one day
          if (dow == 0) {
            daysToAdd++;
          }
          // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
          if (dow + daysToAdd >= 6) {
            //subtract days in current working week from work days
            var remainingWorkDays = daysToAdd - (5 - dow);
            //add current working week's weekend
            daysToAdd += 2;
            if (remainingWorkDays > 5) {
              //add two days for each working week by calculating how many weeks are included
              daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
              //exclude final weekend if remainingWorkDays resolves to an exact number of weeks
              if (remainingWorkDays % 5 == 0){
                daysToAdd -= 2;
              }
            }
          }

          date = new Date();
          date.setDate(date.getDate() + daysToAdd);
          let expectedDay = date.getDay();
          let expectedDayValue = WEEK_DAYS.find(d => d.value == expectedDay).text;
          let availableDateOption = {
            numberDays: daysToAdd,
            unit: this.$tc('reportInfo.day', daysToAdd),
            day: expectedDayValue,
            date: this.yyyymmdd(date)
          }
          this.availableConformityReportDate.push(availableDateOption);
          addedDays++;
        }
    },

    toggleRequest(property) {
      if (this.selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED) {

        let documentRequestType = null;
        switch (property) {
          case 'requireTpro':
            documentRequestType = DOCUMENT_REQUEST_TYPES.T_PRO;
            break;
          case 'requireBuildingInspectionForm':
            documentRequestType = DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM;
            break;
          case 'requirePermit':
            documentRequestType = DOCUMENT_REQUEST_TYPES.PERMIT_REQUEST;
            break;
          case 'requireConformityAttestation':
            documentRequestType = DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST;
            break;
          case 'requireStampedFieldReport':
            documentRequestType = DOCUMENT_REQUEST_TYPES.STAMPED_FIELD_REPORT;
            break;
          default:
            return;
        }

        this.openRequestDateDialog(documentRequestType);
      }
      else {
        // En draft
        this.selectedReport.changes[property] = this.selectedReport[property];
        this.saveChanges();

        if (property == 'requireTpro' && this.selectedReport.requireTpro) {
          this.tproDialog = true;
        }
      }
    },
    getLowestCommentId() {
      let lowestCommentId = 0;
      for (let i = 0; i < this.selectedReport.comments.length; i++) {
        if (this.selectedReport.comments[i].id < lowestCommentId) {
          lowestCommentId = this.selectedReport.comments[i].id;
        }
      }
      return lowestCommentId;
    },

    getChangedComment(commentId) {
      let commentChange = this.selectedReport.changes.addedOrModifiedComments.find(c => c.id == commentId);
      if (commentChange == null) {
        commentChange = { id: commentId };
        this.selectedReport.changes.addedOrModifiedComments.push(commentChange);
      }
      return commentChange;
    },
    confirmDialogNo() {
      if (this.confirmAction == ConfirmAction.ChangeGps) this.promiseAskConfirm(false);
      this.confirmDialog = false;
    },
    confirmDialogYes() {
      switch (this.confirmAction) {
        case ConfirmAction.DeleteComment:
          this.deleteComment();
          break;
        case ConfirmAction.DeleteFile:
          this.removeFile();
          break;
        case ConfirmAction.DeletePile:
          this.removePiles();
          break;
        case ConfirmAction.CopyLastPile:
          this.copyLastPile();
          break;
        case ConfirmAction.DeleteReport:
          this.deleteReport();
          break;
        case ConfirmAction.ReturnToDraft:
          this.returnToDraft();
          break;
        case ConfirmAction.DeletePermitPlan:
          this.removePermitPlan();
          break;
        case ConfirmAction.ChangeGps:
          this.promiseAskConfirm(true);
          break;
        case ConfirmAction.PileNotInstalled:
          this.confirmPileNotInstalled();
          break;
        case ConfirmAction.ChangeProject:
          this.projectChanged(this.otherProjectId);
          break;
        case ConfirmAction.HardDeleteReport:
          this.hardDeleteReport();
          break;
        default:
          console.log("Report.confirmDialog: unexpected action", this.confirmAction);
          break;
      }
      this.confirmDialog = false;
    },
    async deleteReport() {
      try {
        this.isSaving = true;
        let response = await ReportService.softDeleteReport(this.selectedReport.id, this.currentUser.id);
        this.isSaving = false;
        if (response.status == 200) {
          this.reloadPage();
        }
        else {
          this.openToast({ message: response, duration: 15000, color: 'red'});
        }
      }
      catch (error) {
        this.isSaving = false;
        console.log(error)
        this.openToast({ message: error, duration: 15000, color: 'red'});
      }
    },
    async hardDeleteReport() {
      try {
        this.isSaving = true;
        let response = await ReportService.hardDeleteReport(this.selectedReport.id, this.currentUser.id);
        this.isSaving = false;
        if (response.status == 200) {
          this.$router.push("/reports/");
        }
        else {
          this.openToast({ message: response, duration: 15000, color: 'red'});
        }
      }
      catch (error) {
        this.isSaving = false;
        console.log(error)
        this.openToast({ message: error, duration: 15000, color: 'red'});
      }
    },
    deleteComment() {
      let commentToRemove = this.selectedReport.comments[this.deleteCommentIndex];
      this.selectedReport.changes.deletedComments.push(commentToRemove.id);
      this.saveChanges();

      this.selectedReport.comments.splice(this.deleteCommentIndex, 1);
    },
    confirmDeleteComment(index) {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.comment');
      this.confirmAction = ConfirmAction.DeleteComment;
      this.confirmYesNo = true;
      this.deleteCommentIndex = index;
    },
    toggleNewComment(opened) {
      this.addCommentClicked = opened;

      if (!this.addCommentClicked) {
        this.newComment = '';
      }
    },

    editComment(comment) {
      comment.initialComment = comment.comment;
      comment.editcomment = true;
      this.$forceUpdate();
    },

    cancelComment(comment) {
      comment.comment = comment.initialComment;
      comment.editcomment = false;
      this.$forceUpdate();
    },

    updateCommentChanged(comment) {
      let commentChange = this.getChangedComment(comment.id);

      commentChange.comment = comment.comment;
      if (this.currentUser.id != comment.userId) {
        commentChange.userId = this.currentUser.id;
      }

      comment.editcomment = false;
      comment.updatedAt = moment(new Date().toISOString()).format('YYYY/MM/DD HH:mm:ss');
      this.formatComment(comment)
      this.saveChanges();
    },

    saveNewComment() {

      this.addingComment = true;

      let date = new Date().toISOString();

      let newComments = {
        id: this.getLowestCommentId(), 
        siteReportId: this.selectedReportId, 
        userId: this.currentUser.id, 
        createdAt: date,
        updatedAt: date,
        comment: this.newComment, 
        editcomment: false,
        user: this.currentUser
      }
      this.formatComment(newComments)

      let changedComment = JSON.parse(JSON.stringify(newComments));
      this.selectedReport.changes.addedOrModifiedComments.push(changedComment);
      
      this.newComment = '';
      this.addCommentClicked = false;
      this.saveChanges();
    },

    fileCategoryChanged() {
      this.$forceUpdate();
    },

    async saveFile(file, fileType) {
      //console.log("Saving new file...");
      let photoresponse = await FileService.addNewFile(file, 'site_reports', this.selectedReportId, {category: fileType});
      if (photoresponse.data?.alreadyExists === true) {
        this.openToast({ message: this.$t('reportInfo.file_already_exists'), duration: 3000});
      }
      else if (photoresponse.success) {
        this.addPhotoToLocalCollection(photoresponse.data, fileType)

        if (this.displayErrors) {
          this.validateReport();
          this.$forceUpdate();
        }
      }
      else {
        this.openToast({ message: this.$t(photoresponse.data), duration: 15000, color: 'red'});
      }
    },
    
    addPhotoToLocalCollection(file, fileType) {
      switch (fileType)
      {
        case 1:
            this.selectedReport.fileSitePhotos.push(file)
          break;
        case 2:
            this.selectedReport.filePileLocations.push(file)
          break;
        case 3:
            this.selectedReport.fileOtherDocs.push(file)
          break;
        case 4:
            this.selectedReport.approvedPermitPlans.push(file)
          break;
        default:
          return;
      }
    },

    resetInputFiles(input) {
      input.value = '';
    },

    dragOver() {},

    dragLeave() {},

    async dropFile(e, category) {
      this.isUploading[category.id] = true;
      let files = Array.from(e.dataTransfer.files)
      if (e && files) {
        let promises = [];
          for (let file of files) {
            promises.push(this.handleFileUpload(file, category.relatableType));
          }
          await Promise.allSettled(promises);
          this.isUploading[category.id] = false;
      }
    },

    async selectFileFromProjectClicked(category) {
      this.libraryDialogCategory = category;
      let result = await UserService.getProjectFiles(this.selectedReport.projectId);
      this.projectFiles = result.data.files;

      // Remove images that are not an image
      if (category.acceptFiles){
        this.projectFiles = this.projectFiles.filter((e) => e.isImage);
      }

      // Remove duplicate
      this.projectFiles = this.projectFiles.filter((value, index, self) =>index === self.findIndex((t) => (t.hash === value.hash)))

      // Disable
      for (let file of this.projectFiles) {
        file.disable = this.selectedReport[category.reportArray].some(x=> x.hash == file.hash)
      }

      // Order by (move disabled items to the end)
      this.projectFiles.sort((a, b) => {
        return ((a.disable === b.disable)? 0 : a.disable? 1 : -1);
      })

      this.libraryDialog = true;
    },
    
    uploadBtnClicked(category) {
      let el = document.querySelector(`#${category.id}`)
      el.disabled = false;
      el.click();
    },

    uploadFile(category) {
      this.isUploading[category.id] = true;
      setTimeout(async () => {
        let input = document.querySelector(`#${category.id}`);
        let promises = [];
        for (let i = 0; i < input.files.length; i++) {
          var file = input.files[i];
          promises.push(this.handleFileUpload(file, category.relatableType));
        }
        await Promise.allSettled(promises);
        this.resetInputFiles(input)
        this.isUploading[category.id] = false;
      }, 1);
    },

    async handleFileUpload(file, fileType) {
      if (file == null || file == undefined) return;

      // Validation d'une photo pour les photos de chantier
      if (fileType == REPORT_FILE_TYPE.FIELD_PHOTOS &&
          (!file.type.startsWith("image") && !file.name.toLowerCase().endsWith(".heic"))) {
        this.openToast({ message: this.$t("reportInfo.invalid_image"), duration: 5000, color: 'red'});
        return;
      }

      this.savingChanged(fileType, true);

      try {
        await this.saveFile(file, fileType);
        this.savingChanged(fileType, false);
        if (this.displayErrors) {
          let scope = this;
          this.$nextTick(() => {
            scope.validateReport();
          });
        }
      }
      catch(error) {
        console.log(error);
        this.savingChanged(fileType, false);
      }
    },

    savingChanged(fileType, saving) {
      switch (fileType) {
        // case REPORT_FILE_TYPE.FIELD_PHOTOS:
        //   this.isSavingSitePhoto = saving;
        //   break;
        // case REPORT_FILE_TYPE.PILE_LOCATION_PLAN:
        //   this.isSavingPilePhoto = saving;
        //   break;
        // case REPORT_FILE_TYPE.OTHER_DOCUMENTS:
        //   this.isSavingOtherDoc = saving;
        //   break;
        case REPORT_FILE_TYPE.PERMIT_PLAN:
          this.isSavingPermitPlan = saving;
          break;
      }
    },
    
    async downloadFile(file) {
      if (file.extension == 'pdf') {
        this.viewPdf.title = file.filename;
        this.viewPdf.show = true;
        try {
          let result = await axios.get(file.url.download, { headers: authHeader(), responseType: 'blob' });
          this.viewPdf.data = await result.data.arrayBuffer();
        } catch (error) {
          console.log(error);
          this.viewPdf.show = false;
          this.openToast({ message: error, duration: 5000, color: 'red'});
        }
      }
      else {
        window.open(file.url.download, '_blank');
      }
    },

    selectFileName(file) {
      let el = document.querySelector('#' + file.inputId);
      if (!el) return setTimeout(() => this.selectFileName(file), 100);
      el.focus();
      el.select();
    },

    async renameFile(file, action) {
      if (action == 'enable') {
        if (!isNaN(this.isRenamingAFile?.id) && this.isRenamingAFile.id != file.id) this.renameFile(this.isRenamingAFile, 'disable');
        file.renaming = true;
        this.isRenamingAFile = file;
        file.inputId = 'ren' + Date.now();
        setTimeout(() => this.selectFileName(file), 100);
      }
      else { //disable
        this.isRenamingAFile = false;
        file.renaming = false;
        delete file.renaming;
        delete file.inputId;
        FileService.renameFile(file);
      }
      this.$forceUpdate();
    },

    confirmFileDelete(file, fromArray, index) {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.file_message');
      this.confirmAction = ConfirmAction.DeleteFile;
      this.confirmYesNo = true;
      this.fileToDelete = { file, fromArray, index };
    },

    async removeFile() {
      let result = await FileService.deleteFile(this.fileToDelete.file);
      if (result.success) {
        this.fileToDelete.fromArray.splice(this.fileToDelete.index, 1);
        this.$forceUpdate();
      }
      else {
        this.openToast({ message: this.$t(result.data), duration: 15000, color: 'red'});
      }
    },

    async rotateImage(image) {
      if (image.isInRotation) return;
      image.isInRotation = true;
      this.$forceUpdate();
      let result = await FileService.rotateImage(image);
      if (result.data?.alreadyExists === true) {
        this.openToast({ message: this.$t('reportInfo.file_already_exists'), duration: 3000});
      }
      else if (result.data?.reloadAll === true) {
        window.location.reload(); //If the rotation impacted another file in another category, we lazily reload the whole report. 
      }
      else if (result.success == true && result.data != null) {
        for (let k of Object.keys(result.data)) {
          if (k != 'url') image[k] = result.data[k];
        }
        for (let k of Object.keys(result.data?.url)) {
          image.url[k] = result.data.url[k];
        }
        this.$forceUpdate();
      }
      else {
        this.openToast({ message: this.$t(result.data), duration: 15000, color: 'red'});
      }
      image.isInRotation = false;
    },

    async saveChanges() {

      await this.saveReportChanges();

      if (this.displayErrors) {
          this.$nextTick(() => {
            this.validateReport();
          });
      }
      // F.R. Patch pour la validation qui ne se refresh pas
      setTimeout(() => {
        this.$forceUpdate();
      }, 1000);
    },

    async changeStatus() {
      
      let canValidateReports = this.userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_VALIDATION);
      this.setDisplayErrors(true);
      this.validateReport();

      if (this.selectedReport.errors.length > 0) {

        let errorString = '';
        for (let i = 0; i < this.selectedReport.errors.length; i++) {
          if (errorString != '') {
            errorString += '\n';
          }
          errorString += this.selectedReport.errors[i];
        }

        this.openToast({ message: errorString, duration: 15000, color: 'red'});
        this.$forceUpdate();
        return;
      }
      else {
        let newStatus = null;
        
        switch (this.selectedReport.statusEnum) {
          case SITE_REPORT_STATUS.DRAFT:
            // If the user has the function to validate a report, move the draft report to validated. If not, move to validation.
            if (canValidateReports) {
              newStatus = SITE_REPORT_STATUS.VALIDATED;  
            }
            else {
              newStatus = SITE_REPORT_STATUS.VALIDATION;
            }
            break;
          case SITE_REPORT_STATUS.VALIDATION:
            if (canValidateReports) {
              newStatus = SITE_REPORT_STATUS.VALIDATED;  
            }
            break;
        }

        let requestDataRequired = newStatus == SITE_REPORT_STATUS.VALIDATED && 
        // 2022-07-28 https://novika.atlassian.net/browse/C1327-772?focusedCommentId=17332 T-Pro ne demande plus de date souhaitée
            (this.selectedReport.requireConformityAttestation || 
             this.selectedReport.requireBuildingInspectionForm || 
             this.selectedReport.requirePermit || 
             this.selectedReport.requireStampedFieldReport ||
             this.selectedReport.requestType == REQUEST_TYPES.PERMIT_REQUEST ||
             this.selectedReport.requestType == REQUEST_TYPES.RECOMMENDATION_REQUEST);
        
        if (requestDataRequired) {
          this.openRequestDateDialog();
          return;
        }
        else if (newStatus == null) {
          return;
        }

        this.selectedReport.changes.statusEnum = {statusEnum: newStatus, userId: this.currentUser.id};
        
        this.isSaving = true;
        await this.saveChanges();
        this.isSaving = false;
        this.reloadPage();
      }      
    },

    openRequestDateDialog(documentRequestType) {

      this.newDocumentRequestType = documentRequestType;

      // On coche la demande pour que les champs obligatoires soient pris en compte
      let property = this.getDocumentRequestTypeProperty(documentRequestType);
      if (property) {
        this.$set(this.selectedReport, property, {})
      }

      this.calculateAvailableConformityDate();
      // Setting default expected date for 5 working days, 3 for recommentation
      let dayArrayKey = (this.selectedReport.requestType == REQUEST_TYPES.RECOMMENDATION_REQUEST?2:4);
      this.selectedDateForRequest = this.availableConformityReportDate[dayArrayKey].date;
      this.conformityAttestationModal = true;
    },

    getDocumentRequestTypeProperty(documentRequestType) {
      let property = null;
      switch (documentRequestType) {
        case DOCUMENT_REQUEST_TYPES.T_PRO:
          property = 'requireTpro';
          break;
        case DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM:
          property = 'requireBuildingInspectionForm';
          break;
        case DOCUMENT_REQUEST_TYPES.PERMIT_REQUEST:
          property = 'requirePermit';
          break;
        case DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST:
          property = 'requireConformityAttestation';
          break;
        case DOCUMENT_REQUEST_TYPES.STAMPED_FIELD_REPORT:
          property = 'requireStampedFieldReport';
          break;
      }

      return property;
    },

    conformityAttestationDateCanceled() {

      this.conformityAttestationModal = false;

      let property = this.getDocumentRequestTypeProperty(this.newDocumentRequestType);
      
      if (property) {
        this.$set(this.selectedReport, property, null)
      }
    },

    async conformityAttestationDateValided() {

      this.conformityAttestationModal = false;

      this.selectedReport.changes.requestedDocumentDate = this.selectedDateForRequest;

      if (this.newDocumentRequestType != undefined &&
          this.newDocumentRequestType != null) {

        switch (parseInt(this.newDocumentRequestType)) {
          case DOCUMENT_REQUEST_TYPES.ATTESTATION_REQUEST:
            this.selectedReport.changes.requireConformityAttestation = true;
            break;
          case DOCUMENT_REQUEST_TYPES.STAMPED_FIELD_REPORT:
            this.selectedReport.changes.requireStampedFieldReport = true;
            break;
          case DOCUMENT_REQUEST_TYPES.PERMIT_REQUEST:
            this.selectedReport.changes.requirePermit = true;
            break;
          case DOCUMENT_REQUEST_TYPES.BUILDING_DEPART_INSPECTION_FORM:
            this.selectedReport.changes.requireBuildingInspectionForm = true;
            break;
          case DOCUMENT_REQUEST_TYPES.T_PRO:
            this.selectedReport.changes.requireTpro = true;
            break;
        }
      }
      else {
        this.selectedReport.changes.statusEnum = {statusEnum: SITE_REPORT_STATUS.VALIDATED, userId: this.currentUser.id};
      }

      this.isSaving = true;
      await this.saveChanges();
      this.isSaving = false;

      this.reloadPage();
    },

    confirmReturnToDraft() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.return_to_draft');
      this.confirmAction = ConfirmAction.ReturnToDraft;
      this.confirmYesNo = true;
    },

    async returnToDraft() {
      this.selectedReport.changes.statusEnum = {statusEnum: SITE_REPORT_STATUS.DRAFT, userId: this.currentUser.id};
      this.isSaving = true;
      await this.saveChanges();
      this.isSaving = false;
      this.reloadPage();
    },

    yyyymmdd: yyyymmdd,

    confirmDelete() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.message');
      this.confirmAction = ConfirmAction.DeleteReport;
      this.confirmYesNo = true;
    },

    confirmHardDelete() {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.hard_delete_message');
      this.confirmAction = ConfirmAction.HardDeleteReport;
      this.confirmYesNo = true;
    },
    displayedTorqueValue(pile) {
      let torque = pile.torque_lb_ft;

      if (this.selectedReport.project.dealer.engineering_department &&
          this.selectedReport.project.dealer.engineering_department.calculationFormulaType == CALCULATION_FORMULA_TYPE.FRANCE) {
        // Pour la France, TORQUE: Lbs-pi → N·m (y*1.35582)
        torque = pile.torque_lb_ft * 1.35582;
        torque = Math.round(torque);
      }
      return torque;
    },
    displayedTensionValue(pile) {
      let capacityTension = pile.capacityTension_lb;

      if (this.selectedReport.project.dealer.engineering_department &&
          this.selectedReport.project.dealer.engineering_department.calculationFormulaType == CALCULATION_FORMULA_TYPE.FRANCE) {
         // Pour la France, TENSION: lb → Tonne (z/1000)
        capacityTension = pile.capacityTension_lb / 2204.6;

        // Pour la valeur en tonne, on arrondi à 2 décimales près
        capacityTension = Math.round((capacityTension + Number.EPSILON) * 100) / 100;
      }
      return capacityTension;
    },
    displayedCompressionValue(pile) {
      let capacityCompression = pile.capacityCompression_lb;

      if (this.selectedReport.project.dealer.engineering_department &&
          this.selectedReport.project.dealer.engineering_department.calculationFormulaType == CALCULATION_FORMULA_TYPE.FRANCE) {
         // Pour la France, COMPRESSION: lb → Tonne (z/1000)
        capacityCompression = pile.capacityCompression_lb / 2204.6;

        // Pour la valeur en tonne, on arrondi à 2 décimales près
        capacityCompression = Math.round((capacityCompression + Number.EPSILON) * 100) / 100;
      }
      return capacityCompression;
    },
    
    getDealerMachineMotorDisplayName(dealerMachineMotor) {
      let displayName = '';

      if (dealerMachineMotor.machineName) {
        displayName += dealerMachineMotor.machineName;
      }

      if (dealerMachineMotor.serialNumber) {
        if (displayName) {
          displayName += ' - ';
        }
        displayName += dealerMachineMotor.serialNumber;
      }

      if (dealerMachineMotor.machine && typeof dealerMachineMotor.machine == 'string') {
        if (displayName) {
          displayName += ' - ';
        }
        displayName += dealerMachineMotor.machine;
      }

      if (dealerMachineMotor.motorNumber) {
        if (displayName) {
          displayName += ' - ';
        }
        displayName += dealerMachineMotor.motorNumber;
      }

      return displayName;
    },
    showPileBedrockDropdown(pile) {
      if (this.selectedReport.statusEnum == SITE_REPORT_STATUS.VALIDATED && pile.bedrockEnum == BEDROCK_ENUM.DEPRECATED_FALSE_REFUSAL) {
        return false;
      }
      return this.showBedrockDropdown;
    },
    showPilePressureWarning(pile) {
      if (this.showPileBedrockDropdown(pile)) {
        return pile.bedrockEnum != null;
      }
      else {
        return pile.isFalseRefusal;
      }
    },
    async addProjectFile(id, category){
      let file = await FileService.addProjectFile(id, 'site_reports', this.selectedReportId, category.relatableType);
      if (file.success) {
        this.addPhotoToLocalCollection(file.data, category.relatableType)
        if (this.displayErrors) {
          this.validateReport();
          this.$forceUpdate();
        }
      }
      else {
        this.openToast({ message: this.$t(file.data), duration: 15000, color: 'red'});
      }
      
      this.libraryDialog = false;
    },
    async downloadReport(reportUrl, isExcel) {
      let extractFilenameFromHeaders = (headers) => {
        let headerLine = headers[Object.keys(headers).find(k => k.toLowerCase() == 'content-disposition')]; //look for the content-disposition header but ignoring case
        if (headerLine == null) return 'report.pdf'; //Fallback
        return decodeURI(headerLine.substring(headerLine.indexOf('"') + 1, headerLine.lastIndexOf('"')));
      };
      this.isGeneratingReport = true;
      let result = await axios.get(reportUrl, {
        responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            'Accept': isExcel ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
          }
      });

      this.isGeneratingReport = false;
      
      if (result.data.byteLength < 100) {
        try {
          let data = JSON.parse(new TextDecoder().decode(result.data));
          if (data.message != null) {
            return this.openToast({ message: this.$t(data.message), duration: 5000, color: 'green' });
          }
        }
        catch (e) {
          // Ignore
        }
      }
      
      let file = new Blob([result.data], {type: isExcel ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'});
      let url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = extractFilenameFromHeaders(result.headers);
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.remove();
        }, 100);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
    },
    isAddingReadingForPileNumber(pileNumber) {
      if (pileNumber) {
        return this.addingReadingPileNumbers.includes(pileNumber);
      }
      else {
        // Si pas de pileNumber en paramètre, on regarde si on ajoute présentement un pieux aux readings
        return this.addingReadingPileNumbers.find(n => this.torqueReadingPiles.find(p => p.pileNumber == n) == undefined) != undefined;
      }
    },
    formatReport() {
      let report = this.selectedReport;

      if (report.welders) {
        report.welders.forEach((welder) => {
          let current = new Date();
          let expiration = new Date(welder.welderExpirationDateCwb);
          welder.isExpired = current > expiration;
        });
      }

      if (report.tempWelder && report.welders) {
        let tempWelders = report.tempWelder;
        if (tempWelders && tempWelders.length > 0) {
          tempWelders.forEach((w) => {
            w.id = -1;
            w.isOther = true;
            report.welders.push(w);
          });
        }
      }

      if (report.otherProjectType) {
        let otherProjectType = report.otherProjectType;
        if (otherProjectType && otherProjectType.length > 0) {
          otherProjectType.forEach((p) => {
            p.id = -1;
            p.isOther = true;
            report.projectTypes.push(p);
          });
        }
      }

      if (report.piles) {
        sortByInteger(report.piles, 'index');

        this.updateReportPileExtensionLengths();

        report.piles.forEach((p) => {
          this.formatPile(report, p);
        });
      }

      if (report.logs) {
        for (let i = 0; i < report.logs.length; i++) {
          report.logs[i].createdAt = moment(
            report.logs[i].createdAt
          ).format("YYYY/MM/DD HH:mm:ss");
        }
      }
    
      if (report.comments) {
        for (let i = 0; i < report.comments.length; i++) {
          let comment = report.comments[i];
          comment.updatedAt = moment(comment.updatedAt).format("YYYY/MM/DD HH:mm:ss");
          this.formatComment(comment);
        }
      }

      report.projectTypes.forEach((pType) => {
        let translatedProjectType = report.project.dealer.projectTypes.find(pt => pt.id == pType.projectTypeId);
        if (translatedProjectType) {
          pType.displayName = translatedProjectType.name;
        }
      });
    },
    formatComment(comment) {
      comment.formattedComment = comment.comment ? comment.comment : '';
      comment.formattedComment = comment.formattedComment.replace(/[\r\n]/g, '<br/>');
    },
    formatPile(report, pile) {
      if (!pile.pilePlatesDimensionId && pile.customPlateDimensions) {
        pile.pilePlatesDimensionId = -1;
      }

      if (!pile.concreteBlockTypeId && pile.customConcreteBlockType) {
        pile.concreteBlockTypeId = -1;
      }

      let isMetric = report && report.project && report.project.dealer && report.project.dealer.globalMeasureUnit == 0;

      pile.editExtensionLength = null;
      pile.editCustomExtensionLength = null;
      pile.editInvoicedLength = null;

      if (pile.invoicedLength_ft) {
        pile.editInvoicedLength = isMetric ? convertFeetsToMeters(pile.invoicedLength_ft) : pile.invoicedLength_ft;
      }

      // Si fait parti de la liste par défaut (5,7,10)
      if (pile.extensionLength_ft) {
        let pileExtensionLength = report.pileExtensionLengths.find(l => l.id == pile.extensionLength_ft);
        if (pileExtensionLength) {
          pile.editExtensionLength = pileExtensionLength.id;
        }
        else {
          pile.editExtensionLength = -1; // Other
          pile.editCustomExtensionLength = isMetric ? convertFeetsToMeters(pile.extensionLength_ft) : pile.extensionLength_ft;
        }
      }
    },
    updateReportPileExtensionLengths() {
      let report = this.selectedReport;
      let isMetric = report && report.project && report.project.dealer && report.project.dealer.globalMeasureUnit == 0;

      report.pileExtensionLengths = [ { id: null, value: '' } ];
      for (let ext of report.PILE_EXTENSION_FT_LENGTHS) {
        report.pileExtensionLengths.push({ id: ext, value: isMetric ? convertFeetsToMeters(ext) : ext });
      }
      report.pileExtensionLengths.push({ id: -1, value: this.$t('reportInfo.other') });
    },
    convertFeetsToMeters(x){
      return convertFeetsToMeters(x)
    },
    concreteSlabMandatory(projectType) {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PROJECT_CONCRETE_SLAB, projectType.hasConcreteSlab);
    },
    brickMandatory(projectType) {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PROJECT_BRICK, projectType.hasBrick);
    },
    pileNotInstalledMandatory(pile) {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.PILE_NOT_INSTALLED, pile.isNotInstalled);
    },
    torqueReadingMandatory(value) {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.TORQUE_READINGS, value);
    },
    getZoneDisplay(zone) {
      let display = this.selectedReport.siteReportDisplays.find(d => d.zone == zone);
      if (display) {
        switch (display.display) {
          default:
          case SITE_REPORT_DISPLAY_DISPLAYS.HIDDEN:
            return false;
          case SITE_REPORT_DISPLAY_DISPLAYS.VISIBLE_OPTIONAL:
          case SITE_REPORT_DISPLAY_DISPLAYS.VISIBLE_MANDATORY:
          case SITE_REPORT_DISPLAY_DISPLAYS.MANDATORY_WO_REFERENCENB:
          case SITE_REPORT_DISPLAY_DISPLAYS.MANDATORY_WO_RECOMMENDATION:
          case SITE_REPORT_DISPLAY_DISPLAYS.MANDATORY_WHEN_CHECKED:
            return true;
          case SITE_REPORT_DISPLAY_DISPLAYS.FREE_STANDING_STRUCTURE:
            return this.selectedReport.projectTypes.find(pt => pt.isFreeStandingStructure) != undefined;
        } 
      }
      return false;
    },
    getZoneMandatory(zone, value) {
      let display = this.selectedReport.siteReportDisplays.find(d => d.zone == zone);
      if (display) {
        if (display.display == SITE_REPORT_DISPLAY_DISPLAYS.VISIBLE_MANDATORY) {
          return true;
        }
        if (display.display == SITE_REPORT_DISPLAY_DISPLAYS.MANDATORY_WHEN_CHECKED) {
          return value;
        }
        if (display.display == SITE_REPORT_DISPLAY_DISPLAYS.MANDATORY_WO_REFERENCENB) {
          return !this.selectedReport.project.referenceNumber || !(this.selectedReport.project.referenceNumber.length > 0);
        }
        if (display.display == SITE_REPORT_DISPLAY_DISPLAYS.FREE_STANDING_STRUCTURE) {
          return this.selectedReport.projectTypes.find(pt => pt.isFreeStandingStructure) != undefined;
        }
        if (display.display == SITE_REPORT_DISPLAY_DISPLAYS.MANDATORY_WO_RECOMMENDATION) {
          return !this.selectedReport.projectHasCompletedRecommendation;
        }
      }
      return false;
    },
    getHistoryDetail(history, index) {
      let details = [];
      details.push(history.user.firstname);
      details.push(history.user.lastname);
      
      switch (history.statusEnum) {
        case SITE_REPORT_STATUS.DRAFT:
          if (index > 0) {
            details.push(this.selectedReport.requestType == REQUEST_TYPES.FIELD_REPORT ? this.$t("reportInfo.update_report") : this.$t("reportInfo.update_request"));
            details.push(this.$t("status.draft"));
          }
          else {
            details.push(this.selectedReport.requestType == REQUEST_TYPES.FIELD_REPORT ? this.$t("reportInfo.created_report") : this.$t("reportInfo.created_request"));
          }
          break;
        case SITE_REPORT_STATUS.VALIDATION:
          details.push(this.selectedReport.requestType == REQUEST_TYPES.FIELD_REPORT ? this.$t("reportInfo.update_report") : this.$t("reportInfo.update_request"));
          details.push(this.$t("status.in_validation"));
          break;
        case SITE_REPORT_STATUS.VALIDATED:
          details.push(this.selectedReport.requestType == REQUEST_TYPES.FIELD_REPORT ? this.$t("reportInfo.update_report") : this.$t("reportInfo.update_request"));
          details.push(this.$t("status.validated"));
          break;
        case SITE_REPORT_STATUS.DELETED:
          details.push(this.selectedReport.requestType == REQUEST_TYPES.FIELD_REPORT ? this.$t("reportInfo.deleted_report") : this.$t("reportInfo.deleted_request"));
          break;
        default:
          details.push(this.selectedReport.requestType == REQUEST_TYPES.FIELD_REPORT ? this.$t("reportInfo.canceled_report") : this.$t("reportInfo.canceled_request"));
          break;
      }

      return details.filter(d => d).join(' ');
      
    },
    isRequestDisabled(isChecked) {

      let isDisabled = true;
      switch (this.selectedReport.statusEnum) {
        case SITE_REPORT_STATUS.DRAFT:
          isDisabled = false;
          break;
        case SITE_REPORT_STATUS.VALIDATION:
          isDisabled = this.userFunctions.find(f => f.id == FUNCTIONS.SITE_REPORT_VALIDATION) == null;
          break;
        case SITE_REPORT_STATUS.VALIDATED:
          isDisabled = isChecked != undefined && isChecked != null;
          break;
        case SITE_REPORT_STATUS.CANCELED:
        case SITE_REPORT_STATUS.DELETED:
          isDisabled = true;
          break;
      }

      return isDisabled || this.isSiteReportViewOnly;
    },
    updateNotInstalledReason(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.notInstalledReason = pile.notInstalledReason;

      if (pile.notInstalledReason != PILE_NOT_INSTALLED_REASON.OTHER) {
        pile.notInstalledOtherReason = null;
        pileChange.notInstalledOtherReason = null;
      }
      this.saveChanges();
    },

    updateNotInstalledOtherReason(pile) {
      let pileChange = this.getChangedPile(pile.id);
      pileChange.notInstalledOtherReason = pile.notInstalledOtherReason;
      this.saveChanges();
    },
    askConfirmApplyGPS() {
      return new Promise(r => {
        this.promiseAskConfirm = r;
        this.confirmDialog = true;
        this.confirmDialogTitle = this.$t('confirm.head');
        this.confirmDialogBody = this.$t('confirm.change_gps');
        this.confirmAction = ConfirmAction.ChangeGps;
        this.confirmYesNo = true;
      });
    },
    showSitePhotos() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.FILES_SITE_PHOTOS);
    },
    showPilesLocation() {
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.FILES_PILES_LOCATION);
    },
    showPermitPlans() {
      if (!this.selectedReport.project.dealer.engineering_department ||
          !this.selectedReport.project.dealer.engineering_department.buildingInspectionFormInfo) {
        return false;
      }
      return this.getZoneDisplay(SITE_REPORT_DISPLAY_ZONES.FILES_PERMIT_PLANS);
    },
    sitePhotosMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.FILES_SITE_PHOTOS);
    },
    pilesLocationMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.FILES_PILES_LOCATION);
    },
    permitPlansMandatory() {
      return this.getZoneMandatory(SITE_REPORT_DISPLAY_ZONES.FILES_PERMIT_PLANS, this.selectedReport.requireBuildingInspectionForm);
    },
  }, // -- end of methods
};
</script>

<style scoped>
.border-right {
  border-right: 1px solid lightgray;
}
@media (max-width: 959px) {
 .border-right {
    border-right: none;
  } 
}
.v-tab {
  justify-content: left;
  font-size: 14px;
}
.v-card__text {
  font-size: 14px;
}
.tab-icon {
  margin-right: 6px;
}
.v-tab--active {
  fill: #009f4d !important;
  color: #009f4d !important;
}
.v-tab--active {
  color: #009f4d !important;
}
.v-tab--active > img {
  fill: #009f4d !important;
}
.required::after{
    content: "*";
}
#save-btn {
  background-color: #009f4d;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}
#send-btn {
  background-color: #009f4d;
}
#send-btn > span > svg {
  color: #fff;
  margin-right: 4px;
}
.add-container {
  text-align: center;
}

#card-holding-table {
  padding: 20px;
}
#div-holding-table {
  overflow-x: scroll;
}
#piles-table {
  overflow: scroll;

  border-collapse: collapse;
}
/* Sticky headers, and sticky first row*/
#piles-table tr th {
  background-color: #009f4d;
  color: white;
  position: sticky;
  z-index: 2;

  top: 0px;
  transform: translate(-1px, 0px);

  padding: 5px;
}
#piles-table tr td:first-child {
  background-color: #e4e4e4;
  position: sticky;
  z-index: 1;
  left: 0px;
}
#piles-table tr th:first-child {
  position: sticky;
  z-index: 3;
  left: 0px;
}
#piles-table tr th,
#piles-table tr td {
  border-right: 1px solid lightgray;
}
#piles-table tr th,
#piles-table tr td:last-child {
  border-right: none;
}
#piles-table tr td {
  padding: 10px 4px;
}
#piles-table tr.gray, .gray {
  background-color: #F0F0F0;
}

#required-info div.v-input {
  margin-top: 0px;
}
.comment-container {
  padding: 5px;
}
.comment-container + .comment-container {
  border-top: 1px solid #d0d0d0;
}
.black-dot {
    vertical-align: middle;
    background-color: rgb(136, 136, 136);
    height: 2px;
    width: 2px;
    border-radius: 90px;
    display: inline-block;
    margin: 0px 5px;
}
.requests-anchor {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  display: block;
  height: 48px;
  width: 85%;
}
.requests-anchor img {
  height: 48px;
}
.requests-anchor ion-thumbnail {
  display: inline-block;
  height: 100%;
}
.wrappedfile {
  text-overflow: ellipsis !important;
  max-width: 78%;
  margin-left: 10px;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  line-height: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
}
.request-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.request-container + .request-container {
  margin-top: 5px;
}
ion-thumbnail {
  margin-right: 8px;
}
.alternate {
  background: #d3d3d361;
}
.box + .box {
  border-top: 1px solid lightgray;
}

.upload-btn-wrapper {
  position: absolute;
  right: 0px;
  top: -15px;
}

.upload-btn-wrapper button {
  box-shadow: none;
  height: 45px;
  width: 45px;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.techno-text {
  color:#009f4d;
}

.required-img{
  width:48px;
  height:48px;
  border:1px dashed black; 
  border-radius:8px;
}

.delete-photo-icon{
  position:absolute;
  top:10px;
  right:0px;
}

.rotate-photo-icon{
  position:absolute;
  top:10px;
  right:60px;
}

.download-photo-icon{
  position:absolute;
  top:10px;
  right:90px;
}

.rename-file-icon{
  position:absolute;
  top:10px;
  right:30px;
}

tr {
  cursor: unset;
}

.address-field {
  margin: 0px;
  padding: 0px;
}
.clickup-anchor {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
.v-list-item--link:hover {
  background-color: #009f4d33;
}

.custom-label {
  font-size: 16px;
  transform: scale(0.75);
  color: rgba(0, 0, 0, 0.6);
  transform-origin: top left;
  height: 20px;
  line-height: 20px;
  letter-spacing: normal;
  display: block;
}
.add-test-col {
  text-align: center;
  position: relative;
  min-height:100px;
}
.add-test-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#torque-piles .row + .row {
  margin-top: 3px;
}

.text-field-units ::v-deep.v-label {
  font-size: 10px;
}

.machines {
  padding-top: 5px;
}
.machines .v-input + .v-input {
  margin-top: 20px;
}
#markerAddress {
  font-size: 0.75em;
  margin-left: 20px;
}

.directionLink {
  font-size: 0.7em;
}

.editingFile {
  display: inline-block;
  padding-left: 10px;
  position: fixed;
}

.loader {
  width: 48px;
  top: -8px;
  position: relative;
  aspect-ratio: 2;
  display: inline-block;
  --_g: no-repeat radial-gradient(circle closest-side,#000 70%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 40%;
  animation: l3 1s infinite linear;
}

.check-all >>> .v-icon {
  color: #fff !important;
}

@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}
</style>
